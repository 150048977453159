.hayhar-page.m3-011{
    &.isShow{
        .ant-carousel{
            height: auto;
            .img_wrap {
                height: auto;
                img{
                    height: 100%;
                    width: auto;
                }
            }
            .img_wrap.mobile_warp{
                img{
                    height: 100%;
                }
            }
        }
        
        .Hyperlinks{
            display: none;
        }
    }
    
    .text-top{
        color: #FFF;
    }
    .mobile .mobile-central{
        top: -16.8rem;
    }
    .footer{
        padding: 3rem;
        background-color: rgb(14,165,233,);
    }
    @media (max-width:1048px){
        & .ant-carousel{
            .img_wrap {
                img{
                    height: 100% !important;
                    width: 100% !important;
                }
            }
            
        }
    }
}



