.icon-footer-promotion:before {
    content: ""
}

@media(max-width: 1023px) {
    #mobileFooter table td {
        padding:.4rem .625rem
    }
}

#allFooter {
    clear: both
}

.centralize {
    text-align: center
}

.paddingBottom50px {
    padding-bottom: 50px
}

.footer-top-Wrapper {
    height:400px;
    position: relative
}

.v2of2023 .footer-top-Wrapper {
    height:auto;
    >div{ /*row expanded collapse large-12*/
        display: block;
    }
    .sponsor-col,
    .vendors-col,
    .ambassador-col{
        float:left;
        margin:0 1.302083333vw;
    }
    .sponsor-col{
        width: 21.875vw; // 420px when 1920screen;
        margin-left:2.5vw;
        .sponsor-item{
            height:auto;
        }
    }
    .vendors-col{
        width: 39.89583333vw; // 766px when 1920screen;
        margin-right: 0;
    }
    .ambassador-col{
        width: 28.02083333vw; // 538px when 1920screen;
        position: static;
        margin-left:0;
        margin-right:0;
    }
}
.footer-top-Wrapper .ambassador-col {
    position: absolute;
    top:0; // bottom: 0;
    right: 0
}

.footer-top-Wrapper .ambassador-col .ambassador-mobile {
    display: none
}

.footer-top-Wrapper .ambassador-col .ambassador-desktop img {
    float:left;
    width: auto;
    height: 22em
}
.footer-content-web.v2of2023 .footer-top-Wrapper .ambassador-col .ambassador-desktop img{
    width:auto;
    height:325px;
}
@media (max-width:1920px) {
    .footer-content-web.v2of2023 .footer-top-Wrapper .ambassador-col .ambassador-desktop img{
        max-width:100%;
    }

    .footer-content-web.v2of2023 .footer-top-Wrapper .ambassador-col .ambassador-desktop{position:relative}
    .footer-content-web.v2of2023 .footer-top-Wrapper .ambassador-col .ambassador-desktop img+span{
        position:absolute;
        top:0;
        left:0;
        height:35px;
        width:100%;
        background:#fff;
        display:block;
    }
}
@media (max-width:1530px) {
    .footer-content-web.v2of2023 .footer-top-Wrapper .ambassador-col .ambassador-desktop img+span{
        height:61px;
    }
}
@media (max-width:1200px) {
    .footer-content-web.v2of2023 .footer-top-Wrapper .ambassador-col .ambassador-desktop img+span{
        height:83px;
    }
}

.footer-content-web.v2of2023 .sponsor-item>.column {
    margin-top:30px;
    .sponsor-wording-top{margin-top:30px;}
}

.footer-top-Wrapper .sponsor-col .sponsor-item {
    width: auto;
    height: 22em
}

.mobileFooterBotton {
    color: #fff;
    font-size: 9px;
    display: block;
    line-height: 1;
    text-transform: capitalize
}

.mobileFooterNew {
    background: red;
    color: #fff;
    border-radius: 20px;
    font-size: 8px;
    padding: 0 5px 0 5px;
    position: absolute
}

.flag-icons {
    display: inline-block;
    width: 36px;
    height: 25px;
    cursor: pointer
}

.flag-icons.flag-icons-CN {
    background-position: -3px -3px
}

.flag-icons.flag-icons-ID {
    background-position: -3px -33px
}

.flag-icons.flag-icons-JP {
    background-position: -3px -63px
}

.flag-icons.flag-icons-KR {
    background-position: -3px -93px
}

.flag-icons.flag-icons-TH {
    background-position: -3px -123px
}

.flag-icons.flag-icons-VN {
    background-position: -3px -153px
}

.FooterIcon {
    display: inline-block;
    height: 32px;
    cursor: pointer;
    margin: 10px 20px 10px 0;
    background-repeat: no-repeat
}

.FirstCagayanLicenses {
    width: 33px;
    background-position: -25px -300px
}

.FirstCagayanLicenses:hover {
    background-position: -115px -300px
}

.GameCareResponsibility {
    width: 34px;
    background-position: -25px -266px
}

.GameCareResponsibility:hover {
    background-position: -115px -266px
}

.AboveAgeResponsibility {
    width: 33px;
    background-position: -25px -232px
}

.AboveAgeResponsibility:hover {
    background-position: -115px -232px
}

.VisaPayment {
    width: 50px;
    cursor: default!important;
    background-position: -266px -7px
}

.VisaPayment:hover {
    background-position: -266px -53px
}

.MasterCardPayment {
    width: 50px;
    cursor: default!important;
    background-position: -348px -7px
}

.MasterCardPayment:hover {
    background-position: -348px -53px
}

.ChinaUnionPayment {
    cursor: default!important;
    background-position: -26px -36px
}

.ChinaUnionPayment:hover {
    background-position: -115px -36px
}

.FastBank {
    width: 56px;
    cursor: default!important;
    background-position: -15px -194px
}

.FastBank:hover {
    background-position: -105px -194px
}

.BankWirePayment {
    width: 65px;
    cursor: default!important;
    background-position: -682px -7px
}

.BankWirePayment:hover {
    background-position: -682px -53px
}

.footer-second-wrapper .payment .FooterIcon {
    background-image:url(/ft/footer_payment.webp);
    margin: 5px 3px 5px 3px;
    height: 37px;
    min-width: 37px
}

.AliPayPayment {
    cursor: default!important;
    background-position: -26px 4px
}

.AliPayPayment:hover {
    background-position: -115px 4px
}

.SkrillPayment {
    width: 45px;
    cursor: default!important;
    background-position: -852px -7px
}

.SkrillPayment:hover {
    background-position: -852px -53px
}

.Help2PayPayment {
    width: 45px;
    cursor: default!important;
    background-position: -1385px -8px
}

.Help2PayPayment:hover {
    background-position: -1385px -53px
}

.IWalletPayment {
    width: 70px;
    cursor: default!important;
    background-position: -1184px -8px
}

.IWalletPayment:hover {
    background-position: -1184px -53px
}

.VenusPointPayment {
    width: 80px;
    cursor: default!important;
    background-position: -1285px -8px
}

.VenusPointPayment:hover {
    background-position: -1285px -53px
}

.AstroPay {
    cursor: default!important;
    background-position: -26px -157px
}

.AstroPay:hover {
    background-position: -115px -157px
}

.WeChat {
    cursor: default!important;
    background-position: -26px -77px
}

.WeChat:hover {
    background-position: -115px -77px
}

.JDPay {
    cursor: default!important;
    background-position: -26px -117px
}

.JDPay:hover {
    background-position: -115px -117px
}

.EasyPayTH {
    width: 66px;
    cursor: default!important;
    background-position: -9px -572px
}

.EasyPayTH:hover {
    background-position: -99px -572px
}

.EeziePay {
    width: 66px;
    cursor: default!important;
    background-position: -9px -605px
}

.EeziePay:hover {
    background-position: -99px -605px
}

.LocalBankTransfer {
    width: 62px;
    height: 30px!important;
    cursor: default!important;
    background-position: -12px -366px
}

.LocalBankTransfer:hover {
    background-position: -102px -366px
}

.FastBahtTH {
    width: 85px;
    cursor: default!important;
    background-position: 0 -539px
}

.FastBahtTH:hover {
    background-position: -90px -539px
}

.LineTH {
    width: 40px;
    cursor: default!important;
    background-position: -23px -506px
}

.LineTH:hover {
    background-position: -113px -506px
}

.CashCard {
    width: 73px;
    cursor: default!important;
    background-position: -7px -473px
}

.CashCard:hover {
    background-position: -97px -473px
}

.QRCode {
    width: 73px;
    cursor: default!important;
    background-position: -7px -768px
}

.QRCode:hover {
    background-position: -95px -768px
}

.MomoPay {
    width: 70px;
    cursor: default!important;
    height: 23px!important;
    background-position: -9px -858px
}

.MomoPay:hover {
    background-position: -96px -858px
}

.LocalBankTransferVn {
    width: 62px;
    height: 28px!important;
    cursor: default!important;
    background-position: -12px -645px
}

.LocalBankTransferVn:hover {
    background-position: -102px -645px
}

.FastPayVN {
    width: 55px;
    height: 28px!important;
    cursor: default!important;
    background-position: -15px -680px
}

.FastPayVN:hover {
    background-position: -105px -680px
}

.ZaloPay {
    width: 72px;
    cursor: default!important;
    background-position: -9px -798px
}

.ZaloPay:hover {
    background-position: -96px -798px
}

.CashCardVn {
    width: 73px;
    height: 30px!important;
    cursor: default!important;
    background-position: -7px -830px
}

.CashCardVn:hover {
    background-position: -94px -830px
}

.IovationSecurity {
    width: 90px!important;
    cursor: default!important;
    background-position: -4px -387px;
    background-size: 200px
}

.IovationSecurity:hover {
    background-position: -108px -387px
}

.footer-social-icon-wrapper.facebook {
    background-color: #4267b2
}

.footer-social-icon-wrapper.twitter {
    background-color: #1da1f2
}

.footer-social-icon-wrapper.youtube {
    background-color: #f5161b
}

.footer-disclaimer-content {
    color: #8e8e8e;
    text-align: justify;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px
}

.footer-social {
    margin: 0;
    list-style: none;
    list-style-type: none
}

.footer-social>li {
    float: inherit;
    margin-left: 15px
}

.footer-social-icon-wrapper {
    border-radius: 50%;
    width: 33px;
    height: 33px;
    cursor: pointer
}

.footer-license-mobile {
    list-style: none;
    list-style-type: none;
    margin: 0;
    display: inline-block;
    margin: auto
}

.footer-first-wrapper {
    margin: 0 50px;
    text-align: center;
    padding-top: 4em;
    padding-bottom: 1em
}

.footer-icon-wrapper {
    padding: 5px 45.5px;
    margin-left: 0;
    margin-right: 0;
    background-color: #f2f3f4
}

.footer-back-to-top {
    margin: 0 0 10px;
    text-align: center;
    background-color: #dcdcdc;
    height: 45px;
    border: 1px solid #dcdcdc;
    border-radius: 2px
}

.footer-back-to-top>span {
    font-weight: bold;
    line-height: 45px;
    font-size: 16px
}

.footer-social-icon {
    font-size: 15px;
    color: #fff;
    padding-left: 9px;
    padding-top: 9px;
    display: block
}

.footer-nagivation {
    margin: 0;
    list-style: none;
    list-style-type: none
}

.footer-nagivation>li {
    float: left;
    margin-right: 20px
}

.footer-nagivation>li>* {
    color: #fff
}

.footer-icon {
    margin: 0;
    list-style: none;
    list-style-type: none;
    clear: both
}

.footer-icon>li {
    float: left
}

.footer-region {
    margin: 0;
    list-style: none;
    list-style-type: none
}

.footer-region>li {
    float: inherit;
    margin-left: 10px
}

.footer-help {
    font-size: 14px;
    font-weight: bold
}

.footer-live-chat {
    z-index: 10;
    width: 210px;
    height: 44px;
    text-align: center;
    background: #25aae1;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    position: fixed;
    right: 40px;
    bottom: 0
}

.footer-live-chat-logo {
    color: #fff
}

.footer-live-chat-disclaimer {
    font-size: 16px;
    font-weight: bold;
    color: #fff
}

.footer-row {
    padding: 40px 0 0;
    background-color: #e1e1de;
    height: 297px
}

.footer-top-title {
    font-size: 17px;
    padding-bottom: 2px;
    color: #000
}

.footer-content-Wrapper {
    background-color: #e1e1de;
    padding: 0 45.5px;
    margin-top: 30px
}

.footer-partner {
    background-size: 450px
}

.sponsor-table {
    display: inline-block;
    margin: 0;
    font-size: 9px
}

.sponsor-table>tbody {
    background-color: transparent!important;
    border: 0
}

.sponsor-table>tbody>tr {
    background-color: transparent!important
}

.sponsor-table>tbody>tr:first-child {
    border-bottom: 1px solid #8c8c8c
}

.sponsor-table>tbody>tr>td {
    padding: 2px 0
}

.sponsor-wording-top {
    font-size: 15px;
    font-weight: bold;
    color: #25aae1
}

.sponsor-wording-btm {
    font-size: 15px;
    color: #000
}

.sponsor-logo {
    width: auto;
    height: 13vw;
    max-height: 13em;
    margin: 2em
}
.v2of2023 .sponsor-logo {
    height:139px;
}

.footer-partner.tottenham {
    width: 75px;
    height: 155px;
    margin-top: 20px;
    display: inline-block;
    background-position: -235px 0
}

.footer-partner.tottenham:hover {
    background-position: -12px 0
}

.footer-partner.newcastle {
    width: 115px;
    height: 120px;
    background-position: -237px -520px;
    display: inline-block;
    margin-top: 25px
}

.footer-partner.tottenham:hover {
    background-position: -14px -520px
}

.sponsor-newcastle:hover .footer-partner.newcastle {
    background-position: -14px -520px
}

.hidden-footer {
    display: none
}

.footer-gaming-platform {
    height: 70px;
    background-size: 270px;
    display: block;
    background-repeat: no-repeat
}

@media(min-width: 1012px) {
    .footer-gaming-platform {
        margin:10px 33px 10px 0
    }
    .footer-content-web.v2of2023 {
        .footer-gaming-platform{
            margin: 0 11px 4px 0
        }
    }

}

@media(min-width: 1135px) {
    .footer-gaming-platform {
        margin:10px 66px 10px 0
    }
}

@media(min-width: 1400px) {
    .footer-gaming-platform {
        margin:10px 2px 10px 0
    }
}

@media(min-width: 1700px) {
    .footer-gaming-platform {
        margin:10px 25px 10px 0
    }
}

@media(min-width: 1707px) {
    .footer-gaming-platform {
        margin:10px 32px 10px 0
    }
}

.footer-gaming-platform.asiagaming {
    width: 105px;
    height: 45px;
    background-position: -16px -515px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.asiagaming:hover {
    background-position: -150px -515px
}

.footer-gaming-platform.im {
    width: 130px;
    height: 45px;
    background-position: -5px -228px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.im:hover {
    background-position: -140px -228px
}

.footer-gaming-platform.bti {
    width: 85px;
    height: 45px;
    background-position: -23px -275px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.bti:hover {
    background-position: -156px -275px
}

.footer-gaming-platform.microgaming {
    width: 125px;
    height: 45px;
    background-position: -1px -325px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.microgaming:hover {
    background-position: -136px -325px
}

.footer-gaming-platform.ebet {
    width: 103px;
    height: 45px;
    background-position: -10px -370px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.ebet:hover {
    background-position: -146px -370px
}

.footer-gaming-platform.onework {
    width: 120px;
    height: 45px;
    background-position: -8px -470px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.onework:hover {
    background-position: -143px -470px
}

.footer-gaming-platform.golddeluxe {
    width: 88px;
    height: 45px;
    background-position: -20px -466px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.golddeluxe:hover {
    background-position: -155px -466px
}

.footer-gaming-platform.playtech {
    width: 136px;
    height: 45px;
    background-position: 5px -180px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.playtech:hover {
    background-position: -131px -180px
}

.footer-gaming-platform.kyg {
    width: 72px;
    height: 45px;
    background-position: -25px -420px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.kyg:hover {
    background-position: -160px -420px
}

.footer-gaming-platform.cmd {
    width: 108px;
    height: 45px;
    background-position: -13px -566px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.cmd:hover {
    background-position: -148px -566px
}

.footer-gaming-platform.betfair {
    background-position: -183px -625px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.betfair:hover {
    background-position: -364px -625px
}

.footer-gaming-platform.gpk {
    width: 118px;
    height: 45px;
    background-position: -6px 12px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.gpk:hover {
    background-position: -146px 12px
}

.footer-gaming-platform.ipk {
    width: 116px;
    height: 45px;
    background-position: -6px -88px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.ipk:hover {
    background-position: -143px -88px
}

.footer-gaming-platform.bgg {
    width: 110px;
    height: 45px;
    background-position: -12px -665px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.bgg:hover {
    background-position: -148px -665px
}

.footer-gaming-platform.tgp {
    width: 135px;
    height: 45px;
    background-position: 0 -618px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.tgp:hover {
    background-position: -136px -618px
}

.footer-gaming-platform.evo {
    width: 123px;
    height: 45px;
    background-position: -6px -810px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.evo:hover {
    background-position: -140px -810px
}

.footer-gaming-platform.tfg {
    width: 108px;
    height: 45px;
    background-position: -12px -710px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.tfg:hover {
    background-position: -147px -710px
}

.footer-gaming-platform.tcg {
    width: 125px;
    height: 45px;
    background-position: -15px -955px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.tcg:hover {
    background-position: -150px -955px
}

.footer-gaming-platform.pgs {
    width: 108px;
    height: 45px;
    background-position: -12px -1052px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.pgs:hover {
    background-position: -147px -1052px
}

.footer-gaming-platform.wec {
    width: 116px;
    height: 45px;
    background-position: -10px -1574px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.wec:hover {
    background-position: -147px -1574px
}

.footer-gaming-platform.amb {
    width: 116px;
    height: 45px;
    background-position: -10px -1522px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.amb:hover {
    background-position: -147px -1522px
}

.footer-gaming-platform.jkr {
    width: 125px;
    height: 45px;
    background-position: -2px -860px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.jkr:hover {
    background-position: -137px -860px
}

.footer-gaming-platform.sgw {
    width: 108px;
    height: 45px;
    background-position: -15px -910px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.sgw:hover {
    background-position: -150px -910px
}

.footer-gaming-platform.wmc {
    width: 108px;
    height: 45px;
    background-position: -12px -1002px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.wmc:hover {
    background-position: -144px -1002px
}

.footer-gaming-platform.tg {
    width: 108px;
    height: 45px;
    background-position: -12px -1103px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.tg:hover {
    background-position: -148px -1103px
}

.footer-gaming-platform.sxy {
    width: 108px;
    height: 45px;
    background-position: -12px -1148px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.sxy:hover {
    background-position: -147px -1148px
}

.footer-gaming-platform.obgames {
    width: 104px;
    height: 43px;
    background-position: -23px -1212px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.obgames:hover {
    background-position: -152px -1212px
}

.footer-gaming-platform.kpk {
    width: 115px;
    height: 45px;
    background-position: -12px -1305px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.kpk:hover {
    background-position: -143px -1305px
}

.footer-gaming-platform.jif {
    width: 80px;
    height: 43px;
    background-position: -30px -1258px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.jif:hover {
    background-position: -159px -1258px
}

.footer-gaming-platform.png {
    width: 116px;
    height: 45px;
    background-position: -10px -1355px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.png:hover {
    background-position: -141px -1355px
}

.footer-gaming-platform.vtg {
    width: 115px;
    height: 45px;
    background-position: -12px -1405px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.vtg:hover {
    background-position: -143px -1405px
}

.footer-gaming-platform.spr {
    width: 115px;
    height: 45px;
    background-position: -12px -1472px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.spr:hover {
    background-position: -143px -1472px
}

.footer-gaming-platform.spx {
    width: 115px;
    height: 45px;
    background-position: -12px -1619px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.spx:hover {
    background-position: -143px -1619px
}

.footer-gaming-platform.evp {
    width: 115px;
    height: 45px;
    background-position: -12px -1663px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.evp:hover {
    background-position: -142px -1663px
}

.footer-gaming-platform.hbn {
    width: 115px;
    height: 45px;
    background-position: -12px -1704px
}

.footer-content-web:not(.v2of2023) .footer-gaming-platform.hbn:hover {
    background-position: -142px -1704px
}

.footer-ambassador {
    background-repeat: no-repeat
}

.ambassador-zh-robbie,.ambassador-en-robbie,.ambassador-vi-robbie {
    background-position: -20px -500px;
    height: 235px;
    width: 185px
}

.ambassador-id-robbie,.ambassador-th-robbie {
    background-position: -20px -502px;
    height: 235px;
    width: 190px
}

.ambassador-zh-robbie:hover,.ambassador-en-robbie:hover,.ambassador-vi-robbie:hover,.ambassador-id-robbie:hover,.ambassador-th-robbie:hover {
    background-position: -245px -496px;
    margin-left: -30px;
    width: 250px
}

.ambassador-zh-steve,.ambassador-en-steve {
    background-position: -15px -274px;
    width: 100%;
    height: 235px
}

.ambassador-vi-steve,.ambassador-id-steve,.ambassador-th-steve {
    background-position: -20px -276px;
    height: 235px;
    width: 192px
}

.ambassador-zh-steve:hover,.ambassador-en-steve:hover,.ambassador-id-steve:hover {
    background-position: -240px -240px;
    width: 213px
}

.ambassador-vi-steve:hover,.ambassador-th-steve:hover {
    background-position: -250px -240px;
    height: 235px;
    width: 195px
}

.footer-ambassador.rola-sign {
    background-position: -17px -10px;
    height: 235px;
    width: 100%
}

.footer-ambassador.rola-sign:hover {
    background-position: -235px 7px;
    width: 240px;
    margin-left: -45px
}

.footer-sponsorship-paging>ul {
    text-align: center;
    margin: auto
}

.footer-sponsorship-paging>ul>li {
    display: inline-block;
    padding: 0 5px
}

.footer-ambassador-img {
    width: auto;
    max-height: 20vw
}

.footer-social-ul {
    margin: 0
}

.footer-social-ul>li {
    float: none;
    margin-right: 0;
    display: inline-block;
    vertical-align: middle
}

.footer-social-media-div>a {
    display: inline-block
}

.footer-social-media {
    display: inline-block;
    margin: 15px 0 0 0;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: 75px;
    height: 32px
}

.footer-social-media.th,.footer-social-media.vn,.footer-social-media.en {
    background-size: 70px;
    height: 27px
}

.footer-social-media.vn {
    background-size: 52px;
    margin-top: 24px;
    height: 21px
}

.footer-social-media.telegram {
    width: 34px;
    background-position: -2px -256px
}

.footer-social-media.telegram:hover {
    background-position: -39px -256px
}

.footer-social-media.telegram.vn {
    width: 26px;
    background-position: -2px -180px
}

.footer-social-media.telegram.vn:hover {
    background-position: -27px -180px
}

.footer-social-media.hotline {
    width: 38px;
    background-position: -4px -224px
}

.footer-social-media.hotline:hover {
    background-position: -50px -224px
}

.footer-social-media.bbm {
    background-position: -45px -247px
}

.footer-social-media.bbm:hover {
    background-position: 0 -247px
}

.footer-social-media.insta {
    width: 30px;
    background-position: -2px -210px
}

.footer-social-media.insta:hover {
    background-position: -38px -210px
}

.footer-social-media.insta.vn {
    width: 23px;
    background-position: -2px -156px
}

.footer-social-media.insta.vn:hover {
    background-position: -29px -156px
}

.footer-social-media.wechat {
    display: none;
    width: 43px;
    background-position: -45px -141px
}

.footer-social-media.wechat:hover {
    background-position: 0 -141px
}

.footer-social-media.weibo {
    width: 42px;
    background-position: 2px -288px
}

.footer-social-media.weibo:hover {
    background-position: -39px -288px
}

.footer-social-media.youku {
    width: 38px;
    background-position: 4px -153px
}

.footer-social-media.youku:hover {
    background-position: -36px -153px
}

.footer-social-media.facebook {
    width: 33px;
    background-position: 0 0
}

.footer-social-media.facebook:hover {
    background-position: -36px 0
}

.footer-social-media.facebook.vn {
    width: 24px
}

.footer-social-media.facebook.vn:hover {
    background-position: -27px 0
}

.footer-social-media.twitter {
    width: 34px;
    background-position: -3px -28px
}

.footer-social-media.twitter:hover {
    background-position: -39px -28px
}

.footer-social-media.twitter.vn {
    width: 22px;
    background-position: -2px -21px
}

.footer-social-media.twitter.vn:hover {
    background-position: -29px -21px
}

.footer-social-media.tiktok {
    width: 36px;
    background-position: 0 -113px
}

.footer-social-media.tiktok:hover {
    width: 36px;
    background-position: -36px -113px
}

.footer-social-media.youtube {
    width: 31px;
    background-position: -1px -86px
}

.footer-social-media.youtube:hover {
    background-position: -39px -86px
}

.footer-social-media.youtube.vn {
    width: 27px;
    background-position: 0 -63px
}

.footer-social-media.youtube.vn:hover {
    background-position: -28px -63px
}

.footer-social-media.line {
    width: 32px;
    background-position: -3px -56px
}

.footer-social-media.line:hover {
    background-position: -41px -56px
}

.partner-description {
    display: inline-block;
    margin: 0;
    margin-top: 70px;
    vertical-align: top
}

.footer-second-wrapper {
    padding: 36px 0;
    background-color: #eff0f1
}

.footer-second-wrapper hr {
    display: none
}

.footer-third-wrapper {
    padding: 20px 0 15px 0;
    background-color: #25aae1
}

.footer-third-wrapper hr {
    display: none
}

.footer-forth-wrapper {
    padding: 10px 0 45px 0;
    background-color: #219aca
}

.footer-forth-wrapper hr {
    display: none
}

.footer-forth-wrapper.zh {
    background-color: #25aae1;
    padding: 10px 0 0 0
}

.footer-link-container {
    display: table;
    margin: auto
}

.footer-link-container>span {
    padding: 0 17px
}

.footer-link-container>span>a {
    color: #fff
}

.footer-link-container>span>a:hover {
    color: #cbcbcb
}

.footer-social-container {
    display: table;
    margin: auto
}

.footer-social-container>div {
    display: inline-block
}

.footer-social-container>.help-link {
    border-right: 1px solid #dcdcdc;
    padding: 0 26px;
    vertical-align: top;
    display: inline-block;
    line-height: 25px;
    margin: 5px 0;
    margin-right: 9px
}

.footer-social-container>.help-link>a {
    color: #fff
}

.footer-social-container>.help-link>a:hover {
    color: #2e3191
}

.region-selector {
    border-left: 1px solid #dcdcdc;
    padding: 0 26px;
    vertical-align: top;
    display: inline-block;
    line-height: 11px;
    margin: 85px 0
}

.mobile-region-selector {
    padding: 0 0 0 10px;
    vertical-align: top;
    display: inline-block;
    line-height: 11px;
    margin-top: 5px
}

.language-dropdown {
    margin: auto;
    width: 145px;
    height: 35px;
    border: 1px solid #fff;
    border-radius: 10px
}

.language-dropdown-text {
    margin-top: 7px;
    padding: 0;
    text-align: center;
    font-size: .9rem
}

.language-dropdown-icon {
    margin-top: 10px;
    float: left!important;
    font-size: 10px
}

.language-dropdown-icon>.icon-fun88_arrow_down_triangle {
    font-size: 8px
}

.footer-partner-wrapper {
    padding: 0 10px
}

.footer-icon-fun88-help {
    font-size: 22px;
    padding-right: 15px;
    line-height: 24px;
    vertical-align: middle
}

@media(min-width: 1024px) {
    .linkcontainer {
        border-bottom:1px solid rgba(225,226,228,1);
        margin: 0 auto;
        padding: 1em 0 2em 0
    }
}

@media(max-width: 1650px) {
    .footer-row {
        height:310px
    }

    .footer-ambassador {
        background-size: 450px
    }

    .ambassador-zh-robbie,.ambassador-en-robbie {
        background-position: -30px -445px;
        height: 215px;
        width: 131px;
        margin-left: 0
    }

    .ambassador-vi-robbie,.ambassador-id-robbie,.ambassador-th-robbie {
        background-position: -18px -446px;
        height: 215px;
        width: 106%
    }

    .ambassador-zh-robbie:hover,.ambassador-en-robbie:hover,.ambassador-vi-robbie:hover,.ambassador-id-robbie:hover,.ambassador-th-robbie:hover {
        background-position: -220px -445px;
        margin-left: -30px;
        width: 225px
    }

    .ambassador-zh-steve,.ambassador-en-steve {
        background-position: -25px -242px;
        height: 215px;
        width: 100%
    }

    .ambassador-vi-steve,.ambassador-id-steve,.ambassador-th-steve {
        background-position: -16px -242px;
        height: 208px;
        width: 176px
    }

    .ambassador-zh-steve:hover,.ambassador-en-steve:hover {
        background-position: -225px -215px;
        width: 175px
    }

    .ambassador-vi-steve:hover,.ambassador-id-steve:hover,.ambassador-th-steve:hover {
        background-position: -225px -216px;
        height: 213px;
        width: 176px
    }

    .footer-ambassador.rola-sign {
        background-position: -25px -3px;
        height: 215px;
        width: 100%
    }

    .footer-ambassador.rola-sign:hover {
        background-position: -210px 7px;
        width: 215px;
        margin-left: -53px
    }

    .footer-partner {
        background-size: 400px
    }

    .footer-partner.tottenham {
        width: 65px;
        height: 140px;
        background-position: -210px 0
    }

    .footer-partner.newcastle {
        width: 105px;
        height: 120px;
        background-position: -210px -465px
    }

    .sponsor-newcastle:hover .footer-partner.newcastle {
        background-position: -12px -465px
    }
}

@media only screen and (max-width: 1525px) {
    .footer-row {
        height:265px
    }

    .footer-ambassador {
        background-size: 420px
    }

    .ambassador-zh-robbie,.ambassador-en-robbie {
        background-position: -35px -410px;
        height: 195px;
        width: 115px
    }

    .ambassador-vi-robbie,.ambassador-id-robbie,.ambassador-th-robbie {
        background-position: -22px -410px;
        height: 205px;
        width: 148px
    }

    .ambassador-zh-robbie:hover,.ambassador-en-robbie:hover,.ambassador-id-robbie:hover {
        background-position: -205px -410px;
        margin-left: -55px;
        height: 207px;
        width: 210px
    }

    .ambassador-vi-robbie:hover,.ambassador-th-robbie:hover {
        background-position: -205px -410px;
        margin-left: -55px;
        width: 210px;
        height: 207px
    }

    .ambassador-zh-steve,.ambassador-en-steve {
        background-position: -28px -220px;
        height: 205px
    }

    .ambassador-vi-steve,.ambassador-id-steve,.ambassador-th-steve {
        background-position: -15px -220px
    }

    .ambassador-zh-steve:hover,.ambassador-en-steve:hover,.ambassador-vi-steve:hover,.ambassador-id-steve:hover,.ambassador-th-steve:hover {
        background-position: -208px -196px;
        width: 170px;
        height: 204px;
        margin-left: -5px
    }

    .footer-ambassador.rola-sign {
        background-position: -35px 3px;
        height: 205px;
        width: 100%
    }

    .footer-ambassador.rola-sign:hover {
        background-position: -198px 13px;
        width: 200px;
        margin-left: -66px
    }

    .partner-description {
        display: none!important
    }

    .footer-partner-wrapper {
        text-align: center;
        padding: 0 40px
    }
}

@media(max-width: 1370px) and (min-width:1251px) {
    .ambassador-zh-steve,.ambassador-en-steve {
        background-position:-32px -220px;
        width: 130px
    }

    .ambassador-vi-steve {
        background-position: -15px -220px;
        width: 162px
    }

    .ambassador-zh-steve:hover,.ambassador-en-steve:hover {
        background-position: -212px -196px;
        width: 162px
    }

    .ambassador-vi-steve:hover {
        background-position: -212px -196px;
        width: 162px
    }
}

@media only screen and (max-width: 68.8125em) {
    .ambassador-zh-steve,.ambassador-en-steve,.ambassador-vi-steve {
        background-position:-22px -172px
    }

    .ambassador-zh-steve:hover,.ambassador-en-steve:hover,.ambassador-vi-steve:hover {
        background-position: -160px -154px
    }
}

@media only screen and (max-width: 1250px) {
    .footer-row {
        height:245px
    }

    .footer-ambassador {
        background-size: 380px
    }

    .footer-ambassador.rola-sign {
        background-position: -32px 1px;
        height: 185px;
        width: 100%
    }

    .footer-ambassador.rola-sign:hover {
        background-position: -180px 13px;
        width: 180px;
        margin-left: -65px
    }

    .ambassador-zh-robbie,.ambassador-en-robbie {
        background-position: -35px -372px;
        height: 185px;
        width: 115px
    }

    .ambassador-vi-robbie {
        background-position: -25px -372px;
        height: 183px;
        width: 126px
    }

    .ambassador-id-robbie,.ambassador-th-robbie {
        background-position: -20px -372px;
        height: 183px;
        width: 136px
    }

    .ambassador-zh-robbie:hover,.ambassador-en-robbie:hover,.ambassador-vi-robbie:hover,.ambassador-id-robbie:hover,.ambassador-th-robbie:hover {
        background-position: -190px -372px;
        margin-left: -50px;
        height: 183px;
        width: 183px
    }

    .ambassador-zh-steve,.ambassador-en-steve {
        background-position: -28px -200px;
        width: 120px
    }

    .ambassador-vi-steve,.ambassador-id-steve,.ambassador-th-steve {
        background-position: -14px -200px;
        height: 184px;
        width: 150px
    }

    .ambassador-zh-steve:hover,.ambassador-en-steve:hover {
        background-position: -192px -178px;
        width: 145px
    }

    .ambassador-vi-steve:hover,.ambassador-id-steve:hover,.ambassador-th-steve:hover {
        background-position: -191px -178px;
        height: 184px
    }
}

@media only screen and (max-width: 1100px) {
    .footer-partner-wrapper {
        padding:0 10px
    }

    .footer-partner {
        background-size: 350px
    }

    .footer-partner.tottenham {
        width: 65px;
        height: 125px;
        background-position: -180px 0
    }

    .footer-partner.newcastle {
        width: 105px;
        height: 105px;
        background-position: -177px -400px
    }

    .sponsor-newcastle:hover .footer-partner.newcastle {
        background-position: -3px -400px
    }

    .footer-row {
        height: 241px
    }

    .footer-ambassador {
        background-size: 320px
    }

    .ambassador-zh-robbie,.ambassador-en-robbie {
        background-position: -25px -318px;
        height: 150px;
        width: 115px
    }

    .ambassador-vi-robbie,.ambassador-id-robbie,.ambassador-th-robbie {
        background-position: -18px -318px;
        height: 150px;
        width: 115px
    }

    .ambassador-zh-robbie:hover,.ambassador-en-robbie:hover,.ambassador-vi-robbie:hover,.ambassador-id-robbie:hover,.ambassador-th-robbie:hover {
        background-position: -160px -318px;
        margin-left: -40px;
        height: 150px;
        width: 155px
    }

    .ambassador-zh-steve,.ambassador-en-steve {
        background-position: -24px -173px;
        width: 120px;
        height: 152px
    }

    .ambassador-vi-steve,.ambassador-id-steve,.ambassador-th-steve {
        background-position: -12px -173px;
        width: 122px;
        height: 152px
    }

    .ambassador-zh-steve:hover,.ambassador-en-steve:hover {
        background-position: -162px -154px;
        height: 151px;
        width: 123px
    }

    .ambassador-vi-steve:hover,.ambassador-id-steve:hover,.ambassador-th-steve:hover {
        background-position: -162px -154px;
        height: 151px
    }

    .footer-ambassador.rola-sign {
        background-position: -24px -4px;
        height: 150px
    }

    .footer-ambassador.rola-sign:hover {
        background-position: -150px 4px;
        width: 155px;
        margin-left: -45px
    }
}

@media(max-width: 1023px) {
    .footer-second-wrapper hr {
        display:block;
        max-width: 90rem;
        margin: 2.25rem auto;
        border-color: rgba(255,255,255,.5);
        -webkit-background-clip: padding-box;
        background-clip: padding-box
    }

    .footer-first-wrapper {
        padding-top: 3em;
        padding-bottom: 2em
    }

    .sponsor-logo {
        height: 10em;
        margin: 1em
    }

    .footer-top-Wrapper .ambassador-col .ambassador-desktop {
        display: none
    }

    .footer-top-Wrapper .ambassador-col .ambassador-mobile {
        display: block
    }

    .footer-top-Wrapper .ambassador-col .ambassador-mobile .amb {
        margin: 0 auto;
        height: 26em;
        width: auto;
        display: inline-block;
        background-image: none
    }

    .footer-top-Wrapper .owl-nav .owl-prev {
        position: absolute;
        left: 2%;
        bottom: 50%
    }

    .footer-top-Wrapper .owl-nav .owl-next {
        position: absolute;
        right: 2%;
        bottom: 50%
    }

    .footer-top-Wrapper .sponsor-col .sponsor-item {
        height: 17em
    }

    .footer-top-Wrapper .ambassador-col {
        position: relative
    }

    .footer-second-wrapper {
        text-align: center
    }

    .footer-second-wrapper .first-row {
        display: flex;
        flex-flow: wrap
    }

    .footer-second-wrapper .first-row:before,.footer-second-wrapper .first-row:after {
        content: normal
    }

    .footer-second-wrapper .first-row .responsibilities {
        order: 4;
        -webkit-order: 4
    }

    .footer-second-wrapper .first-row .licenses {
        order: 1;
        -webkit-order: 1
    }

    .footer-second-wrapper .first-row .payment {
        order: 2;
        -webkit-order: 2
    }

    .footer-second-wrapper .first-row .security {
        order: 3;
        -webkit-order: 3
    }

    .footer-second-wrapper .footerlink {
        margin-bottom: 2em;
        text-align: left!important
    }

    .footer-forth-wrapper {
        padding: 10px 0 60px 0;
        text-align: justify
    }

    .footer-forth-wrapper.zh {
        padding: 10px 0 45px 0
    }

    .footer-gaming-platform {
        margin: 0 10px
    }

    .footer-icon-wrapper {
        padding: 5px 40px
    }

    .footer-partner {
        background-size: 480px
    }

    .FooterIcon {
        margin: 10px 5px 10px 5px
    }

    .footer-icon {
        margin: 9px 0;
        height: auto
    }

    .footer-icon>li {
        float: none;
        margin-right: 0;
        display: inline-block;
        vertical-align: middle
    }

    .hidden-footer {
        display: block
    }

    .footer-partner.tottenham {
        width: 90px;
        height: 170px;
        background-position: -245px 0
    }

    .footer-partner.newcastle {
        width: 127px;
        height: 130px;
        background-position: -250px -555px
    }

    .sponsor-newcastle:hover .footer-partner.newcastle {
        background-position: -12px -555px
    }

    .footer-second-wrapper {
        padding: 38px 0 10px
    }

    .footer-social-container>.help-link {
        border-right: 0 solid #dcdcdc;
        padding: 0 30px 0 0
    }

    .footer-social-container>.region-selector {
        border-left: 1px solid #dcdcdc;
        padding: 0 0 0 30px
    }

    .footer-social-media.mobile {
        display: inline-block
    }

    .footer-row {
        height: auto;
        padding: 28px 50px 28px
    }

    .footer-content-Wrapper {
        padding: 0 40px
    }

    .footer-disclaimer-content {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        margin-bottom: 10px
    }

    .footer-back-to-top {
        margin: 0 0 10px;
        height: 45px
    }

    .footer-back-to-top>span {
        line-height: 45px;
        font-size: 16px
    }

    .footer-social>li {
        margin-right: 30px;
        margin-left: 0
    }

    .footer-social-icon-wrapper {
        width: 41px;
        height: 41px
    }

    .footer-social-icon {
        font-size: 19px;
        left: 11px;
        top: 11px
    }

    .footer-region {
        padding: 5px 0
    }

    .footer-region>li {
        margin-left: 30px
    }

    .footer-help {
        font-size: 16px
    }

    .footer-ambassador-img {
        max-height: 15em
    }
}

@media only screen and (max-width: 758px) {
    .footer-icon-wrapper,.footer-content-Wrapper {
        padding:0 20px
    }
}

@media only screen and (max-width: 639px) {
    .footer-ambassador-img {
        max-height:25em
    }

    .footer-top-wrapper-mobile .verticalLine {
        border-right: 0
    }
}

@media only screen and (max-width: 600px) {
    .footer-partner {
        -moz-transform:scale(.8,.8);
        -ms-transform: scale(.8,.8);
        -webkit-transform: scale(.8,.8);
        -o-transform: scale(.8,.8);
        transform: scale(.8,.8)
    }

    .footer-row {
        padding: 28px 0 28px
    }
}

@media only screen and (max-width: 480px) {
    .footer-social-container>.help-link {
        border-right:0 solid #dcdcdc;
        padding: 0 40px 0 0!important
    }

    .footer-social-container>.region-selector {
        border-left: 1px solid #dcdcdc;
        padding: 0 0 0 40px!important
    }

    .footer-content-Wrapper {
        padding: 0 10px
    }

    .footer-first-wrapper {
        padding-top: 2em
    }

    .sponsor-logo {
        height: 9em
    }

    .footer-top-Wrapper .ambassador-col .ambassador-mobile .amb {
        height: 18em
    }
}

@media only screen and (max-width: 400px) {
    .footer-icon-wrapper,.footer-content-Wrapper {
        padding:0 10px
    }

    .footer-social-container>.help-link {
        border-right: 0 solid #dcdcdc;
        padding: 0 20px 0 0!important
    }

    .footer-social-container>.region-selector {
        border-left: 1px solid #dcdcdc;
        padding: 0 0 0 20px!important
    }

    .footer-top-wrapper-mobile .second-slide-slick .ambassador-mobile {
        margin-top: 50px
    }
}

.footer-column-container .column-list {
    margin: 0;
    list-style: none;
    list-style-type: none;
    font-size: .8rem
}

.footer-column-container .column-list a {
    color: #c1c1c1;
    text-decoration: none;
    line-height: 2
}

.footer-bottom-title {
    color: #707171;
    padding-bottom: 5px;
    font-size: .9rem
}

.footer-content-header {
    font-size: 10px;
    text-align: center
}

.footer-header-arrow-down {
    margin-top: 4px
}

.footer-content-container {
    color: #fff;
    margin-left: 20px;
    margin-right: 20px
}

.footer-content-container>.content>p>a,.footer-content-container>h1>a {
    color: blue;
    font-weight: bolder
}

.footer-content-container>h1,.footer-content-container>.content>h2 {
    font-size: 10px
}

.footer-content-container>.content,.footer-content-container>.content>p {
    font-size: 10px
}

.footer-content-container>.content {
    display: block
}

.footer-content-container>h1,.expand {
    display: inline
}

.footer-content-container>.expand {
    font-size: 7px!important
}

.expand {
    margin-left: 10px;
    font-size: x-small
}

.DisclaimerLine {
    height: 77px;
    width: 320px;
    margin: auto
}

.Logo-NUFC {
    margin: auto;
    height: 94px;
    width: 95px;
    background-size: 93px
}

.Logo-OG {
    margin: auto;
    height: 105px;
    width: 70px;
    background-size: 70px
}

.Logo-SPURS {
    margin: auto;
    height: 93px;
    width: 45px;
    background-size: 45px
}

.Montenegro-Seal {
    height: 90px;
    width: 83px;
    margin: 18px auto
}

.white {
    color: #fff
}

.text-align-center {
    text-align: center
}

.text-align-left {
    text-align: left
}

.trim-padding-horizontal {
    padding-left: 0;
    padding-right: 0
}

.montenegro-desc {
    font-size: 10px;
    margin: 10px 1rem 0 1rem;
    float: left!important
}

.mobile-footer-link {
    font-size: 12px
}

.top-mobile-footer-link {
    margin-bottom: 2rem
}

.colorHeaderFilterMobile {
    width: 20%;
    display: inline-block;
    background-color: #2196f3;
    float: left;
    height: 5px
}

.disable-hover,.disable-hover * {
    pointer-events: none!important
}

.gridViewSlotHolder>.playNowButtonList,.gridViewSlotHolder>.tryNowButtonList {
    display: none
}

.slotGamePanel.list>.playNowButtonList,.slotGamePanel.list>.tryNowButtonList {
    display: block;
    opacity: 0
}

.loading {
    position: fixed;
    z-index: 1001;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3)
}

.loading:not(:required) {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 8px;
    width: .7em;
    height: .7em;
    margin-top: -.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: .5em;
    -webkit-box-shadow: #25aae1 1.5em 0 0 0,#25aae1 1.1em 1.1em 0 0,#25aae1 0 1.5em 0 0,#25aae1 -1.1em 1.1em 0 0,#25aae1 -1.5em 0 0 0,#25aae1 -1.1em -1.1em 0 0,#25aae1 0 -1.5em 0 0,#25aae1 1.1em -1.1em 0 0;
    box-shadow: #25aae1 1.5em 0 0 0,#25aae1 1.1em 1.1em 0 0,#25aae1 0 1.5em 0 0,#25aae1 -1.1em 1.1em 0 0,#25aae1 -1.5em 0 0 0,#25aae1 -1.1em -1.1em 0 0,#25aae1 0 -1.5em 0 0,#25aae1 1.1em -1.1em 0 0
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.gamesNumberMobileHolder {
    font-size: 16px;
    font-weight: bolder
}

.gameNamePositioningGrid {
    margin-left: auto;
    margin-top: 10px
}

.game-overlay.grid>.icon_positioning {
    position: absolute;
    top: 5px;
    left: 5px
}

.game-overlay.list>.icon_positioning {
    position: absolute;
    top: 20px;
    left: 5px
}

.game-overlay.list>.slot-fav-icon {
    top: 25px
}

.sortAndFilterBtn {
    color: #fff;
    background-color: #00adef;
    padding: 3px;
    border-radius: 4px
}

.sortAndFilterBtn:hover {
    background-color: #14bef2
}

.gameNamePositioningList {
    margin-left: 50px
}

.clearFilterGames {
    float: right;
    text-decoration: underline;
    font-weight: bolder;
    color: #2199e8
}

.clearFilterGames:hover {
    color: #1585cf
}

.totalGamesCount {
    color: #000;
    font-weight: bolder;
    font-size: 16px;
    text-align: center;
    margin-top: 25px
}

.totalSlotsNumbers,.totalSlotsNames {
    font-weight: bold!important
}

.payLineValue {
    display: none
}

.rolaImageNoGame {
    width: 406px
}

.banner-Button.zh,.banner-Button.en,.banner-Button.id,.banner-Button.vi {
    margin-top: 1%
}

.banner-Button {
    background-color: #3f97ff;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    margin: 0;
    color: #fff;
    padding: 10px 25px;
    min-width: 150px
}

.gameSearchBox {
    width: 100%;
    background-color: #fff!important;
    border: 1px solid #fff!important;
    border-radius: 7px;
    color: #000!important;
    box-sizing: border-box;
    transition: width 1s;
    background-image: url(/Assets/images/General/search_icon.png);
    background-position: 10px 6px;
    background-repeat: no-repeat;
    background-size: 20px;
    box-shadow: 1px 1px 1px 0 #888;
    padding-left: 40px!important
}

.gameSearchBox:hover,.gameSearchBox:focus {
    border: 1px solid #ddd!important
}

.gameSearchBox::-webkit-input-placeholder {
    color: gray
}

.gameSearchBox:-moz-placeholder {
    color: gray;
    opacity: 1
}

.gameSearchBox::-moz-placeholder {
    color: gray;
    opacity: 1
}

.gameSearchBox:-ms-input-placeholder {
    color: gray
}

@-webkit-keyframes MOVE-BG {
    from {
        background-position: 95px 5px
    }

    to {
        background-position: 10px 5px
    }
}

@-webkit-keyframes MOVE-BACK-BG {
    from {
        background-position: 10px 5px
    }

    to {
        background-position: 95px 5px
    }
}

.defaultAlphabetSorting {
    vertical-align: middle;
    display: inline-block;
    font-size: 14px;
    font-weight: bolder;
    color: #000
}

.defaultAlphabetSorting:hover,.defaultAlphabetSorting.active {
    color: #14bef2
}

.slotGameImage {
    z-index: 3
}

.slotGameImage.list {
    height: 70px
}

.lazyload {
    opacity: 0
}

.lazyloading {
    opacity: 1;
    transition: 300ms opacity;
    background: #f7f7f7 no-repeat center 30px;
    z-index: 3
}

.chkBoxText {
    margin-left: 30px;
    position: absolute;
    vertical-align: middle
}

.radioBoxText {
    margin-left: 10px;
    position: absolute;
    vertical-align: middle
}

.cursor-pointer {
    cursor: pointer
}

.gameLaunch {
    z-index: 20
}

.downloadFeelLuckyText {
    float: left;
    margin-left: 22px
}

.listViewFontSize {
    font-size: 14px;
    text-transform: uppercase
}

.populateFilteringBtn {
    background-color: #cacaca;
    height: 30px;
    border-radius: 3px;
    color: #2c2c2c;
    display: inline-block;
    padding: 0 25px;
    padding-left: 8px;
    position: relative;
    margin-left: 15px;
    margin-bottom: 6px
}

.icon-cancel_filter {
    position: absolute;
    top: 6px;
    cursor: pointer;
    right: 6px
}

.totalJackpotDivHolder {
    position: absolute;
    top: -17%;
    z-index: 1
}

.progressiveJackpotHolder {
    height: 27px;
    width: 100%;
    background: #000;
    opacity: .9;
    filter: alpha(opacity=60);
    color: #fff;
    position: absolute;
    bottom: 0;
    margin-bottom: 0
}

.progressiveJackpotHolder.hide {
    display: none
}

.progressiveJackpotHolder.show {
    display: block
}

.progressiveJackpotHolder>.icon-fun88_bookmark_star_checked {
    position: absolute;
    top: 12%;
    left: 15%
}

.bold-black-text {
    font-weight: bolder;
    color: #000
}

.no-padding {
    padding: 0!important
}

.img-responsive {
    width: 100%;
    height: auto
}

.feelLuckyImageHolder {
    padding: 0!important;
    top: 18px;
    position: absolute
}

.dark-grey-bold-text {
    font-size: 16px;
    color: #2c2c2c;
    font-weight: bold;
    text-transform: uppercase
}

.showAllText {
    padding: 0;
    text-align: center;
    text-transform: uppercase
}

.gameCategoryTextHolder {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: #8e8e8e;
    float: left
}

.upperCaseLetter {
    text-transform: uppercase
}

.capitalizeLetter {
    text-transform: capitalize
}

.checkBoxListWrap {
    min-height: 36px;
    max-height: 100px;
    position: relative
}

.sportsbookCheckbox:checked {
    background-color: grey!important;
    box-shadow: inset 0 0 0 2px #fff!important;
    transition: .5s!important
}

.sportsbookCheckbox {
    position: relative!important;
    margin-left: 4px!important;
    width: calc(3em - 30px)!important;
    height: calc(3em - 30px)!important;
    float: left!important;
    margin: 5px 10px 2px 1px!important;
    border-radius: 50%!important;
    vertical-align: middle!important;
    border: 1px solid grey!important;
    -webkit-appearance: none!important;
    outline: none!important;
    cursor: pointer!important
}

.sportsbookCheckboxtxt {
    margin-left: 0!important;
    font-size: 11px
}

.sportsbook-mobile-wrapper {
    position: relative;
    padding: 0 10px;
    margin-top: 10px;
    color: gray
}

.gameViewIcon {
    font-size: 22px;
    color: #cacaca;
    cursor: pointer
}

.gameViewIcon:hover {
    color: #33c85d
}

.mobileSortTiles {
    text-transform: uppercase
}

.mobileRadioBoxWrap {
    position: relative;
    margin-top: 10px
}

.mobileFilterBtn {
    cursor: pointer;
    text-align: center;
    background-color: #00adef;
    border: 1px solid #00adef;
    color: #fff;
    font-size: 16px;
    border-radius: 5px;
    padding: 5px 0
}

.mobileFilterBtn:hover {
    background-color: #3f97ff
}

.jackpotBtn {
    z-index: 2;
    position: absolute;
    right: 0
}

.mobileClearFilterBtn {
    cursor: pointer;
    text-align: center;
    border: 1px solid gray;
    border-radius: 3px;
    font-size: 16px;
    border-radius: 5px;
    padding: 5px 0;
    background-color: #f3f3f3;
    color: gray
}

.mobileFilterBoxText {
    position: absolute;
    left: 30%;
    font-weight: lighter;
    color: #000
}

.mobileSortPanelSortTitlesHolder {
    padding-left: 20px;
    font-weight: bolder;
    font-size: 18px;
    color: #8e8e8e
}

#mobile-sort-ddl-holder {
    background-color: #00adef;
    width: 90%;
    border-radius: 3px;
    min-height: 55px;
    color: #fff;
    position: relative;
    border-bottom: 1px solid #cecece;
    font-size: 24px
}

#mobile-sort-ddl {
    list-style-type: none;
    background-color: #00adef;
    margin: 0;
    z-index: 20;
    position: relative;
    display: none;
    border-radius: 5px
}

.gameFilterTitle,.mobileCategoryFilterTitle {
    padding-left: .625rem;
    padding-right: .625rem;
    margin-bottom: 10px;
    color: gray
}

#titleSorting {
    padding: 10px 15px;
    border-bottom: 1px solid #cecece;
    font-weight: lighter
}

#mobile-sort-ddl>li {
    display: block;
    font-size: 24px;
    font-weight: lighter;
    padding: 10px 15px
}

#mobile-sort-ddl>li:not(:last-child) {
    border-bottom: 1px solid #cecece
}

.mobileFilterSortSection {
    display: none;
    position: fixed;
    z-index: 50;
    height: 100%;
    background-color: #f3f3f3;
    padding: 0;
    overflow-y: scroll;
    top: 0;
    -webkit-overflow-scrolling: touch;
    z-index: 1002
}

.icon-filter_expand {
    position: absolute;
    right: 15px;
    top: 23px;
    font-size: 10px;
    z-index: 25
}

.filterHeaderMobile {
    background-color: #00adef;
    height: 46px;
    text-align: center;
    display: block;
    color: #fff;
    font-size: 19px;
    font-weight: lighter;
    padding-top: 10px;
    position: fixed;
    width: 100%;
    z-index: 30
}

#mobile-rainbow-style {
    position: fixed;
    top: 70px;
    width: 100%;
    z-index: 50
}

.mobile-btn-selection-holder {
    position: fixed;
    bottom: 0;
    z-index: 40;
    padding: 10px 15px;
    background-color: #f3f3f3
}

.mobileTitleFilter {
    font-size: 26px;
    font-weight: lighter;
    padding: 20px 20px;
    display: block;
    color: gray
}

.closeButtonFilterPanel {
    position: absolute;
    right: 5%;
    top: 35%;
    font-size: 14px
}

.filteringHolder.icon-fun88_arrow_down_triangle {
    color: #3f97ff;
    position: absolute;
    font-size: 6px;
    top: 8px;
    left: 100px
}

.square-radio {
    border: 1px solid #000;
    margin: 2px;
    width: 40px;
    height: 40px;
    position: relative
}

.square-radio--clicked .square-radio--content {
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000;
    width: 20px;
    height: 20px
}

.icheckbox_square-blue {
    position: absolute
}

.VendorLogo {
    border-radius: 26px;
    width: 26px;
    height: 26px;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding-top: 6px;
    position: absolute;
    z-index: 4
}

.listViewPlayNow {
    position: absolute;
    background-color: #23aee1;
    color: #fff;
    width: 150px;
    left: 30%;
    margin-top: 13px;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    z-index: 10
}

.searchFilteringHolder {
    margin-top: 10px
}

.listViewTryNow,.listViewDetail {
    position: absolute;
    background-color: #fff;
    color: #000;
    width: 150px;
    left: 55%;
    margin-top: 13px;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    z-index: 10
}

.filterSectionHolder {
    background-color: #fff;
    position: relative
}

.allProducts {
    background: #f3f3f3;
    color: #000
}

.bsgProducts {
    background: #c79906;
    color: #fff
}

.cqgProducts {
    background: #fe9900;
    color: #fff
}

.tgProducts {
    background: #f20000;
    color: #fff
}

.swfProducts {
    background: #a2ef00;
    color: #fff
}

.lxProducts {
    background: #984ee4;
    color: #fff
}

.mgsqfProducts {
    background: #03ad1f;
    color: #fff
}

.imoptProducts {
    background: #23aee1;
    color: #fff
}

.iwgProducts {
    background: #003b8d;
    color: #fff
}

.gpkProducts {
    background: #23aee1;
    color: #fff
}

.sgwProducts {
    background: #ea6a6a;
    color: #fff
}

.slcProducts {
    background: #09f;
    color: #fff
}

.tcgProducts {
    background: #2f9d3c;
    color: #fff
}

.pgsProducts {
    background: #03ad1f;
    color: #fff
}

.sprProducts {
    background: #5a4a85;
    color: #fff
}

.jirProducts {
    background: #07a1eb;
    color: #fff
}

.wecProducts {
    background: #25aae1;
    color: #fff
}

.ambProducts {
    background: #13c124;
    color: #fff
}

.spxProducts {
    background: #f80;
    color: #fff
}

.evpProducts {
    background: #03ad1f;
    color: #fff
}

.hbnProducts {
    background: #03ad1f;
    color: #fff
}

.productProvider {
    font-size: 11px;
    color: #3f97ff;
    font-weight: normal;
    float: left
}

.margin-left-fifty {
    margin-left: 50px!important
}

.vendorDescription span {
    font-size: 11px;
    color: #8e8e8e
}

.game-name-selector {
    padding-top: 6px!important;
    height: 37px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    position: relative;
    cursor: default
}

.gameFilteringName:hover {
    color: #14bef2
}

.gameFilteringName.active:after {
    content: ' ';
    display: block;
    border: 2px solid #14bef2;
    margin-top: 20px;
    width: 63px;
    color: #14bef2
}

#slotVendorDropDownList,#slotGameTypeDropDownList,#slotGameFeaturesDropDownList,#slotPayLineDropDownList,#slotNewDropDownList,#slotMyFavDropDownList,#slotGameRecommendation,#slotLastPlayedGameList,#slotFilterPlayerPreference {
    font-weight: bolder;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    height: 40px;
    width: 100%
}

.preferenceIcons {
    float: left;
    width: 50%;
    display: inline-block;
    text-align: center;
    color: #000
}

.preferenceIcons.active,.preferenceIcons:hover {
    color: red
}

#slotVendorList,#slotGameType,#slotGameFeatures,#slotFilterRangeNewList,#slotMyFavoritesGame,#slotMyRecentRecommendationsGame,#slotLastPlayedGames,#slotPreferenceList {
    display: block;
    border-bottom: 1px solid #d3d3d3;
    width: 100%
}

.recentWinnerHolder {
    position: absolute;
    right: 3%;
    top: 0;
    min-height: 350px;
    max-height: 342px;
    background-color: #dcdcdc;
    opacity: .9;
    width: 300px;
    z-index: 1000
}

.recentWinnerIcon {
    position: absolute;
    font-size: 40px;
    top: 20px;
    left: 25px
}

.recentWinnerTable {
    display: table;
    width: 300px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px
}

.recentWinnerTable:first-child {
    border-top: 1px solid #000!important
}

#gameNameSelector {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    padding-left: 5px
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border-top: 1px solid #000!important
}

.recentWinnerText {
    text-align: center;
    color: #3f97ff;
    font-weight: bolder;
    font-size: 22px;
    text-transform: uppercase;
    padding: 20px
}

.recentWinnerWinnersList {
    min-height: 55px;
    padding-top: 0;
    border-top: 2px solid #f3f3f3
}

.gameholder {
}

.slot-banner-Wrapper {
    position: relative;
    z-index: 1
}

.slot-banner-Wrapper.show {
    display: block
}

.slot-banner-Wrapper.hide {
    display: none
}

.slot-banner-paging {
    position: absolute;
    bottom: 0;
    width: 100%
}

.homebannerImage {
    height: 200px;
    background-size: cover!important;
    display: none
}

.gamebannerImage {
    height: 350px;
    background-size: cover!important
}

.gamebannerImage1 {
    height: 350px;
    background-size: 100% 100%!important;
    background-repeat: no-repeat!important
}

.gamebannerContent {
    position: absolute;
    padding: 25px 25px 25px 40px;
    top: 35px;
    width: 100%
}

.gamebannerTitle {
    font-size: 48px;
    display: block;
    color: #3f97ff;
    line-height: 1.2;
    font-weight: bold
}

.gamebannerDesc {
    font-size: 72px;
    display: block;
    color: #fff;
    line-height: 1.2
}

.gamebannerMiniDesc {
    font-size: 20px;
    display: block;
    color: #fff;
    line-height: 1.2;
    font-weight: bold;
    padding-left: 5px
}

@-webkit-keyframes opacity {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@-moz-keyframes opacity {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.loadingSpan {
    text-align: center
}

.loadingSpan span {
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-name: opacity;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: infinite
}

.loadingSpan span:nth-child(2) {
    -webkit-animation-delay: 100ms;
    -moz-animation-delay: 100ms
}

.loadingSpan span:nth-child(3) {
    -webkit-animation-delay: 300ms;
    -moz-animation-delay: 300ms
}

.iconLucky,.iconLuckyDownload,.iconLuckyDownload {
    height: 69px
}

.filterVendorTitleName {
    position: absolute;
    left: 0;
    text-transform: uppercase
}

.filterGameTypeName,.filterGameFeatures,.filterGameLine,.filterPreferenceName {
    position: absolute;
    left: 0;
    text-transform: uppercase
}

.categoryFilterTitle {
    position: absolute;
    left: 0;
    text-transform: capitalize
}

.slot-banner-arrow {
    position: absolute;
    top: 35%;
    width: 100%
}

.slot-banner-arrow>.slick-prev {
    float: left;
    margin-left: 4px
}

.slot-banner-arrow>.slick-next {
    float: right;
    margin-right: 4px
}

.slot-banner-arrow>button>span {
    font-size: 90px;
    color: #fff
}

.slot-slick-dots>li {
    display: inline-block
}

.slot-banner-paging>ul {
    text-align: center;
    position: absolute;
    width: 100%;
    margin: auto;
    bottom: 0;
    margin-bottom: 10px
}

.slot-banner-paging>ul>li {
    display: inline-block;
    padding: 0 5px
}

.slot-banner-paging>ul>li>span {
    color: #fff;
    cursor: pointer
}

.slick-dots>li>span.activedot {
    display: none
}

.slick-dots>li.slick-active>span.activedot {
    display: inline-block;
    color: #3f97ff!important
}

.slick-dots>li.slick-active>span.inactivedot {
    display: none
}

.game-overlay {
    position: relative;
    cursor: pointer;
    z-index: 3
}

.detail {
    z-index: 2;
    width: 100%;
    transition: all .5s;
    -webkit-transition: all .5s;
    display: none;
    margin-top: 5px
}

.game-overlay img {
    width: 100%;
    vertical-align: top
}

.game-overlay:after,.game-overlay:before {
    position: absolute;
    display: none
}

.game-overlay>.gameh-play-f {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -7px;
    margin-top: -11px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.w20 {
    width: 20px
}

.game-overlay .gameh-centerplay {
    transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -webkit-transition: opacity 1s ease-in-out
}

.game-overlay:hover .gameh-centerplay {
    transition: opacity .55s ease-in-out;
    -moz-transition: opacity .55s ease-in-out;
    -webkit-transition: opacity .55s ease-in-out
}

.game-overlay:hover .spinner {
    -webkit-animation: spin .3s linear;
    -moz-animation: spin .3s linear;
    animation: spin .3s linear;
    animation-fill-mode: forwards
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(180deg)
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(180deg)
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }
}

.game-overlay>.gameh-centerwrap {
    -ms-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: -webkit-transform .33s;
    transition: transform .33s;
    -webkit-backface-visibility: hidden;
    transform: matrix(1,0,0,1,0,0)
}

.playNowButtonList {
    position: absolute;
    right: 120px;
    -webkit-transform: translate(70%,-40%);
    -moz-transform: translate(70%,-40%);
    -ms-transform: translate(70%,-40%);
    -o-transform: translate(70%,-40%);
    transform: translate(70%,-40%);
    cursor: pointer;
    background-color: #00adef;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    top: 27%;
    display: none
}

.playNowButtonList.btnLangth {
    right: 80px
}

.playNowButtonList.btnLangvi {
    right: 110px
}

.playNowButtonList.btnLangen {
    right: 90px
}

.playNowButtonList:hover {
    background-color: #14bef2
}

.slotGamePanel.list>.playNowButtonList {
    right: 95px
}

.slotGamePanel.list>.playNowButtonList.btnLangen {
    right: 88px
}

.slotGamePanel.list>.playNowButtonList.btnLangid {
    right: 110px
}

.slotGamePanel.list>.tryNowButtonList.freePlayid {
    right: 270px
}

.slotGamePanel.list>.tryNowButtonList.freePlayth {
    right: 180px
}

.slotGamePanel.list>.tryNowButtonList.freePlayvi {
    right: 215px
}

.slotGamePanel.list>.tryNowButtonList.freeSlotvi {
    right: 130px
}

.slotGamePanel.list>.tryNowButtonList.freeSlotth {
    right: 163px
}

.slotGamePanel.list>.tryNowButtonList.freeSlotid {
    right: 140px
}

.slotGamePanel.list>.tryNowButtonList.freeSlotzh {
    right: 120px
}

.tryNowButtonList {
    position: absolute;
    right: 148px;
    -webkit-transform: translate(206%,-48%);
    -moz-transform: translate(206%,-48%);
    -ms-transform: translate(206%,-48%);
    -o-transform: translate(206%,-48%);
    transform: translate(206%,-48%);
    cursor: pointer;
    text-decoration: underline;
    color: #fff;
    z-index: 10;
    top: 72%;
    display: none
}

.tryNowButtonList.gameButtonList {
    right: 165px
}

.tryNowButtonList:hover {
    color: #000
}

.gameh-centerwrap {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 52%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    transition: all .7s ease
}

.w100p {
    width: 100%
}

.gameSearchField {
    position: absolute;
    right: 7%;
    top: 27%;
    width: 320px
}

.gameh-centerplay {
    opacity: 1;
    border-radius: 50%;
    position: relative
}

.game-overlay:hover>.slotGameImage {
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    transition: all .8s ease;
    -moz-transition: all .8s ease;
    -ms-transition: all .8s ease;
    -webkit-transition: all .8s ease;
    -o-transition: all .8s ease
}

.SGW.first>.game-overlay:hover>.slotGameImage,.GPK.first>.game-overlay:hover>.slotGameImage,.SLC.first>.game-overlay:hover>.slotGameImage,.KYG.first>.game-overlay:hover>.slotGameImage,.JBP.first>.game-overlay:hover>.slotGameImage,.YBP.first>.game-overlay:hover>.slotGameImage,.PRI.first>.game-overlay:hover>.slotGameImage,.TGP_P2P.first>.game-overlay:hover>.slotGameImage,.JKR_P2P.first>.game-overlay:hover>.slotGameImage,.IPK.first>.game-overlay:hover>.slotGameImage,.TCG.first>.game-overlay:hover>.slotGameImage {
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
    transition: all .8s ease;
    -moz-transition: all .8s ease;
    -ms-transition: all .8s ease;
    -webkit-transition: all .8s ease;
    -o-transition: all .8s ease
}

.game-overlay:hover>.flex-container {
    transition: all .9s ease;
    -moz-transition: all .9s ease;
    -ms-transition: all .9s ease;
    -webkit-transition: all .9s ease;
    -o-transition: all .9s ease;
    margin-top: -11px
}

.game-overlay:hover>.playNowIconOverlay {
    display: inline-block
}

.game-overlay>.playNowIconOverlay {
    position: absolute;
    z-index: 4;
    width: 65px;
    top: 50%;
    left: 50%;
    display: none;
    margin-left: auto;
    margin-right: auto;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.loading-gif {
    position: relative;
    width: 40px;
    margin-left: auto;
    margin-right: auto;
    display: none
}

.dropdown.menu>li.is-dropdown-submenu-parent>a:after {
    right: -12px!important
}

.is-dropdown-submenu {
    min-width: 105px!important
}

.listView:nth-child(4n) {
    background-color: #f2f2f2
}

.listView:nth-child(4n-1) {
    background-color: #f2f2f2
}

.slotGamePanel {
    padding-right: 0;
    position: relative
}

.slotGamePanelBoxWrap {
    min-height: 370px
}

.sportsbookGamePanelBoxWrap {
    min-height: 270px
}

.slotGamePanel.list {
    padding-left: 0;
    cursor: pointer;
    border-bottom: 1px solid #cecece;
    height: 70px;
    min-height: 70px
}

.slotGamePanel.list:hover {
    background: rgba(0,0,0,.3)
}

.slotGamePanel.list:hover>.playNowButtonList,.slotGamePanel.list:hover>.tryNowButtonList {
    display: block;
    opacity: 1
}

.slotGamePanel.list:hover>.tryNowButtonList.NotDemoFree {
    display: none
}

.KygGamePanelBoxWrap {
    min-height: 305px
}

.KygGamePanelBoxWrap.first .slotGameImage {
    height: 489px
}

.KygGamePanelBoxWrap .slotGameImage {
    height: 222px
}

.KYG .gameNamePositioningGrid {
    margin-top: 3px
}

.vendorGameWrapper {
    min-height: 400px!important
}

.newGameSlotGamePanelBoxWrap {
    min-height: 190px;
    text-align: center
}

.listView {
    position: relative
}

.listView>.buttonListView {
    width: 100%;
    opacity: 0
}

.listView:after,.listView:before {
    position: absolute;
    opacity: 0;
    transition: all .5s;
    -webkit-transition: all .5s
}

.listView:after {
    content: ' ';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.3)
}

.listView:before {
    content: attr(data-content);
    width: 100%;
    color: #fff;
    z-index: 1;
    bottom: 0;
    padding: 4px 10px;
    text-align: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box
}

.listView:hover:after,.listView:hover:before,.listView:hover>.buttonListView {
    opacity: 1
}

.slot-fav-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    z-index: 4;
    cursor: pointer
}

.gamesName {
    font-size: 16px;
    color: #2c2c2c;
    display: inline-block;
    text-align: left
}

.vendorName {
    margin-top: 17px;
    font-size: 16px;
    color: #2c2c2c;
    text-align: left;
    padding-top: 4px
}

.vendorBetLimit span:first-child,.vendorBetLimit span:nth-child(n+2) {
    font-weight: bold;
    font-size: 12px;
    color: #2c2c2c
}

.vendorBetLimit span:last-child {
    font-size: 10px;
    color: #8e8e8e
}

.vendorExtraInfo span:first-child,.vendorExtraInfo span:nth-child(n+2) {
    font-weight: bold;
    font-size: 12px;
    color: #2c2c2c
}

.vendorExtraInfo span:last-child {
    font-size: 10px;
    color: #8e8e8e
}

.slotCategoryHolder {
    padding-left: 15px;
    padding-bottom: 19px;
    padding-top: 0
}

.slotholder.hidden {
    display: none
}

.vendorMasterHolder {
    margin-bottom: 70px
}

.slotholder.show {
    display: block
}

.icon-fun88_bookmark_heart_checked {
    animation: beat .5s infinite alternate;
    transform-origin: center;
    color: red!important
}

@keyframes beat {
    to {
        transform: scale(1.2)
    }
}

.showAllHolder,.showMoreHolder {
    padding-top: 5px;
    color: #3f97ff;
    font-weight: bold;
    border: 1px solid #d3d3d3;
    background-color: #f2f3f4;
    border-radius: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    margin-top: 25px
}

.showMoreHolder {
    margin-top: 15px
}

.showAllHolder:hover,.showMoreHolder:hover {
    background-color: #3f97ff;
    color: #fff
}

.viewAllSpanWording {
    padding-right: 0;
    text-align: right;
    font-size: 12px;
    color: #2199e8;
    cursor: pointer
}

.viewAllSpanWording:hover,.viewAllSpanWording:hover,.viewAllSpanWording:hover,.viewAllSpanWording:hover,.viewAllSpanWording:hover {
    color: #1585cf
}

.text-no-game-found {
    text-align: center;
    width: 35%;
    position: absolute;
    top: 44%;
    left: 32%;
    word-break: break-all;
    font-size: 24px;
    font-weight: 500;
    display: none
}

#lessRecentlyPlayedGameFound,#favPlayedGame {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    word-break: break-all;
    margin-bottom: 20px
}

#logoHolder {
    display: table-cell;
    position: relative;
    vertical-align: middle
}

#onlyRolaGames {
    position: relative;
    width: 100%;
    height: auto;
    display: table
}

#leftGirlHolder {
    float: left
}

#rightGirlHolder {
    float: right
}

.no-game-found-girl-holder {
    display: table-cell;
    position: relative
}

#gameNameSort {
    z-index: 10
}

.walletPopulateList:first-child {
    padding-top: 40px
}

.gameMasterIcon {
    font-size: 24px;
    position: absolute;
    margin-top: 5px;
    color: #d3d3d3;
    cursor: pointer
}

.gameMasterIcon.gameViewIcon {
    position: relative
}

.gameMasterIcon.gameViewIcon.gridViewIcon {
    margin-right: 10px
}

.gameMasterIcon.gameViewIcon.listViewIcon {
    margin-right: 16px
}

.gameMasterIcon:hover,.gameMasterIcon.active {
    color: #33c85d
}

.twenty-five-padding-top {
    padding-top: 5px
}

.zero-padding-top {
    padding-top: 0
}

#searchFunctionPlaceHolder {
    display: none;
    padding-top: 20px
}

.icon-fun88_bookmark_star_checked {
    color: #25aae1;
    font-weight: bolder;
    font-size: 18px
}

.playNowHolder {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    line-height: 38px;
    margin: 0;
    text-transform: uppercase
}

.demoDetailsBtn {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    line-height: 38px;
    text-transform: uppercase;
    background-color: transparent
}

.newGameSlotGamePanelBoxWrap>.playNowHolder {
    font-size: 12px
}

.btnPlayReal {
    border-radius: 3px;
    line-height: 10px;
    margin: 10px auto;
    background-color: #3f97ff;
    cursor: pointer;
    width: 50%
}

.slotGameTableWrapper {
    margin-bottom: 0
}

.slotGameTableWrapper>tbody>tr>td {
    padding: 0
}

.slotGameTableWrapper>tbody {
    border: 0 solid #f1f1f1;
    background-color: #fff
}

.btnLiveDetail {
    width: 133px;
    height: 44px;
    border: 3px solid #fff;
    border-radius: 5px;
    line-height: 38px;
    margin: 0 auto 10px auto;
    cursor: pointer
}

.demoPlayBtn {
    color: #2199e8;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 0
}

.demoPlayBtn:hover {
    color: #1585cf
}

.btnPlayRealSmallIcon {
    width: 100px;
    height: 33px;
    border: 3px solid #3f97ff;
    border-radius: 5px;
    line-height: 25px;
    margin: 0 auto 10px auto;
    background-color: #3f97ff;
    cursor: pointer
}

.btnDemoRealSmallIcon {
    width: 100px;
    height: 33px;
    border: 3px solid #fff;
    border-radius: 5px;
    line-height: 25px;
    margin: 0 auto 10px auto;
    background-color: transparent;
    cursor: pointer
}

.btnPlayRealSmallIcon>.playNowHolder,.btnDemoRealSmallIcon>.demoDetailsBtn {
    font-size: 13px;
    line-height: 25px
}

.newGameTag {
    background-color: #33c85d;
    color: #fff;
    font-size: 8px;
    font-weight: bolder;
    float: left;
    text-transform: uppercase;
    padding: 2px;
    border-radius: 3px;
    margin-top: 5px;
    margin-right: 5px;
    min-width: 40px;
    height: 15px;
    text-align: center
}

.newGameTag.show {
    display: block
}

.newGameTag.hide {
    display: none
}

.slotDropDownListSmallArrowButton {
    position: absolute;
    right: 0;
    font-size: 6px;
    color: #8e8e8e;
    padding-top: 5px
}

.recentWinnerHolder .recentWinnerPlayLists {
    position: absolute;
    top: 70px;
    overflow: hidden;
    max-height: 265px
}

.gameBoardSelectionList {
    padding-top: 20px;
    padding-bottom: 0;
    font-size: 14px;
    text-align: left;
    position: relative;
    padding-left: 35px
}

.gameBoardSelectionList>div {
    padding-left: 0;
    padding-right: 0
}

.padTopTwelve {
    padding-top: 12px
}

.padTopThirtyTwo {
    padding-top: 32px
}

.feelLuckyIcon {
    width: 71px;
    height: 66px;
    top: 19px;
    background-repeat: no-repeat;
    position: absolute
}

#feelLuckyDiceIcon {
    background: url(/Assets/images/Games/Icon/feel_lucky_dice_icon.png)
}

#feelLuckySlotIcon {
    background: url(/Assets/images/Games/Icon/feel_lucky_slot_icon.png);
    width: 61px
}

.feelLuckyText {
    text-transform: uppercase;
    font-weight: bolder;
    position: absolute;
    font-size: 17px;
    top: 13%;
    left: 30%
}

.feelLuckyText.vi,.feelLuckyText.id {
    font-size: 14px
}

.feelLuckyButton {
    text-transform: uppercase;
    width: 125px;
    border-radius: 4px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    padding: 8px;
    position: absolute;
    top: 45%;
    left: 38%;
    cursor: pointer
}

.feelLuckyButton.id {
    width: 150px
}

.feelLuckyButton.vi {
    width: 170px
}

.NotSupportFree,.NotDemoFree {
    display: none
}

@media screen and (max-width: 84.9375em) {
    .DemoFree.desktop {
        display:none
    }

    .gameNameInfoWrap.gameNamePositioningGrid>.gamesName.slot {
        width: 100%
    }

    .gameNameInfoWrap.gameNamePositioningGrid.slot {
        display: inherit
    }
}

@media screen and (min-width: 85em) {
    .DemoFree.mobile {
        display:none
    }

    .gameNameInfoWrap.gameNamePositioningGrid>.gamesName.slot {
        width: 50%
    }
}

.SupportFree,.DemoFree {
    display: block
}

.SupportFree.inactive,.DemoFree.inactive {
    display: none
}

#firstLoadPanel>.slotholder.show.realign {
    margin-top: 30px
}

.btnRealPlayLiveCasino {
    margin: 0 auto!important
}

.btnPlayReal.playRealBtnNotSupportFree {
    margin: 30px auto
}

.btnPlayRealSmallIcon.playRealBtnNotSupportFree {
    margin: 10px auto
}

.btnDemoRealSmallIcon {
    margin: 0 auto 10px auto
}

.icon-down_btn {
    float: left;
    padding-left: 3px;
    position: absolute;
    top: 11px;
    font-size: 14px
}

#feelLuckySpin {
    background-color: #33c85d
}

#feelLuckyDownload {
    background-color: #3f97ff
}

.feelLuckyGamePanel {
    background-color: #f3f3f3;
    height: 105px;
    max-height: 105px;
    border: 1px solid #dcdcdc;
    position: relative
}

.feelLuckyGamePanel:nth-child(n+2) {
    margin-top: 14px
}

.gameDetailsSelectionUl {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.gameDetailsSelectionUl>li {
    display: inline-block;
    padding: 0 25px
}

.gameDetailsSelectionUl>li:not(.game-name-selector) {
    height: 42px
}

.gameDetailsSelectionUl>li:not(:last-child) {
    cursor: pointer
}

.gameDetailsSelectionUl>li.active {
    font-weight: bolder;
    color: #23aee1
}

.gameDetailsSelectionUl>li.active:after {
    content: ' ';
    display: block;
    border: 2px solid #23aee1;
    margin-top: 20px;
    width: 100%
}

.gameDetailsSelectionUl>li:first-child {
    padding-left: 0
}

.gameDetailsSelectionUl>li.imgSorting>img {
    padding: 0 12px;
    cursor: pointer
}

.gameNameInfoWrap {
    margin-top: 10px;
    position: relative;
    display: inline-flex
}

.gameNameInfoWrapKYG {
    margin-top: 10px;
    position: relative;
    display: block
}

.gameNameInfoWrapKYG.gameNamePositioningList {
    display: flex
}

.dropdown:hover .dropdown-content {
    display: block
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 96px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
    left: 0;
    top: 36px;
    width: 100%;
    min-width: 50px
}

.dropdown-content a {
    color: #000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    text-align: center
}

.dropdown-content a:hover {
    background-color: #23aee1;
    color: #fff
}

.irs-bar.irs-bar {
    background: #1cbd64;
    border: #1cbd64
}

.irs-slider.irs-slider {
    border-radius: 0;
    top: 30px;
    width: 15px;
    height: 15px
}

.irs-line.irs-line {
    border-radius: 0
}

.irs.irs {
    margin-top: -20px
}

#resultFilteringHolder {
    padding: 0 25px
}

.filterTitle {
    font-weight: bolder;
    font-size: 16px
}

.slotsGamesList>.newGameSlotGamePanelBoxWrap {
    min-height: 220px
}

.vendorBetLimit,.vendorExtraInfo,#vendorGamePanel {
    padding: 0;
    margin: 0
}

#vendorGamePanel {
    max-width: 100%
}

.vendorImageHolder {
    padding-right: 0;
    margin-bottom: 30px
}

.keno-Game-Wrapper {
    padding: 0 25px!important
}

.salProducts {
    background: #5657a7;
    color: #fff
}

.opusProducts {
    background: #e4a700;
    color: #fff
}

.tgpProducts,.tgp_slotProducts {
    background: #ab4505;
    color: #fff
}

.gdlProducts {
    background: #00693d;
    color: #fff
}

.aglProducts {
    background: #f56205;
    color: #fff
}

.gpiProducts {
    background: #25aae1;
    color: #fff
}

.bggProducts {
    background: #361d6e;
    color: #fff
}

.evoProducts {
    background: #00bda9;
    color: #fff
}

.wmcProducts {
    background: #0d124f;
    color: #fff
}

.nleProducts {
    background: #df1964;
    color: #fff
}

.abtProducts {
    background: #00bda9;
    color: #fff
}

.eaProducts {
    background: #b2140b;
    color: #fff
}

.boyProducts {
    background: #e72d00;
    color: #fff
}

.cpProducts {
    background: #f7bb49;
    color: #fff
}

.loadMoreLoading {
    width: 30px;
    height: 30px;
    margin-top: 20px
}

.loadMoreLoadingPanel {
    display: none;
    text-align: center
}

.gameDescription {
    display: block;
    display: -webkit-box;
    height: 40px;
    margin: 0 auto;
    line-height: 20px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 20px;
    text-align: left
}

.vendorBox {
    height: 400px
}

.vendorBox.en,.vendorBox.id,.vendorBox.th,.vendorBox.vi {
    height: 395px
}

.vendorEnterButton {
    position: absolute;
    margin-top: -22px;
    right: 0
}

.freePlayHolder {
    margin-top: -22px
}

.p2pEnterLobby {
    position: absolute;
    margin-top: -22px;
    right: 0
}

.gameSearchBox::placeholder {
    font-size: 12px
}

.currentGameTag {
    color: #fff;
    font-size: 8px;
    font-weight: bolder;
    float: left;
    text-transform: uppercase;
    padding: 2px;
    border-radius: 3px;
    margin-top: 5px;
    margin-right: 5px;
    min-width: 40px;
    height: 15px;
    visibility: hidden;
    text-align: center
}

.IsNewContainer {
    right: -5px;
    top: 0;
    width: 150px;
    height: 100px;
    z-index: 4;
    position: absolute
}

.flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end
}

.flex-item {
    text-align: center;
    margin-left: -25px
}

@media only screen and (max-width: 2650px) {
    .game-overlay:hover>.flex-container {
        margin-top:-6px
    }

    .first>.game-overlay:hover>.flex-container>.flex-item {
        margin-top: 1px
    }
}

@media only screen and (max-width: 2090px) {
    .gamebannerImage1 {
        height:460px
    }

    .flex-container {
        max-height: 90px
    }

    .first>.flex-container>.flex-item {
        right: 20px;
        height: 125px
    }
}

@media only screen and (max-width: 1900px) {
    .gamebannerImage1 {
        height:385px
    }

    .flex-container {
        max-height: 85px
    }
}

@media only screen and (max-width: 1800px) {
    .gamebannerImage1 {
        height:385px
    }

    .flex-container {
        max-height: 85px
    }

    .game-overlay:hover>.flex-container {
        margin-top: -5px
    }
}

@media only screen and (max-width: 1760px) {
    .KygGamePanelBoxWrap {
        min-height:295px
    }

    .KygGamePanelBoxWrap.first .slotGameImage {
        height: 470px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 213px
    }

    .gamebannerImage1 {
        height: 385px
    }

    .flex-container {
        max-height: 85px
    }

    .first>.game-overlay:hover>.flex-container>.flex-item {
        max-width: 100px;
        margin-top: 0
    }
}

.filterSportsbook {
    margin-left: -8%;
    padding-top: 20px
}

.instantgamesplaybtn,.sportsbookplaybtn {
    background: rgba(44,146,255,1);
    background: linear-gradient(180deg,rgba(151,217,252,1) 5%,rgba(94,179,254,1) 25%,rgba(44,146,255,1) 100%);
    border-radius: 20px;
    width: 80px;
    padding: 5px;
    font-size: 15px;
    color: #edf7ff;
    margin: 20px 0 0 -10px
}

.instantgamesplaybtn:hover,.sportsbookplaybtn:hover {
    background: unset;
    background-color: #fff;
    color: #7ad1fc;
    transition: .7s
}

.sportsbookGamePanelBoxWrap.list {
    margin-left: 2px;
    width: 49%
}

.sportsbookGamePanelBoxWrap.list>.playNowButtonList {
    margin-top: -7.5px!important;
    padding: 2px!important
}

.sportsbookGamePanelBoxWrap.list>.playNowButtonList {
    margin-top: -8px!important;
    padding: 2px!important
}

.sportsbookGamePanelBoxWrap.list:hover {
    background: rgba(0,0,0,0)!important
}

.instantgamesNameInfoWrap,.sportsbookgameNameInfoWrap {
    background-color: #0c1113;
    margin-top: -1%!important;
    width: 100%;
    margin-left: 0!important
}

.instantgamesname,.sportsbookgamesname {
    color: #ebecec;
    padding: 8px;
    font-size: 11px!important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%!important
}

.game-overlay:hover>.slotGameImage {
    -moz-transform: none;
    -webkit-transform: none;
    transform: none;
    filter: brightness(30%)
}

@media only screen and (max-width: 1760px) {
    .feelLuckyText.id {
        left:25%
    }
}

@media only screen and (max-width: 1700px) {
    .gamebannerImage1 {
        height:350px
    }

    .flex-container {
        max-height: 85px
    }

    .first>.flex-container>.flex-item {
        height: 115px
    }
}

@media only screen and (max-width: 1690px) {
    .slotGamePanelBoxWrap {
        min-height:300px
    }

    .sportsbookGamePanelBoxWrap {
        min-height: 200px
    }

    .vendorBox {
        height: 285px
    }

    .vendorBox.en,.vendorBox.id {
        height: 325px
    }

    .vendorBox.th,.vendorBox.vi {
        height: 300px
    }

    .padTopTwelve {
        padding-top: 30px
    }

    .feelLuckyGamePanel {
        height: 90px
    }

    .feelLuckyImageHolder {
        top: 12px
    }

    .no-game-found-girl-holder {
        width: 350px
    }

    .feelLuckyText {
        font-size: 15px
    }

    .feelLuckyText.id {
        font-size: 13px
    }

    .game-overlay:hover>.flex-container {
        margin-top: -5px
    }

    .first>.game-overlay:hover>.flex-container>.flex-item {
        max-width: 100px;
        margin-top: 1px;
        transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease
    }
}

@media only screen and (max-width: 1660px) {
    .KygGamePanelBoxWrap.first .slotGameImage {
        height:417px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 186px
    }
}

@media only screen and (max-width: 1580px) {
    .feelLuckyText {
        top:14%;
        left: 26%
    }

    .feelLuckyButton.vi,.feelLuckyButton {
        width: 150px
    }

    .feelLuckyButton.id,.feelLuckyButton {
        left: 33%
    }

    .feelLuckyText.id {
        left: 21%
    }

    .flex-container {
        max-height: 75px;
        max-width: 70px
    }

    .first>.flex-container>.flex-item {
        max-width: 100px
    }

    .game-overlay:hover>.flex-container {
        margin-top: -5px
    }

    .first>.game-overlay:hover>.flex-container>.flex-item {
        max-width: 70px
    }
}

@media only screen and (max-width: 1815px) {
    .gameDetailsSelectionUl {
        width:60%
    }
}

@media only screen and (max-width: 1520px) {
    .gameDetailsSelectionUl>li {
        padding:0 18px
    }

    .gameBoardSelectionList {
        font-size: 12px
    }

    .padTopTwelve {
        padding-top: 0
    }

    .padTopThirtyTwo {
        padding-top: 12px
    }
}

@media only screen and (max-width: 1490px) {
    .listViewTryNow,.listViewDetail {
        width:115px;
        left: 66%
    }

    #resultFilteringHolder {
        padding: 0
    }

    .listViewPlayNow {
        width: 115px;
        left: 40%
    }

    .filterTitle,.chkBoxText,.radioBoxText {
        font-size: 14px
    }

    #slotVendorDropDownList,#slotGameTypeDropDownList,#slotGameFeaturesDropDownList,#slotPayLineDropDownList {
        font-size: 12px
    }

    .feelLuckyGamePanel {
        height: 79px
    }

    .iconLucky,.iconLuckyDownload,.iconLuckyDownload {
        height: 53px
    }

    .feelLuckyText,.feelLuckyText.vi {
        top: 14%;
        left: 28%;
        font-size: 14px
    }

    .feelLuckyText.id {
        left: 23%;
        font-size: 12px
    }

    .jackpotCombineHolder {
        margin: 15px auto 0
    }

    .feelLuckyText,.feelLuckyText.vi,.feelLuckyText.id {
        top: 14%;
        left: 28%;
        font-size: 15px
    }

    .feelLuckyButton {
        left: 35%
    }

    .flex-container {
        max-height: 75px;
        max-width: 70px
    }

    .first>.flex-container>.flex-item {
        max-width: 100px
    }

    .gameNameInfoWrap.gameNamePositioningGrid>.gamesName {
        width: 38%
    }

    .gameNameInfoWrap.gameNamePositioningGrid>.sportsbook.gamesName {
        width: 90%
    }

    .gameNameInfoWrapKYG.gameNamePositioningGrid>.gamesName {
        width: 60%
    }
}

@media only screen and (max-width: 1390px) {
    .feelLuckyButton.vi,.feelLuckyButton {
        width:130px;
        font-size: 11px
    }

    .KygGamePanelBoxWrap.first .slotGameImage {
        height: 374px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 165px
    }

    .feelLuckyText,.feelLuckyText.vi,.feelLuckyText.id {
        left: 31%;
        font-size: 13px
    }

    .flex-container {
        max-height: 68px;
        max-width: 64px
    }

    .first>.flex-container>.flex-item {
        max-height: 90px
    }

    .game-overlay:hover>.flex-container {
        margin-top: -4px
    }

    .first>.game-overlay:hover>.flex-container>.flex-item {
        max-width: 65px
    }

    .vendorEnterButton {
        position: relative;
        margin-top: 0;
        right: 0
    }
}

@media only screen and (max-width: 1300px) {
    .gameDetailsSelectionUl>li {
        padding:0 12px
    }

    .padTopTwelve {
        padding-top: 25px
    }

    .gameNameInfoWrap {
        margin-top: 3px
    }

    .gameNameInfoWrapKYG {
        margin-top: 3px
    }

    .gamesName,.vendorName {
        font-size: 14px
    }

    .vendorName {
        margin-top: 12px
    }

    .showAllHolder {
        margin-top: 0
    }

    .totalJackpotDivHolder {
        top: -12px
    }

    .padTopThirtyTwo {
        padding-top: 25px
    }

    .no-game-found-girl-holder {
        width: 300px
    }

    .feelLuckyText.en,.feelLuckyText.vi,.feelLuckyText.th {
        font-size: 11px
    }

    .feelLuckyText.id {
        font-size: 10px
    }

    .feelLuckyButton.id {
        width: 135px;
        left: 30%
    }

    .flex-container {
        max-height: 70px;
        max-width: 60px
    }

    .game-overlay:hover>.flex-container {
        margin-top: -4px
    }

    .first>.game-overlay:hover>.flex-container>.flex-item {
        max-width: 60px;
        transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        margin-top: 0
    }

    .vendorEnterButton {
        position: relative;
        margin-top: 0;
        right: 0
    }
}

@media only screen and (max-width: 1250px) {
    .recentWinnerHolder {
        min-height:220px
    }

    .gamebannerImage {
        height: 220px
    }

    .homebannerImage {
        height: 160px
    }

    .gamebannerImage1 {
        height: 280px
    }

    .gamebannerContent {
        padding: 20px 10px 10px;
        top: 25px
    }

    .gamebannerTitle {
        font-size: 30px
    }

    .gamebannerDesc {
        font-size: 40px
    }

    .gamebannerMiniDesc {
        font-size: 22px
    }

    .jackpotCombineHolder {
        margin: 0 auto 0
    }

    .feelLuckyButton {
        font-size: 12px;
        padding: 6px;
        left: 29%;
        top: 37%
    }

    .icon-down_btn {
        top: 8px
    }

    .feelLuckyText,.feelLuckyText.vi,.feelLuckyText.th,.feelLuckyText.en {
        left: 27%;
        font-size: 10px
    }

    .feelLuckyText.id {
        top: 13%;
        left: 30%;
        font-size: 9px
    }

    .feelLuckyButton.id {
        top: 40%;
        left: 33%;
        font-size: 11px
    }

    .feelLuckyGamePanel {
        height: 70px
    }

    .iconLucky,.iconLuckyDownload,.iconLuckyDownload {
        height: 42px
    }

    .slotsGamesList>.newGameSlotGamePanelBoxWrap {
        min-height: 165px
    }

    .listViewPlayNow {
        width: 115px;
        left: 36%
    }

    .listViewTryNow,.listViewDetail {
        width: 115px;
        left: 62%
    }

    .listViewFontSize {
        font-size: 12px;
        text-transform: uppercase
    }

    .flex-container {
        max-height: 67px
    }

    .first>.flex-container>.flex-item {
        max-width: 100px;
        max-height: 95px
    }

    .game-overlay:hover>.flex-container {
        margin-top: -4px
    }

    .first>.game-overlay:hover>.flex-container>.flex-item {
        max-width: 61px;
        transition: all 1.1s ease;
        -moz-transition: all 1.1s ease;
        -ms-transition: all 1.1s ease;
        -webkit-transition: all 1.1s ease;
        -o-transition: all 1.1s ease;
        margin-top: 0
    }

    .vendorEnterButton {
        position: relative;
        margin-top: 0;
        right: 0
    }
}

.keno-game-Vendor-Title {
    margin: 5px 25px;
    font-size: 22px;
    font-weight: bold
}

@media only screen and (max-width: 1199px) {
    .gameSearchBox {
        width:90%
    }

    .listViewPlayNow {
        width: 115px;
        left: 37%
    }

    .listViewTryNow {
        width: 115px;
        left: 62%
    }

    .gameDetailsSelectionUl>li {
        padding: 0 8px
    }

    .btnPlayReal {
        width: 115px;
        height: 33px;
        line-height: 25px;
        margin: 8px auto
    }

    .btnLiveDetail {
        width: 115px;
        height: 33px;
        line-height: 25px
    }

    .btnPlayRealSmallIcon,.btnDemoRealSmallIcon {
        width: 89px;
        height: 28px;
        line-height: 20px
    }

    .btnPlayRealSmallIcon>.playNowHolder,.btnDemoRealSmallIcon>.demoDetailsBtn {
        font-size: 12px;
        line-height: 20px
    }

    .playNowHolder,.demoDetailsBtn {
        line-height: 20px;
        font-size: 13px
    }

    .feelLuckyButton.id {
        width: 120px;
        font-size: 10px
    }

    .feelLuckyText.id {
        left: 25%
    }

    .KygGamePanelBoxWrap.first .slotGameImage {
        height: 353px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 156px
    }

    .KygGamePanelBoxWrap {
        min-height: 240px
    }

    .flex-item {
        margin-left: -24px
    }

    .vendorEnterButton {
        position: relative;
        margin-top: 0;
        right: 0
    }
}

@media only screen and (max-width: 1160px) {
    .gameDetailsSelectionUl {
        width:55%
    }

    .gameNameInfoWrapKYG.gameNamePositioningGrid>.gamesName {
        width: 44%
    }
}

@media only screen and (max-width: 1120px) {
    .KygGamePanelBoxWrap.first .slotGameImage {
        height:332px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 144px
    }

    .KygGamePanelBoxWrap {
        min-height: 290px
    }

    .vendorEnterButton {
        position: relative;
        margin-top: 0;
        right: 0
    }
}

@media only screen and (max-width: 1520px) {
    #search-button-close {
        top:7px!important
    }
}

@media only screen and (max-width: 1099px) {
    .gameSearchField {
        right:7%
    }

    .progressiveJackpotHolder>.icon-fun88_bookmark_star_checked {
        display: none
    }

    .livecasinoDetail {
        top: 20%
    }

    .gamebannerImage1 {
        height: 290px
    }

    .gameDetailsSelectionUl>li {
        padding: 0 7px
    }

    .gameBoardSelectionList {
        font-size: 11px
    }

    .freePlayHolder {
        margin-top: -5px
    }

    .chkBoxText {
        font-size: 13px
    }

    .radioBoxText {
        font-size: 13px
    }

    .listViewTryNow,.listViewDetail {
        width: 90px;
        left: 65%
    }

    .listViewPlayNow {
        width: 90px;
        left: 42%
    }

    #search-button-close {
        right: 5px!important
    }

    .gameSearchBox {
        width: 100%
    }

    .feelLuckyButton.vi,.feelLuckyButton {
        width: 120px;
        font-size: 10px
    }

    .feelLuckyText.id {
        top: 15%;
        font-size: 8px
    }

    .feelLuckyButton.id {
        width: 110px;
        font-size: 9px;
        left: 31%
    }

    .flex-container {
        max-height: 60px;
        max-width: 54px
    }

    .first>.flex-container>.flex-item {
        max-height: 90px
    }

    .game-overlay:hover>.flex-container {
        margin-top: -4px
    }

    .first>.game-overlay:hover>.flex-container>.flex-item {
        max-width: 55px;
        transition: all 1.2s ease;
        -moz-transition: all 1.2s ease;
        -ms-transition: all 1.2s ease;
        -webkit-transition: all 1.2s ease;
        -o-transition: all 1.2s ease
    }

    .flex-item {
        margin-left: -20px
    }
}

@media screen and (max-width: 83.9375em) {
    .freePlayHolder {
        margin-top:-5px
    }

    .livecasino.desktop.gameSlotInfoWrapper {
        display: none!important
    }

    .gameNameInfoWrap.gameNamePositioningGrid>.livecasino.gamesName {
        width: 100%
    }

    .gameNameInfoWrap.gameNamePositioningGrid>.sportsbook.gamesName {
        width: 100%
    }
}

@media screen and (min-width: 84em) {
    .livecasino.mobile.gameSlotInfoWrapper {
        display:none!important
    }
}

@media only screen and (max-width: 1023px) {
    .homebannerImage {
        display:block
    }

    .vendorBox.zh {
        height: 300px
    }
}

@media screen and (max-width: 64em) {
    .p2pEnterLobby {
        position:absolute;
        margin-top: 0;
        right: auto
    }

    .gameNameInfoWrapKYG.gameNamePositioningGrid>.gamesName {
        width: 100%
    }

    .KygGamePanelBoxWrap .gameSlotInfoWrapper {
        display: none
    }
}

@media only screen and (max-width: 63em) {
    .jackpotBtn {
        position:relative
    }

    s .totalJackpotAmountHolder {
        display: table;
        margin: 0 auto;
        float: none
    }

    .listViewTryNow,.listViewDetail {
        width: 150px;
        left: 55%
    }

    .listViewPlayNow {
        width: 150px;
        left: 30%
    }

    .listView:nth-child(4n) {
        background-color: transparent
    }

    .listView:nth-child(4n-1) {
        background-color: transparent
    }

    .listView:nth-child(odd) {
        background-color: #f2f2f2
    }

    .viewAllSpanWording {
        font-weight: bolder;
        color: #2199e8;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        width: 15%
    }

    .slotFilterUnorderedList {
        margin-bottom: 1.5rem
    }

    .filterSectionHolder,.sortSectionHolder {
        background-color: #25aae1;
        position: relative;
        display: none;
        border-top: 1px solid #cacaca
    }

    .filterTitle {
        font-size: 36px;
        color: #fff;
        font-weight: bold
    }

    .categoryFilterTitle,.filterVendorTitleName,.filterGameTypeName,.filterGameFeatures,.filterGameLine {
        font-size: 16px;
        color: #fff;
        font-weight: bold
    }

    .chkBoxText,.radioBoxText,#fromAmount,#toAmount {
        color: #fff
    }

    .slotDropDownListSmallArrowButton {
        right: 5px;
        color: #fff;
        top: 13px
    }

    .btnPlayReal.playRealBtnNotSupportFree,.btnPlayRealSmallIcon.playRealBtnNotSupportFree {
        margin: 15px auto;
        width: 115px;
        height: 33px;
        line-height: 25px
    }

    .btnPlayRealSmallIcon>.playNowHolder,.btnDemoRealSmallIcon>.demoDetailsBtn {
        font-size: 13px
    }

    .slotsGamesList>.newGameSlotGamePanelBoxWrap {
        min-height: 220px
    }
}

@media only screen and (max-width: 950px) {
    .gamebannerImage {
        height:220px
    }

    .homebannerImage {
        height: 160px
    }

    .gamebannerImage1 {
        height: 270px
    }

    .gamebannerContent {
        padding: 20px 10px 10px;
        top: 25px
    }

    .gamebannerTitle {
        font-size: 30px
    }

    .gamebannerDesc {
        font-size: 40px
    }

    .gamebannerMiniDesc {
        font-size: 22px
    }

    .padTopTwelve {
        padding-top: 0
    }

    .viewAllSpanWording {
        width: 10%
    }

    #mobile-sort-ddl-holder {
        font-size: 16px;
        min-height: 0
    }

    #mobile-sort-ddl>li {
        font-size: 16px;
        padding: 10px 15px
    }

    .mobileRadioBoxWrap {
        margin-top: 0
    }

    #titleSorting {
        padding: 10px 15px
    }

    .icon-filter_expand {
        right: 20px;
        top: 19px
    }

    .flex-container {
        max-width: 55px
    }
}

@media only screen and (max-width: 899px) {
    .newGameSlotGamePanelBoxWrap {
        min-height:170px
    }

    .feelLuckyButton,.feelLuckyButton.id,.feelLuckyButton.vi {
        font-size: 9px
    }

    .icon-down_btn {
        top: 6px
    }

    .feelLuckyText {
        left: 24%;
        top: 11%
    }

    .feelLuckyButton,.feelLuckyButton.vi {
        width: 105px
    }

    .flex-container {
        max-height: 65px
    }

    .KygGamePanelBoxWrap.first .slotGameImage {
        height: 333px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 144px
    }

    .flex-container {
        max-height: 65px
    }

    .first>.game-overlay:hover>.flex-container>.flex-item {
        max-width: 56px
    }
}

@media only screen and (max-width: 830px) {
    .feelLuckyText {
        left:23%;
        top: 19%
    }

    .slotGamePanelBoxWrap {
        min-height: 230px
    }

    .sportsbookGamePanelBoxWrap {
        min-height: 130px
    }

    .KygGamePanelBoxWrap {
        min-height: 270px
    }

    .KygGamePanelBoxWrap.first:not(.list) {
        min-height: 190px
    }

    .KygGamePanelBoxWrap.first .slotGameImage {
        height: 385px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 170px
    }

    .feelLuckyButton,.feelLuckyButton.vi {
        width: 110px;
        top: 45%
    }

    .feelLuckyButton.id {
        top: 40%
    }

    .feelLuckyText.id {
        font-size: 8px
    }

    .feelLuckyButton.vi {
        width: 110px;
        top: 58%
    }

    .feelLuckyButton.id {
        top: 35%
    }

    .icon-down_btn {
        padding-left: 0
    }

    .downloadFeelLuckyText {
        margin-left: 17px
    }

    .flex-container {
        max-height: 55px
    }

    .first>.flex-container>.flex-item {
        max-height: 95px
    }

    .game-overlay:hover>.flex-container {
        margin-top: -3px
    }

    .first>.game-overlay:hover>.flex-container>.flex-item {
        margin-top: -1px
    }
}

@media only screen and (max-width: 815px) {
    .game-overlay>.playNowIconOverlay {
        width:55px
    }

    .livecasinoDetail {
        top: 16%
    }

    .feelLuckyText {
        left: 17%;
        top: 10%
    }

    .viewAllSpanWording {
        width: 14%
    }

    .gameh-centerwrap {
        width: 55px;
        height: 50px;
        border-radius: 50%;
        position: absolute;
        transition: all .7s ease
    }

    .feelLuckyText.en,.feelLuckyText.vi,.feelLuckyText.th {
        font-size: 9px
    }

    .feelLuckyButton.id {
        width: 100px;
        left: 30%
    }

    .feelLuckyText.id {
        left: 20%
    }

    .flex-container {
        max-width: 51px
    }

    .first>.game-overlay:hover>.flex-container>.flex-item {
        max-width: 50px
    }

    .flex-item {
        margin-left: -17px
    }

    .game-overlay:hover>.flex-container {
        margin-top: -4px
    }

    .first>.game-overlay:hover>.flex-container>.flex-item {
        margin-top: 0
    }
}

@media only screen and (max-width: 753px) {
    .livecasinoDetail {
        top:13%
    }

    #firstLoadPanel>.slotholder.show:last-child {
        margin-top: 60px
    }

    .totalJackpotDivHolder {
        top: -70px
    }

    .feelLuckyButton,.feelLuckyButton.vi {
        width: 100px;
        font-size: 8px
    }

    .feelLuckyText.id {
        font-size: 7px;
        left: 24%
    }

    .flex-container {
        max-height: 50px;
        max-width: 50px
    }

    .first>.flex-container>.flex-item {
        max-height: 85px
    }
}

@media only screen and (max-width: 725px) {
    .feelLuckyText.en,.feelLuckyText.vi,.feelLuckyText.th {
        font-size:8px
    }

    .KygGamePanelBoxWrap.first .slotGameImage {
        height: 335px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 146px
    }

    .KygGamePanelBoxWrap {
        min-height: 250px
    }

    .feelLuckyText.id {
        font-size: 7px;
        left: 22%
    }

    .feelLuckyButton.id {
        width: 95px;
        font-size: 8px
    }

    .freePlayHolder.id {
        font-size: .8em;
        margin-top: -16px
    }

    .gamesName.id {
        font-size: .8em
    }

    .first>.flex-container>.flex-item {
        margin-top: 1px
    }

    .gamebannerImage1 {
        height: 240px
    }
}

@media only screen and (max-width: 690px) {
    .feelLuckyButton.id {
        width:85px;
        font-size: 7px;
        left: 33%;
        top: 50%
    }

    .feelLuckyText.id {
        font-size: 8px;
        left: 25%
    }

    .feelLuckyButton,.feelLuckyButton.vi {
        width: 95px
    }

    .slotsGamesList>.newGameSlotGamePanelBoxWrap {
        min-height: 145px
    }

    .mobileTitleFilter {
        font-size: 18px;
        font-weight: lighter;
        padding: 25px 0 10px 15px;
        display: block
    }

    .flex-container {
        max-height: 46px;
        max-width: 48px
    }

    .first>.flex-container>.flex-item {
        max-height: 85px;
        margin-top: 0
    }
}

@media only screen and (max-width: 640px) {
    .vendorEnterButton {
        position:inherit;
        margin-top: 0
    }
}

@media only screen and (max-width: 660px) {
    .feelLuckyText.en,.feelLuckyText.vi,.feelLuckyText.th {
        font-size:7px
    }

    .feelLuckyButton,.feelLuckyButton.vi {
        width: 90px;
        font-size: 7px
    }

    .KygGamePanelBoxWrap.first .slotGameImage {
        height: 307px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 132px
    }

    .KygGamePanelBoxWrap {
        min-height: 255px
    }

    .p2pEnterLobby {
        margin-top: -4px
    }
}

@media only screen and (max-width: 639px) {
    .listViewTryNow,.listViewDetail {
        width:120px;
        left: 60%
    }

    .game-overlay>.playNowIconOverlay {
        width: 70px
    }

    .gameh-centerwrap {
        width: 65px;
        height: 55px
    }

    .listViewPlayNow {
        width: 115px;
        left: 38%
    }

    .slotsGamesList>.newGameSlotGamePanelBoxWrap {
        min-height: 250px
    }

    .filterTitle {
        font-size: 18px
    }

    .categoryFilterTitle,.filterVendorTitleName,.filterGameFeatures,.filterGameLine,.filterGameTypeName {
        font-size: 12px
    }

    .slotDropDownListSmallArrowButton {
        right: 12px;
        top: 10px
    }

    .slotGamePanelBoxWrap {
        min-height: 275px
    }

    .sportsbookGamePanelBoxWrap {
        min-height: 175px
    }

    .KygGamePanelBoxWrap.first:not(.list) {
        min-height: 460px;
        width: 100%
    }

    .KygGamePanelBoxWrap {
        min-height: 280px
    }

    .padTopTwelve {
        padding-top: 30px
    }

    .newGameSlotGamePanelBoxWrap {
        min-height: 230px
    }

    .padTopThirtyTwo {
        padding-top: 45px
    }

    .feelLuckyGamePanel {
        height: 81px
    }

    .feelLuckyGamePanel.id {
        height: 100px
    }

    .iconLucky,.iconLuckyDownload,.iconLuckyDownload {
        height: 55px
    }

    .feelLuckyText,.feelLuckyText.en,.feelLuckyText.vi,.feelLuckyText.th {
        left: 26%;
        top: 16%;
        font-size: 14px
    }

    .feelLuckyText.id {
        font-size: 11px
    }

    .feelLuckyButton,.feelLuckyButton.vi {
        width: 160px;
        top: 42%;
        font-size: 13px
    }

    .feelLuckyButton.id {
        width: 140px;
        top: 45%;
        font-size: 12px
    }

    .icon-down_btn {
        top: 9px
    }

    .flex-container {
        max-height: 52px;
        max-width: 60px
    }

    .first>.flex-container>.flex-item {
        max-height: 85px
    }

    .KygGamePanelBoxWrap.first .slotGameImage {
        height: 400px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 200px
    }

    .gameNameInfoWrap.gameNamePositioningList {
        display: table
    }
}

@media only screen and (max-width: 545px) {
    .feelLuckyText.en,.feelLuckyText.vi,.feelLuckyText.id,.feelLuckyText.th {
        font-size:12px
    }

    .feelLuckyButton.id {
        top: 50%
    }

    .feelLuckyButton {
        width: 135px
    }

    .flex-container {
        max-height: 60px;
        max-width: 58px
    }

    .KygGamePanelBoxWrap.first .slotGameImage {
        height: 343px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 169px
    }

    .KygGamePanelBoxWrap.first:not(.list) {
        min-height: 405px
    }

    .KygGamePanelBoxWrap {
        min-height: 270px
    }
}

@media only screen and (max-width: 520px) {
    .mobileSortPanelSortTitlesHolder {
        font-size:14px
    }

    .game-overlay>.playNowIconOverlay {
        width: 70px
    }

    .slotsGamesList>.newGameSlotGamePanelBoxWrap {
        min-height: 200px
    }

    .listViewTryNow,.listViewDetail {
        width: 100px;
        left: 66%
    }

    .listViewPlayNow {
        width: 100px;
        left: 43%
    }

    .feelLuckyGamePanel {
        height: 63px
    }

    .feelLuckyGamePanel.id {
        height: 85px
    }

    .iconLucky,.iconLuckyDownload,.iconLuckyDownload {
        height: 40px
    }

    .feelLuckyButton.id {
        width: 120px;
        top: 58%;
        font-size: 11px;
        left: 35%
    }

    .feelLuckyButton.id#feelLuckyDownload {
        top: 43%
    }

    .feelLuckyButton.vi {
        width: 135px;
        font-size: 11px
    }

    .icon-down_btn {
        top: 7px
    }

    .feelLuckyText,.feelLuckyText.vi,.feelLuckyText.id {
        font-size: 12px
    }

    .viewAllSpanWording {
        width: 25%
    }

    .viewAllSpanWording.en {
        width: 28%
    }

    .viewAllSpanWording.vi {
        width: 33%
    }

    .chkBoxText {
        font-size: 11px
    }

    .radioBoxText {
        font-size: 11px
    }
}

@media only screen and (max-width: 490px) {
    .slotGamePanelBoxWrap {
        min-height:225px
    }

    .sportsbookGamePanelBoxWrap {
        min-height: 125px
    }

    .recentWinnerHolder {
        min-height: 180px;
        z-index: 1000
    }

    .gamebannerImage {
        height: 180px
    }

    .homebannerImage {
        height: 110px
    }

    .gamebannerImage1 {
        height: 130px
    }

    .gamebannerContent {
        padding: 20px 10px 10px;
        top: 25px
    }

    .gamebannerTitle {
        font-size: 18px
    }

    .gamebannerDesc {
        font-size: 24px
    }

    .gamebannerMiniDesc {
        font-size: 12px
    }

    .padTopTwelve {
        padding-top: 0
    }

    .feelLuckyButton.id {
        left: 30%;
        top: 54%;
        font-size: 10px
    }

    .feelLuckyText,.feelLuckyText.vi,.feelLuckyText.id,.feelLuckyText.en,.feelLuckyText.th {
        left: 23%;
        top: 14%;
        font-size: 11px
    }

    .newGameSlotGamePanelBoxWrap {
        min-height: 200px
    }

    .icon-fun88_bookmark_star_checked {
        font-size: 16px
    }

    .jackpotPrizeWording {
        font-size: 18px
    }

    .jackpotTotalAmount {
        font-size: 16px
    }

    .flex-container {
        max-height: 49px;
        max-width: 60px
    }

    .first>.flex-container>.flex-item {
        max-height: 85px
    }

    .KygGamePanelBoxWrap.first .slotGameImage {
        height: 309px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 152px
    }

    .KygGamePanelBoxWrap.first:not(.list) {
        min-height: 370px
    }

    .KygGamePanelBoxWrap {
        min-height: 250px
    }
}

@media only screen and (max-width: 450px) {
    .feelLuckyText.en,.feelLuckyText.vi,.feelLuckyText.id,.feelLuckyText.th {
        font-size:10px
    }

    .feelLuckyButton,.feelLuckyButton.vi {
        width: 120px;
        font-size: 10px
    }
}

@media only screen and (max-width: 420px) {
    .slotGamePanelBoxWrap {
        min-height:230px
    }

    .sportsbookGamePanelBoxWrap {
        min-height: 130px
    }

    .gameNamePositioningGrid {
        margin-left: auto;
        margin-top: 5px
    }

    .KygGamePanelBoxWrap.first:not(.list) {
        min-height: 305px
    }

    .KygGamePanelBoxWrap {
        min-height: 225px
    }

    .KygGamePanelBoxWrap.first .slotGameImage {
        height: 250px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 130px
    }

    .rolaImageNoGame {
        height: 320px
    }

    .gamesNumberMobileHolder {
        font-size: 14px
    }

    .gamesNumberMobileHolder.vi {
        font-size: 14px
    }

    .mobileSortPanelSortTitlesHolder {
        font-size: 12px
    }

    .feelLuckyText,.feelLuckyText.vi,.feelLuckyText.id {
        left: 31%;
        top: 19%;
        font-size: 9px
    }

    .listViewPlayNow {
        width: 76px;
        left: 43%
    }

    .listViewTryNow,.listViewDetail {
        width: 76px;
        left: 66%
    }

    .feelLuckyButton {
        width: 100px;
        font-size: 9px
    }

    .feelLuckyButton.vi,.feelLuckyButton.id {
        width: 100px;
        font-size: 8px
    }

    .feelLuckyButton.id {
        left: 35%
    }

    .icon-down_btn {
        top: 5px
    }

    .slotsGamesList>.newGameSlotGamePanelBoxWrap {
        min-height: 170px
    }

    .game-overlay>.playNowIconOverlay {
        width: 50px
    }

    .gameh-centerwrap {
        width: 55px;
        height: 55px
    }

    .flex-container {
        max-height: 53px;
        max-width: 53px
    }

    .flex-item {
        margin-left: -20px
    }
}

@media only screen and (max-width: 390px) {
    .KygGamePanelBoxWrap.first:not(.list) {
        min-height:268px
    }

    .KygGamePanelBoxWrap {
        min-height: 210px
    }

    .KygGamePanelBoxWrap.first .slotGameImage {
        height: 210px
    }

    .KygGamePanelBoxWrap .slotGameImage {
        height: 100px
    }

    .listViewPlayNow {
        width: 76px;
        left: 30%
    }

    .listViewTryNow,.listViewDetail {
        width: 76px;
        left: 56%
    }

    .slotsGamesList>.newGameSlotGamePanelBoxWrap {
        min-height: 155px
    }

    .btnPlayReal {
        margin: 10px auto;
        width: 89px;
        height: 28px;
        line-height: 20px
    }

    .btnLiveDetail {
        width: 89px;
        height: 28px;
        line-height: 20px
    }

    .btnPlayReal.playRealBtnNotSupportFree {
        margin: 10px auto
    }

    .playNowHolder,.demoDetailsBtn {
        font-size: 12px;
        line-height: 23px
    }

    .dark-grey-bold-text {
        font-size: 13px
    }

    .newGameSlotGamePanelBoxWrap {
        min-height: 160px
    }

    .sportsbookGamePanelBoxWrap {
        min-height: 60px
    }

    .feelLuckyText,.feelLuckyText.vi,.feelLuckyText.th,.feelLuckyText.en {
        left: 23%;
        top: 12%;
        font-size: 8px
    }

    .feelLuckyButton {
        width: 84px;
        font-size: 8px
    }

    .feelLuckyButton.vi,.feelLuckyButton.id {
        width: 90px;
        font-size: 7.5px
    }

    .feelLuckyGamePanel:nth-child(n+2) {
        margin-top: 5px
    }

    .icon-fun88_bookmark_star_checked {
        font-size: 14px
    }

    .jackpotPrizeWording {
        font-size: 16px
    }

    .jackpotTotalAmount {
        font-size: 14px
    }

    .categoryFilterTitle,.filterVendorTitleName,.filterGameFeatures,.filterGameLine,.filterGameTypeName {
        font-size: 10px
    }

    .slotDropDownListSmallArrowButton {
        top: 8px
    }

    .flex-container {
        max-height: 57px;
        max-width: 50px
    }
}

@media only screen and (max-width: 320px) {
    .vendorBox.id {
        height:325px
    }

    .vendorBox.th,.vendorBox.vi,.vendorBox.en {
        height: 305px
    }

    .filterTitle {
        font-size: 18px
    }

    .keno-Game-Wrapper {
        padding: 0!important
    }

    .flex-container {
        max-height: 49px;
        max-width: 46px;
        right: -9px
    }

    .gamebannerImage1 {
        height: 120px
    }
}

#SpecialPromo .icon,.quickTransfer .promotion-tab-title-logo {
    font-size: 65px
}

.promotions .icon.promoClock,.promolistholder .icon.promoClock {
    font-size: 16px;
    margin-top: 16px;
    margin-right: 5px
}

#SpecialPromo {
    margin-top: 30px;
    min-height: 150px
}

#SpecialPromo tbody {
    border: none
}

#SpecialPromo tr {
    background-color: transparent
}

#SpecialPromo .icon-clock {
    font-size: 18px;
    margin-right: 5px
}

.Hide-Banner-Button {
    display: none
}

.promoTitle {
    color: #2c2c2c;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer
}

.expandDetail>.promoDetails {
    position: absolute
}

#rewardsPointText {
    color: #39ca63;
    font-size: 16px
}

.couponTextField {
    padding-right: 0
}

#detailsRewardsPoint {
    color: #25aae2;
    font-size: 12px
}

.promoDesc {
    color: #8e8e8e;
    font-size: 14px
}

.expireTime {
    color: #2c2c2c
}

#modal-promo .expireTime span,#modal-promo-detail .expireTime span {
    display: inline-block;
    text-align: center;
    height: 27px;
    line-height: 27px
}

#modal-promo .expireTime>span>span,#modal-promo-detail .expireTime>span>span {
    margin-right: 2px
}

#modal-promo .expireTime>span>span:last-child,#modal-promo-detail .expireTime>span>span:last-child {
    color: #8e8e8e
}

#modal-promo .expireTime span span span,#modal-promo-detail .expireTime span span span {
    color: #fff;
    background: url("https://www.enhuoyea11.net/Assets/images/general/countdown.jpg") no-repeat;
    letter-spacing: 7px;
    text-indent: 7px
}

.promotions:not([data-id='null'])[data-runningrebate='true']>span.promoButtonAlign {
    line-height: 24px
}

.promotions:not([data-id='null'])[data-runningrebate='true']>div.promoButtonAlign {
    line-height: 30px
}

#BonusDisplay {
    margin-top: 18px;
    margin-left: 46px
}

#BonusDisplay.bonusDisplayHeader {
    width: 90%;
    margin: 18px auto 0
}

.promotion-tab-title-logo {
    width: 65px
}

#promoCodeBtn {
    width: 84px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 14px;
    height: 32px;
    line-height: 20px;
    cursor: pointer;
    float: right
}

.moreGrpingPromo {
    color: #3f97ff;
    cursor: pointer
}

.moreGrpingPromo>span:after {
    border-color: #3f97ff transparent transparent transparent;
    border-style: solid;
    content: "";
    display: inline-block;
    height: 0;
    position: absolute;
    margin-top: 22px;
    width: 0;
    margin-left: 5px;
    border-width: 5px
}

.moreGrpingPromo:hover {
    color: #1585cf
}

#promoCode {
    margin-bottom: 0;
    padding-right: 5px;
    margin-right: 15px
}

.hideTime {
    display: none
}

.promolistholder>div.promotions {
    border-top: 0
}

.promotions,.promolistholder {
    line-height: 50px
}

.promotions .title,.promolistholder .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.promotions .icon,.promolistholder .icon {
    font-size: 33px;
    margin-top: 8px;
    float: left;
    margin-right: 10px
}

.hr {
    max-width: none;
    border-color: #dcdcdc;
    margin: 0
}

.promo-arrow {
    display: inline-block
}

.promo-arrow .icon {
    font-size: 6px;
    float: right;
    margin-bottom: 3px;
    cursor: pointer
}

#promoCat,#promoVersType {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    text-overflow: ellipsis
}

.promoRunningList {
    min-height: 325px
}

.promoRunningList #promoCat>span:after,.promoRunningList #promoVersType>span:after {
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    content: "";
    display: inline-block;
    height: 0;
    position: absolute;
    top: 40%;
    width: 0;
    margin-left: 5px;
    border-width: 5px;
    right: 10%
}

.circleLevel {
    top: 50%;
    font-size: 16px;
    position: absolute;
    font-weight: bold
}

.levelInCircleOne {
    color: #25aae2;
    left: 37%
}

.levelInCircleOne.vi,.levelInCircleOne.en {
    left: 25%
}

.levelInCircleOne.th {
    left: 30%
}

.levelInCircleTwo {
    color: #9a9a9a;
    font-size: 40px;
    font-weight: 400;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-20%)
}

#circleProgressBarTwo>.progressbar-text {
    font-size: 16px;
    font-weight: bolder
}

#circleProgressBarTwo.th>.progressbar-text,#circleProgressBarTwo.vi>.progressbar-text,#circleProgressBarTwo.id>.progressbar-text,#circleProgressBarTwo.en>.progressbar-text {
    font-size: 18px;
    line-height: 1;
    text-align: center
}

.circleProgressBar {
    position: relative;
    width: 186px;
    height: 186px;
    margin: 0 auto;
    float: left
}

#circleProgressBarOne>.progressbar-text {
    font-size: 40px
}

.progressbar-text {
    font-weight: 400;
    position: absolute
}

.circleProgressBarDivs>div {
    font-size: 18px
}

.promoApplicableTitle {
    float: left;
    margin-top: 60px;
    margin-left: 30px;
    font-size: 16px;
    line-height: 25px;
    font-weight: bold
}

#promoCatList {
    display: none;
    position: absolute;
    background-color: #fff;
    cursor: pointer;
    background-color: #25aae2;
    color: #fff;
    width: 14.57%;
    z-index: 50
}

#promoSecondCatList {
    display: none;
    position: absolute;
    background-color: #fff;
    cursor: pointer;
    left: 16.7%;
    background-color: #25aae2;
    color: #fff;
    width: 14.55%;
    z-index: 50
}

#promoCatList>div,#promoSecondCatList>div {
    line-height: 25px;
    height: 30px;
    font-size: 16px;
    padding: 0 5px 5px 13px;
    z-index: 40
}

#promoCatList>div:hover,#promoSecondCatList>div:hover {
    color: #000;
    background-color: #fff
}

#container>div:first-child {
    line-height: 25px;
    height: 25px;
    font-size: 16px;
    margin-bottom: 10px;
    user-select: none;
    cursor: default
}

.promoOptionBtn {
    cursor: pointer;
    line-height: 37px;
    font-size: 15px;
    height: 37px;
    border: #dcdcdc 1px solid;
    font-weight: bold
}

.promoOptionBtn#myPromoHistory {
    cursor: pointer;
    display: none
}

.promoOptionBtn.promoSelected {
    border: #94c8fd 1px solid;
    border-bottom: #fff 1px solid;
    color: #25aae1
}

.promoMoreInfoBtn {
    min-height: 51px
}

.promoDateColumn>span {
    display: none
}

.searchloader {
    cursor: default!important;
    opacity: .4
}

#container .tncUrl {
    font-size: 15px;
    color: #2c2c2c;
    font-weight: bold;
    cursor: pointer
}

#container .tncUrl:hover,#container .tncUrl:focus {
    color: #3f97ff
}

.specialPromoCont .tncUrl {
    cursor: pointer
}

.specialPromoCont .tncUrl:hover,.specialPromoCont .tncUrl:focus {
    color: #3f97ff
}

.promoHeader {
    font-size: 18px;
    font-weight: bold;
    color: #2c2c2c
}

.promoHeader.selectionField {
    background-color: #25aae2;
    color: #fff;
    border-radius: 3px 3px 0 0;
    width: 35%;
    line-height: 30px
}

.promoHeader.selectionField.history {
    font-size: 18px;
    font-weight: bold;
    color: #2c2c2c;
    width: 100%;
    background-color: #fff
}

#myPromoContainer {
    height: 37px;
    border-bottom: #dcdcdc 1px solid;
    margin-top: 34px
}

#promoPageMainTitle {
    color: #2c2c2c;
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 30px;
    display: inline-block
}

#promoPageMainDesc {
    color: #8e8e8e;
    font-size: 16px;
    margin-bottom: 34px;
    display: inline-block
}

.myPromoSmallCont {
    height: 20px;
    vertical-align: text-bottom
}

.specialPromoSmallCont {
    height: 65px;
    padding: 10px 0 10px 10px;
    margin-left: 65px
}

.myPromoNum {
    font-size: 48px;
    color: #25aae1;
    padding-right: 15px!important
}

.myPromoTitle {
    font-size: 16px;
    font-weight: bold;
    color: #2c2c2c
}

.myPromoDesc {
    font-size: 14px;
    color: #8e8e8e
}

#myPromoSummary {
    margin: 18px 0
}

.promoBtn {
    line-height: initial
}

.specialPromoTitle {
    font-size: 18px;
    color: #2c2c2c;
    font-weight: bold
}

.specialPromoTitleContainer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.specialPromoTime {
    font-size: 16px;
    color: #2c2c2c
}

.specialPromoSmallrow::after {
    content: none
}

.specialPromoReadMore {
    color: #3f97ff;
    font-size: 12px;
    min-width: 105px;
    display: inline-block;
    cursor: pointer
}

.specialPromoApply,.specialPromoReadMoreCont {
    text-align: center
}

#featuredPromoCont {
    padding-right: 50px
}

.specialBigTitle {
    font-size: 18px;
    color: #2c2c2c;
    margin-bottom: 22px
}

.promoDetails {
    color: #3f97ff;
    font-size: 12px;
    cursor: pointer
}

.normalTable,.normalTable tr,.normalTable td,.normalTable tbody {
    border: none;
    background-color: initial;
    padding: 0
}

.normalTable td:first-child {
    width: 1%
}

.normalTable .myPromoSmallCont {
    width: 100%
}

.normalTable .float-right.specialPromoReadMoreCont {
    text-align: right
}

.percentage {
    fill: #f8f9f9;
    text-shadow: 1px 1px 1px black
}

#divCouponTransfer {
    margin-left: 0
}

#txtCouponTransfer {
    width: 96%
}

.mandatory {
    vertical-align: top
}

#promoLoading {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    min-height: 65px
}

.middleContent {
    text-align: center
}

.rolaImage {
    margin-top: -60px
}

#featuredPromoCont .divInline,#endingPromoCont .divInline {
    display: inline-block;
    padding-left: 0;
    padding-right: 0
}

#featuredPromoCont .divSec,#featuredPromoCont .divThird,#endingPromoCont .divSec,#endingPromoCont .divThird {
    vertical-align: top;
    line-height: 30px
}

#featuredPromoCont .divThird,#featuredPromoCont .specialPromoBonusId,#endingPromoCont .divThird,#endingPromoCont .specialPromoBonusId {
    float: right
}

#featuredPromoCont .specialPromoTitle,#endingPromoCont .specialPromoTitle {
    word-wrap: break-word
}

#featuredPromoCont .specialPromoReadMore,#endingPromoCont .specialPromoReadMore {
    text-align: center;
    float: right
}

@media only screen and (max-width: 1023px) {
    .promotions .icon.promoClock,.promolistholder .icon.promoClock {
        margin-left:10px
    }

    #divEndPromoSoon {
        margin-top: 30px
    }

    .promotions>.promoMoreInfoBtn.expandDetail {
        text-align: center
    }

    #featuredPromoCont,#endingPromoCont {
        padding-right: 15px
    }

    #promoSecondCatList {
        left: 26%
    }

    .row.expanded.ddlSelectionHolder {
        max-width: 90%
    }

    .ddlSelectionHolder {
        position: absolute;
        width: 100%;
        top: 5px;
        left: 20px
    }
}

.promoReward {
    line-height: 24px;
    margin-bottom: 0
}

#myPromoUpdatedDate {
    text-align: right;
    line-height: 37px;
    font-size: 15px;
    height: 37px;
    font-weight: bold
}

#myPromoUpdatedDate span {
    font-weight: initial
}

#BtnFundInWallet {
    margin: 10px auto 0
}

#BtnFundInWallet tbody {
    border: none
}

#BtnFundInWallet td {
    padding: 5px
}

#BtnFundInWallet td input {
    margin: 0;
    vertical-align: middle
}

@media only screen and (max-width: 940px) {
    #myPromoUpdatedDate.modifyLineHeightvi {
        line-height:19px;
        font-size: 14px;
        text-align: left
    }

    .historyPromoDate {
        width: 100%
    }
}

@media only screen and (max-width: 720px) {
    #promoCode input {
        width:95%
    }
}

@media only screen and (max-width: 319px) {
    .promoOptionBtn {
        font-size:11px
    }

    #promoCode {
        margin-right: 0
    }
}

@media only screen and (max-width: 749px) {
    #myPromoUpdatedDate {
        text-align:left;
        line-height: 20px;
        font-size: 10px;
        margin-top: 0;
        margin-bottom: 10px
    }
}

@media only screen and (max-width: 1024px) {
    .row.collapse>.column.historyData.status {
        text-align:right;
        padding-right: 30px
    }

    .promoHistoryList>.promotions {
        line-height: 30px
    }

    .normalTable td.mobileAvailableRetrieve {
        position: absolute;
        width: 30%;
        top: 31.5%;
        right: 32px;
        text-align: right
    }

    .row.collapse>.column.historyData {
        padding-left: 30px
    }

    .historyPromoDate {
        line-height: 24px
    }

    .clockHolder {
        line-height: 14px
    }

    #featuredPromoCont .specialPromoReadMore,#endingPromoCont .specialPromoReadMore {
        float: none
    }

    .promotions .icon.promoClock,.promolistholder .icon.promoClock {
        margin-top: -3px
    }

    #container a.tncUrl {
        font-size: 16px;
        margin-left: 10px
    }

    .promoButtonAlign {
        padding-right: 15px
    }

    .detailsTncUrl.promoDetails {
        margin-left: 10px
    }

    .promolistholder>.expireTime,.promolistholder>.moreGrpingPromo {
        margin-top: 0
    }

    .promolistholder>.moreGrpingPromo {
        float: right;
        text-align: center
    }

    .row.collapse.promolistholder>.moreGrpingPromo {
        padding-left: 33px
    }

    .promoHeader.selectionField {
        width: 45%
    }

    .couponTextField {
        padding-right: 15px
    }

    #featuredPromoCont .divInline,#endingPromoCont .divInline {
        padding: 0 10px
    }

    .specialBigTitle {
        margin-bottom: 10px
    }

    .card {
        box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
        background: #fff;
        border-radius: 2px;
        margin: 1rem;
        position: relative;
        border-radius: 2px
    }

    .levelInCircleTwo {
        font-size: 30px
    }

    #rewardsPointText {
        font-size: 16px
    }

    #featuredPromoCont {
        margin: 1rem;
        padding-right: 0!important;
        min-height: 170px
    }

    .divInline.divThird {
        margin-top: 10px
    }

    #SpecialPromo {
        margin-top: 0;
        min-height: 150px
    }

    .mobileFeatureCardHeader {
        background-color: #25aae1;
        padding: 10px;
        color: #fff
    }

    .promoApplicableTitle {
        margin-top: 0;
        width: 100%
    }

    .promoApplicableTitle.rewards,.promoApplicableTitle.promos {
        width: 165px;
        text-align: center;
        padding-left: 0;
        position: absolute;
        bottom: -55px;
        left: -25px
    }

    #myPromoSummary {
        min-height: 280px;
        padding: 15px 0;
        margin: 1rem;
        margin-bottom: 0
    }

    .promotions:not([data-id='null'])>.promoButtonAlign {
        float: right;
        text-align: center
    }

    .promotions:not([data-id='null'])[data-status='NotEligible']>.promoButtonAlign {
        padding-left: 43px
    }

    .promotions>.promoMoreInfoBtn {
        text-align: left
    }

    .promotions>.expireTime {
        margin-top: -15px;
        margin-left: 10px
    }

    #promoCatList {
        width: 45%;
        top: .7%
    }

    #promoSecondCatList {
        left: 54.5%;
        top: .7%;
        width: 45%
    }
}

@media only screen and (max-width: 469px) {
    input[type="text"],input[type="Password"],select,input[type="tel"],[type=text],[type=password] {
        font-size:14px!important
    }

    span.promoBtn {
        margin-left: 15px
    }

    .progressBarMobileSvg {
        margin-left: 43px
    }

    .promoCodeBtnHolder {
        margin-right: 15px
    }

    .specialPromoTitle {
        font-size: 14px
    }

    #container a.tncUrl {
        font-size: 14px;
        font-weight: bold
    }

    #container a.tncUrl.groupingTncUrl {
        font-size: 14px
    }

    .circleProgressBar {
        width: 155px
    }

    .promoApplicableTitle.promos {
        bottom: -40px;
        left: -45px
    }

    .promoApplicableTitle.rewards {
        bottom: -25px;
        left: -45px
    }

    .circleLevel {
        top: 50%
    }

    .promoOptionBtn {
        font-size: 9px
    }

    .promoOptionBtn.zh {
        font-size: 15px
    }

    .levelInCircleOne.zh {
        left: 33%
    }
}

@media only screen and (max-width: 420px) {
    .circleProgressBar {
        width:125px;
        height: 140px
    }

    #circleProgressBarOne>.progressbar-text {
        font-size: 30px
    }

    #circleProgressBarTwo>.progressbar-text {
        font-size: 14px
    }

    #circleProgressBarTwo.vi>.progressbar-text {
        top: 30%!important
    }

    .levelInCircleOne.circleLevel {
        font-size: 14px
    }

    .levelInCircleTwo.circleLevel {
        font-size: 30px
    }

    .circleProgressBar.en>.levelInCircleTwo.circleLevel {
        font-size: 22px
    }

    #myPromoSummary {
        min-height: 220px
    }

    .promoApplicableTitle.rewards,.promoApplicableTitle.promos {
        left: -50px
    }

    #promoCatList>div,#promoSecondCatList>div {
        font-size: 10px
    }

    #promoCatList.zh>div,#promoSecondCatList.zh>div {
        font-size: 14px
    }

    .promoHeader {
        font-size: 16px
    }
}

@media only screen and (max-width: 420px) {
    .normalTable td.mobileAvailableRetrieve {
        width:50%
    }
}

.icon-fun88_bookmark_star_checked {
    margin-right: 10px;
    font-size: 20px;
    color: #25aae1;
    font-weight: bolder
}

.jackpotPrizeWording {
    color: #25aae1;
    font-size: 22px
}

.jackpotTotalAmount {
    color: #a9a9a9;
    height: 31px;
    overflow: hidden
}

.dotSeperator {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 20px;
    right: 0
}

.commaSeperator {
    width: 9px;
    height: 12px;
    position: absolute;
    top: 20px;
    right: -3px
}

.dotSeperator {
    height: 16px;
    top: 13px
}

.SlotJackpotFrame {
    width: 25px;
    height: 31px;
    float: right;
    padding: 0 0;
    font-size: 31px;
    position: relative
}

.totalJackpotAmountHolder {
    font-weight: bolder;
    float: left;
    text-align: center
}

.jackpotCombineHolder {
    height: 32px;
    display: table;
    margin: 50px auto 0
}

.jackpotValueStyle {
    font-size: 12px;
    font-weight: bolder;
    padding-right: 0%
}

.jackpotCurrStyle {
    font-size: 12px;
    font-weight: bolder;
    padding-left: 25%
}

#headerControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame,#headerPtControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame {
    width: 24px
}

@media only screen and (max-width: 1499px) {
    #headerControlJackpot>.jackpotTotalAmount,#headerPtControlJackpot>.jackpotTotalAmount {
        position:relative;
        margin-top: 10px;
        margin-bottom: 5px;
        display: table
    }
}

@media only screen and (min-width: 1024px) and (max-width:1326px) {
    .vendor-Content-Wrapper.Announcement {
        padding-right:0
    }
}

@media only screen and (max-width: 1309px) {
    #headerControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame,#headerPtControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame {
        width:23px
    }
}

@media only screen and (min-width: 1269px) and (max-width:1275px) {
    #headerControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame,#headerPtControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame {
        width:22px
    }
}

@media only screen and (min-width: 1248px) and (max-width:1269px) {
    #headerControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame,#headerPtControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame {
        width:21px
    }
}

@media only screen and (min-width: 1209px) and (max-width:1248px) {
    #headerControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame,#headerPtControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame {
        width:20px
    }
}

@media only screen and (min-width: 1150px) and (max-width:1169px) {
    #headerControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame,#headerPtControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame {
        width:19px
    }

    .jodometer_id,.jodometer_vi,.jodometer_th,.jodometer_zh {
        right: 3px!important
    }
}

@media only screen and (min-width: 1048px) and (max-width:1150px) {
    #headerControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame,#headerPtControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame {
        width:19px
    }

    .jodometer_id,.jodometer_vi,.jodometer_th,.jodometer_zh {
        right: 2px!important
    }
}

@media only screen and (min-width: 1024px) and (max-width:1048px) {
    #headerControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame,#headerPtControlJackpot>.jackpotTotalAmount>.SlotJackpotFrame {
        width:18px
    }

    .jodometer_id,.jodometer_vi,.jodometer_th,.jodometer_zh {
        right: 1px!important
    }
}

@media only screen and (max-width: 640px) {
    .SlotJackpotFrame {
        width:20px
    }

    .SlotJackpotFrame>img {
        right: 2px!important
    }
}

.side-panel-wrapper {
    position: fixed;
    right: 0;
    padding-top: 64px;
    background-color: #f6f7f7;
    width: 42px;
    height: 100vh;
    top: 0;
    z-index: 6
}

#side-panel-bank-status-body>tbody {
    overflow-y: scroll;
    display: block;
    max-height: 60vh
}

@media only screen and (max-height: 530px) {
    #side-panel-bank-status-body>tbody {
        max-height:45vh
    }
}

@media only screen and (max-height: 380px) {
    #side-panel-bank-status-body>tbody {
        max-height:22vh
    }
}

.side-panel-table>tbody>tr {
    background-color: #f6f7f7!important
}

.side-panel-wrapper>div {
    height: 100%;
    width: 100%
}

.side-panel-wrapper>div>div {
    height: 100%;
    position: relative;
    background-color: #f6f7f7!important
}

.side-panel-table {
    width: 100%;
    height: auto;
    text-align: center;
    z-index: 1000;
    position: absolute
}

.side-panel-table>tbody {
    border: 0;
    background-color: #f6f7f7
}

.side-panel-table>tbody>tr>td {
    padding: 0;
    padding: 16px 0!important
}

.side-panel-table>tbody>tr>td>.icon {
    font-size: 22px;
    color: gray;
    cursor: pointer
}

.side-panel-table>tbody>tr>td>.icon.icon-livechat_II {
    font-size: 22px
}

.side-panel-table>tbody>tr>td>.icon:hover {
    color: #23aee1
}

.side-panel-table>tbody>tr>td>.icon.active {
    color: #23aee1
}

.side-panel-table>tbody>tr>td>.icon-bank_status {
    font-size: 22px
}

.side-panel-setting-notification-content-table:nth-child(n+2) {
    margin-top: 5px
}

.side-panel-content-wrapper {
    position: absolute;
    top: 0;
    z-index: 999;
    width: 300px;
    padding: 8px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
    overflow-y: auto
}

.floatingBankStatusContent.open {
    right: 0!important
}

.side-panel-setting-content-wrapper {
    background-color: #fff;
    display: none
}

.side-panel-setting-content-wrapper.open {
    display: block
}

.side-panel-setting-content-table {
    margin: 0
}

.side-panel-setting-content-table>tbody {
    border: 0
}

.side-panel-setting-content-table>tbody>tr>td {
    padding: 15px 35px!important
}

.side-panel-setting-content-table>tbody>tr>td>a>span {
    width: 45px!important;
    display: inline-block
}

.side-panel-setting-content-table>tbody>tr>td>a>span.icon {
    font-size: 16px;
    vertical-align: middle
}

.side-panel-setting-content-table>tbody>tr>td>a>span.icon-fun88_bank_deposit {
    font-size: 22px
}

.side-panel-setting-content-table>tbody>tr>td>a>span.icon-bank_status2 {
    font-size: 22px
}

.side-panel-setting-content-table>tbody>tr>td>a>span.icon-fun88_bank_withdrawal {
    font-size: 22px
}

.side-panel-setting-content-table>tbody>tr>td>a>span.icon-history {
    font-size: 22px
}

.side-panel-setting-content-table>tbody>tr>td>a>span.icon-fun88_bank_transfer {
    font-size: 18px
}

.side-panel-setting-content-table>tbody>tr>td>a>span.icon-account_summary {
    font-size: 20px!important
}

.side-panel-setting-content-table>tbody>tr>td>a>span.icon-update_profile {
    font-size: 22px!important
}

.side-panel-setting-content-table>tbody>tr>td>a>span.icon-password_setting,.side-panel-setting-content-table>tbody>tr>td>a>span.icon-fun88_bank,.side-panel-setting-content-table>tbody>tr>td>a>span.icon-fun88_nortify,.side-panel-setting-content-table>tbody>tr>td>a>span.icon-self-exclusion,.side-panel-setting-content-table>tbody>tr>td>a>span.icon-account_setting,.side-panel-setting-content-table>tbody>tr>td>a>span.icon-icon-banking,.side-panel-setting-content-table>tbody>tr>td>a>span.icon-download_cloud,.side-panel-setting-content-table>tbody>tr>td>a>span.icon-help_center,.side-panel-setting-content-table>tbody>tr>td>a>span.icon-profile_logout {
    font-size: 21px!important
}

.side-panel-setting-content-table>tbody>tr>td>a>span.icon-bet-record {
    font-size: 24px!important
}

.side-panel-setting-content-table>tbody>tr>td>a {
    color: #8e8e8e;
    font-size: 16px
}

.side-panel-setting-content-table>tbody>tr>td>a.active {
    color: #23aee1
}

.side-panel-setting-content-table>tbody>tr>td>a:hover {
    color: #23aee1
}

.side-panel-bank-status-setting>.side-panel-setting-content-table>tbody>tr>td {
    width: auto!important
}

@media only screen and (max-width: 1350px) {
    .side-panel-wrapper {
        padding-top:60px
    }
}

.Blueboth {
    font-size: 13px;
    color: #25aae2;
    font-weight: bold!important
}

.bank-status-message {
    display: none
}

.BankStatus {
    width: 100px
}

.BankStatus-Online {
    font-weight: bold;
    color: #fff;
    background-color: #53ca6e;
    border-radius: 16px 0 16px 0;
    padding: 3px
}

.BankStatus-Offline {
    font-weight: bold;
    color: #fff;
    background-color: red;
    border-radius: 16px 0 16px 0;
    padding: 3px
}

.bank-status-container.Offline {
    opacity: .7
}

.bank-status-container>td>.bank-status-icon {
    padding-right: 10px;
    font-size: 35px!important;
    vertical-align: middle
}

.bank-status-container.active {
    background-color: #00a7e1!important
}

.bankName {
    font-weight: bold
}

.bank-status-container.active>td>.bankName {
    color: #fff
}

.side-panel-bank-status-setting .bank-status-container>td {
    padding: 5px 5px 5px 20px!important
}

.brighttheme-notice {
    background-color: #fff
}

.ui-pnotify-text {
    width: 270px
}

.stack-bottomright {
    cursor: pointer
}

@media(min-width: 320px) and (max-width:500px) {
    .featured-font {
        font-size:12px!important
    }
}

@media(min-width: 320px) {
    .featured-box>.featured-icon {
        font-size:3em!important
    }
}

.featured-font {
    font-size: inherit
}

.titleWrapper {
    padding-bottom: 10px
}

.featured-box>.featured-icon {
    padding-top: 30px;
    font-size: 4em;
    display: inline-block;
    background-color: #d3d3d3;
    height: 100px;
    width: 100%
}

.featured-box:hover .featured-icon {
    opacity: 1;
    color: #00bfff;
    border: solid 1px;
    border-color: #00bfff;
    background-color: #fff;
    cursor: pointer
}

.featured-box {
    text-align: center;
    color: #0a0a0a
}

.featured-box:hover .featured-font {
    color: #00bfff;
    cursor: pointer
}

.home-page-featured-section {
    padding-top: 1em;
    padding-bottom: 8em
}

.featured-arrow {
    margin-left: 4px;
    font-size: inherit!important
}

.SEO_icons {
    height: 75px;
    width: 5.5em;
    margin-left: auto;
    margin-right: auto;
    display: block
}

.MirrorSiteLinkWrapper {
    display: table;
    margin: auto;
}

.MirrorSiteLink div {
    display: inline-block;
}

.MirrorSiteLink {
    line-height: 2;
    float: left;
}

.MirrorSiteLink div:not(:last-child)::after {
    content: "|";
    color: white;
    font-size: 12px;
}

.MirrorSiteLink div a {
    margin: 0 10px;
    color: white;
    text-decoration: none;
}

@media (max-width:1024px) {
    .MirrorSiteLink {
        float: none !important;
        text-align: center;
    }

    .MirrorSiteLink div:last-child::after {
        content: "";
    }
}

@media only screen and (max-width: 480px) {
    .MirrorSiteLink {
        font-size: 12px;
    }

    .MirrorSiteLink div a {
        margin: 0px 5px;
    }
}

@media only screen and (max-width: 320px) {
    .MirrorSiteLink {
        font-size: 10px;
    }
}


/* copy from mainSite for telegramModal*/
#modal-Telegram{
    margin:0;
    display:block;
    padding: 1rem;
}
.modal-Container {
    width: 100%!important;
    background-color: #202020;//
    background-color: rgba(32,32,32,.6);//
    position: fixed;//
    top: 0!important;//
    z-index: 9998;//
    max-height: 80%;
    overflow: scroll;
    overflow-x: hidden;
}
.modal-small-popup {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background-color: #fff;
    margin: 0 auto;
}

.fun-smallpopup-close {
    text-align: right;
    height: 0;
    -moz-transition: height 0.5s ease;
    -webkit-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: height 0.5s ease;
    overflow: hidden;
    padding: 0 10px;
    cursor: pointer;
}
/* END of 2024 telegram CSS*/

/* copy from mainSite for langModal */
.language-region-languageName {
    font-size: 14px;
    padding: 0 3px;
    color: #000;
    margin: 0
}

.language-wrapper {
    display: inline-block
}

.Modal-Language-Wrapper {
    background-color: #fff;
    width: 530px;
    margin: 0 auto;
    position: fixed;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-bottom: 25px
}

.Modal-Language-Wrapper>div {
    // width: 100%;
    height: 40px;
    background-color: #25aae1;
    padding: 0 20px
}

.Model-Language-Header {
    vertical-align: middle;
    line-height: 40px;
    color: #fff;
    font-weight: bold
}

.Modal-Language-Close-Button {
    // vertical-align: middle;
    // line-height: 40px!important;
    color: #fff;
    float: right;
    margin:8px 0 0;
    border: 1.5px solid #fff;
    border-radius: 50%;
    width:22px;
    height:22px;
    cursor: pointer;
    position: relative;
}
.Modal-Language-Close-Button::after,
.Modal-Language-Close-Button::before {
    content:'';
    display:block;
    height:1.5px;
    width:12px;
    background:#fff;
    position: absolute;
    top:50%;
    left:50%;
    margin-left: -6px;
    transform: rotate(45deg);
    transform-origin: center;
}
.Modal-Language-Close-Button::before {
    transform: rotate(135deg);
}

.Model-Language-Content-Wrapper {
    padding: 0;
    padding: 10px 0;
    background-color: #fff!important
}

.Model-Language-Content-Wrapper>div {
    padding: 5px 15px
}

.flag-icons {
    background-image:url(/flag-icons.png);
    display: inline-block;
    width: 36px;
    height: 25px;
    cursor: pointer
}

.flag-icons.flag-icons-CN {
    background-position: -3px -3px
}

.flag-icons.flag-icons-ID {
    background-position: -3px -33px
}

.flag-icons.flag-icons-JP {
    background-position: -3px -63px
}

.flag-icons.flag-icons-KR {
    background-position: -3px -93px
}

.flag-icons.flag-icons-TH {
    background-position: -3px -123px
}

.flag-icons.flag-icons-VN {
    background-position: -3px -153px
}
/* END of 2024 langModal */
