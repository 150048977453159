
@keyframes loadingRotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes loadingRotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.icon_loading {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin: 0 auto;
	background: url(/static/images/showInfo/icon_loading.png) no-repeat;
	animation-name: loadingRotate;
	animation-duration: 2000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
.icon_info {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin: 0 auto;
	background: url(/static/images/showInfo/icon_loading.png) no-repeat;
}
.icon_success {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin: 0 auto;
	background: url(/static/images/showInfo/icon_success.png) no-repeat;
}
.icon_fail {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin: 0 auto;
	background: url(/static/images/showInfo/icon_fail.png) no-repeat;
}

.icon_alert {
	display: inline-block;
	width: 34px;
	height: 34px;
	margin: 0 auto;
	background: url(/static/images/showInfo/icon_alert.png) no-repeat;
}

.am-toast {
	position: fixed;
	width: 100%;
	z-index: 1999;
	font-size: 14px;
	text-align: center;
}
.am-toast > span {
	max-width: 50%;
}
.am-toast.am-toast-mask {
	height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	left: 0;
	top: 0;
	-webkit-transform: translateZ(1px);
	transform: translateZ(1px);
	background-color: rgba(0, 0, 0, 0.4);
}
.am-toast.am-toast-nomask {
	position: fixed;
	max-width: 50%;
	width: auto;
	left: 50%;
	top: 50%;
	-webkit-transform: translateZ(1px);
	transform: translateZ(1px);
}
.am-toast.am-toast-nomask .am-toast-notice {
	-webkit-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}
.am-toast-notice-content .am-toast-text {
	min-width: 60px;
	border-radius: 3px;
	color: #222222;
	background-color: #fff;
	line-height: 1.5;
	padding: 9px 15px;
}
.am-toast-notice-content .am-toast-text.am-toast-text-icon {
	border-radius: 5px;
	padding: 15px 15px;
}
.am-toast-notice-content .am-toast-text.am-toast-text-icon .am-toast-text-info {
	margin-top: 6px;
}
