@font-face {
	font-family: 'Myriad' ;
	src: url(/font/MyriadPro-Regular.ttf);
}

*{
	margin: 0;
	padding: 0;
	font-family: 'Myriad'
}

// #allFooter {
// 	clear: both;
// 	font-size: 14px;
// 	font-weight: 400;
// 	line-height: 1.5;
// 	color: #0a0a0a;

// 	.footer-top-Wrapper {
// 		position: relative;
// 	}

// 	.footer-first-wrapper {
//     margin: 0 50px;
//     text-align: center;
//     padding-top: 4em;
//     padding-bottom: 1em;
// 	}

// }

@import url(./layout.less);
@import url(./footer.less);

@import url(./Toast/index.less);
// hayhar QRP page
@import url(./hayhar/index.less);

@import url(./hayhar/m3-005.less);

@import url(./hayhar/m3-007.less);

@import url(./hayhar/m3-008.less);

@import url(./hayhar/m3-009.less);

@import url(./hayhar/m3-010.less);

@import url(./hayhar/m3-011.less);

@import url(./hayhar/m3-012.less);

@import url(./restrict/index.less);


@hack: true;@import '/buildDir/data/Less/antd-custom.less';