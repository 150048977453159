.hayhar-page.m3-012 {
    .img_wrap {
        img{
            // height: 90vh;
        }
    }
    .img_wrap.mobile_warp{
        img{
            height: 100%;
        }
    }
    .text-top{
        color: #FFF;
    }
    .mobile .mobile-central{
        top: -16.8rem;
    }
    .footer{
        padding: 3rem;
        background-color: rgb(14,165,233,);
    }
}