/*
 * @Date: 2023-02-18 23:17:43
 * @LastEditors: simLarry
 * @LastEditTime: 2023-02-18 23:19:03
 * @FilePath: \F1-M3-QRP-CODE\data\Less\hayhar\m3-009.less
 */
.hayhar-page.m3-009{
    .central-web {
        .central-box{
            margin: 5% 55% 0 0%;
        }
    }
    .central-web{
        .text-top{
            color: #FFF;
        }
    }
    
}