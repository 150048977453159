// web

.hayhar-page.m3-008,.hayhar-page.m3-013{
    .text-top{
        display: none;
    }
    .mobile_warp{
        img{
            object-position:top;
        }
    }
    .central-web {
        
        .central-box{
            margin: 5% 35% 0 0%;
        }
    }
}