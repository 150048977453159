.restrict_page{
    display: block;
    position: relative;
    .restrict_bg{
        position: absolute;
        .restrict-mob{
            display: none ;
        }
        img{
            
            object-fit:cover;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .restrict_right{
        width: 43%;
        margin-left: auto;
        padding: 4rem;
        position: relative;
        .head{
           
            display: flex;
            align-items: center;
            font-size: 40px;
            margin-left: 0.5rem;
            padding-bottom: 2.5rem;
            span{
                --tw-space-x-reverse : 0;
                margin-left: calc(0.5rem*(1 - var(--tw-space-x-reverse)));
                color:rgb(37,170,225);
            }
        }
        .section{
            display: flex;
            align-items: flex-start;
            --tw-space-y-reverse: 0;
            margin-top: calc(1rem*(1 - var(--tw-space-y-reverse)));
            margin-bottom: calc(1rem*var(--tw-space-y-reverse));
            .space-y{
                --tw-space-x-reverse: 0;
                margin-right: calc(1rem*var(--tw-space-x-reverse));
                margin-left: calc(1rem*(1 - var(--tw-space-x-reverse)));
                h4{
                    --tw-text-opacity: 1;
                    color: rgba(31,41,55,var(--tw-text-opacity));
                    font-size: 1.125rem;
                    line-height: 1.75rem;
                    font-weight: inherit;
                }
                div{
                    line-height: 1.5rem;
                    --tw-space-y-reverse: 0;
                    margin-top: calc(0.5rem*(1 - var(--tw-space-y-reverse)));
                    margin-bottom: calc(0.5rem*var(--tw-space-y-reverse));
                    opacity: .8;
                    --tw-text-opacity: 1;
                    color: rgba(107,114,128,var(--tw-text-opacity));
                    text-align: justify;
                    font-size: 15px;
                    .cs{
                        font-weight: 600;
                        &:hover{
                            --tw-text-opacity: 1;
                            color: rgba(56,189,248,var(--tw-text-opacity));
                        }
                    }
                    .email{
                        --tw-text-opacity: 1;
                        color: rgba(14,165,233,var(--tw-text-opacity));
                        cursor: pointer;
                    }
                }
                .illustrate{
                    display: flex;
                    justify-content: flex-end;
                    .icon:nth-child(2){
                        --tw-space-x-reverse: 0;
                        margin-right: calc(0.5rem*(1 - var(--tw-space-x-reverse)));
                        margin-left: calc(0.5rem*(1 - var(--tw-space-x-reverse)));
                    }
                }
            }
        }
    }

    
    .restrict-web{
        display: block;
    }

    @media screen and (max-width:800px) {
        .restrict_bg{
            position: relative;
            .restrict-mob{
                display: block;
            }
            .restrict-web{
                display: none;
            }
        }
        
        .restrict_right{
            .head{
                justify-content:center;
                font-size: 1.5rem;
                line-height: 2rem;
                margin-left: 0;
                padding-bottom: 0;
                img{
                    width: 2rem;

                }
            }

            width: 100%;
            padding: 0;
            .section{
                flex-direction: column;
                padding: 0 2rem;
                .space-y{
                    margin: 0;
                    h4{
                        margin-left: 3.3rem;
                        margin-top: -1.58rem;
                    }
                    .illustrate{
                        justify-content: flex-start;
                    }
                }
            }
            
        }
    }
}