/* copy from mainSite for footer */
.reveal.full {
    top: 0!important;
    left: 0!important;
    margin-left: 0!important;
}
.reveal.without-overlay {
    position: fixed;
}

.reveal.full {
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100%;
    max-width: none;
    margin-left: 0;
    border: 0;
    border-radius: 0
}
@media screen and (max-width: 39.9375em) {
    .reveal {
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        height: 100vh;
        min-height: 100vh;
        max-width: none;
        margin-left: 0;
        border: 0;
        border-radius: 0
    }
}
.reveal.without-overlay {
    position: fixed
}

.reveal-overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    background-color: hsla(0,0%,4%,.45);
    overflow-y: scroll
}

.reveal {
    display: none;
    z-index: 5;
    padding: 1rem;
    border: 1px solid #cacaca;
    background-color: #fefefe;
    border-radius: 0;
    position: relative;
    top: 100px;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto
}

[data-whatinput=mouse] .reveal {
    outline: 0
}

@media screen and (min-width: 40em) {
    .reveal {
        min-height:0
    }
}

.reveal .column,.reveal .columns {
    min-width: 0
}

.reveal>:last-child {
    margin-bottom: 0
}

@media screen and (min-width: 40em) {
    .reveal {
        width:600px;
        max-width: 75rem
    }
}

@media screen and (min-width: 40em) {
    .reveal .reveal {
        left:auto;
        right: auto;
        margin: 0 auto
    }
}

.reveal.collapse {
    padding: 0
}

@media screen and (min-width: 40em) {
    .reveal.tiny {
        width:30%;
        max-width: 75rem
    }
}

@media screen and (min-width: 40em) {
    .reveal.small {
        width:50%;
        max-width: 75rem
    }
}

@media screen and (min-width: 40em) {
    .reveal.medium {
        width:60%;
        max-width: 75rem
    }
}

@media screen and (min-width: 40em) {
    .reveal.large {
        width:90%;
        max-width: 75rem
    }
}


.row {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto
}

.row:after,.row:before {
    content: ' ';
    display: table
}

.row:after {
    clear: both
}

.row.collapse>.column,.row.collapse>.columns {
    padding-left: 0;
    padding-right: 0
}

.row .row {
    margin-left: -.625rem;
    margin-right: -.625rem
}

@media screen and (min-width: 40em) {
    .row .row {
        margin-left:-.9375rem;
        margin-right: -.9375rem
    }
}

.row .row.collapse {
    margin-left: 0;
    margin-right: 0
}

.row.expanded {
    max-width: none
}

.row.expanded .row {
    margin-left: auto;
    margin-right: auto
}

.column,.columns {
    width: 100%;
    float: left;
    padding-left: .625rem;
    padding-right: .625rem
}

@media screen and (min-width: 40em) {
    .column,.columns {
        padding-left:.9375rem;
        padding-right: .9375rem
    }
}

.column:last-child:not(:first-child),.columns:last-child:not(:first-child) {
    float: right
}

.column.end:last-child:last-child,.end.columns:last-child:last-child {
    float: left
}

.column.row.row,.row.row.columns {
    float: none
}

.row .column.row.row,.row .row.row.columns {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0
}

.small-1 {
    width: 8.33333%
}

.small-push-1 {
    position: relative;
    left: 8.33333%
}

.small-pull-1 {
    position: relative;
    left: -8.33333%
}

.small-offset-0 {
    margin-left: 0
}

.small-2 {
    width: 16.66667%
}

.small-push-2 {
    position: relative;
    left: 16.66667%
}

.small-pull-2 {
    position: relative;
    left: -16.66667%
}

.small-offset-1 {
    margin-left: 8.33333%
}

.small-3 {
    width: 25%
}

.small-push-3 {
    position: relative;
    left: 25%
}

.small-pull-3 {
    position: relative;
    left: -25%
}

.small-offset-2 {
    margin-left: 16.66667%
}

.small-4 {
    width: 33.33333%
}

.small-push-4 {
    position: relative;
    left: 33.33333%
}

.small-pull-4 {
    position: relative;
    left: -33.33333%
}

.small-offset-3 {
    margin-left: 25%
}

.small-5 {
    width: 41.66667%
}

.small-push-5 {
    position: relative;
    left: 41.66667%
}

.small-pull-5 {
    position: relative;
    left: -41.66667%
}

.small-offset-4 {
    margin-left: 33.33333%
}

.small-6 {
    width: 50%
}

.small-push-6 {
    position: relative;
    left: 50%
}

.small-pull-6 {
    position: relative;
    left: -50%
}

.small-offset-5 {
    margin-left: 41.66667%
}

.small-7 {
    width: 58.33333%
}

.small-push-7 {
    position: relative;
    left: 58.33333%
}

.small-pull-7 {
    position: relative;
    left: -58.33333%
}

.small-offset-6 {
    margin-left: 50%
}

.small-8 {
    width: 66.66667%
}

.small-push-8 {
    position: relative;
    left: 66.66667%
}

.small-pull-8 {
    position: relative;
    left: -66.66667%
}

.small-offset-7 {
    margin-left: 58.33333%
}

.small-9 {
    width: 75%
}

.small-push-9 {
    position: relative;
    left: 75%
}

.small-pull-9 {
    position: relative;
    left: -75%
}

.small-offset-8 {
    margin-left: 66.66667%
}

.small-10 {
    width: 83.33333%
}

.small-push-10 {
    position: relative;
    left: 83.33333%
}

.small-pull-10 {
    position: relative;
    left: -83.33333%
}

.small-offset-9 {
    margin-left: 75%
}

.small-11 {
    width: 91.66667%
}

.small-push-11 {
    position: relative;
    left: 91.66667%
}

.small-pull-11 {
    position: relative;
    left: -91.66667%
}

.small-offset-10 {
    margin-left: 83.33333%
}

.small-12 {
    width: 100%
}

.small-offset-11 {
    margin-left: 91.66667%
}

.small-up-1>.column,.small-up-1>.columns {
    width: 100%;
    float: left
}

.small-up-1>.column:nth-of-type(1n),.small-up-1>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-1>.column:nth-of-type(1n+1),.small-up-1>.columns:nth-of-type(1n+1) {
    clear: both
}

.small-up-1>.column:last-child,.small-up-1>.columns:last-child {
    float: left
}

.small-up-2>.column,.small-up-2>.columns {
    width: 50%;
    float: left
}

.small-up-2>.column:nth-of-type(1n),.small-up-2>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-2>.column:nth-of-type(2n+1),.small-up-2>.columns:nth-of-type(2n+1) {
    clear: both
}

.small-up-2>.column:last-child,.small-up-2>.columns:last-child {
    float: left
}

.small-up-3>.column,.small-up-3>.columns {
    width: 33.33333%;
    float: left
}

.small-up-3>.column:nth-of-type(1n),.small-up-3>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-3>.column:nth-of-type(3n+1),.small-up-3>.columns:nth-of-type(3n+1) {
    clear: both
}

.small-up-3>.column:last-child,.small-up-3>.columns:last-child {
    float: left
}

.small-up-4>.column,.small-up-4>.columns {
    width: 25%;
    float: left
}

.small-up-4>.column:nth-of-type(1n),.small-up-4>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-4>.column:nth-of-type(4n+1),.small-up-4>.columns:nth-of-type(4n+1) {
    clear: both
}

.small-up-4>.column:last-child,.small-up-4>.columns:last-child {
    float: left
}

.small-up-5>.column,.small-up-5>.columns {
    width: 20%;
    float: left
}

.small-up-5>.column:nth-of-type(1n),.small-up-5>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-5>.column:nth-of-type(5n+1),.small-up-5>.columns:nth-of-type(5n+1) {
    clear: both
}

.small-up-5>.column:last-child,.small-up-5>.columns:last-child {
    float: left
}

.small-up-6>.column,.small-up-6>.columns {
    width: 16.66667%;
    float: left
}

.small-up-6>.column:nth-of-type(1n),.small-up-6>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-6>.column:nth-of-type(6n+1),.small-up-6>.columns:nth-of-type(6n+1) {
    clear: both
}

.small-up-6>.column:last-child,.small-up-6>.columns:last-child {
    float: left
}

.small-up-7>.column,.small-up-7>.columns {
    width: 14.28571%;
    float: left
}

.small-up-7>.column:nth-of-type(1n),.small-up-7>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-7>.column:nth-of-type(7n+1),.small-up-7>.columns:nth-of-type(7n+1) {
    clear: both
}

.small-up-7>.column:last-child,.small-up-7>.columns:last-child {
    float: left
}

.small-up-8>.column,.small-up-8>.columns {
    width: 12.5%;
    float: left
}

.small-up-8>.column:nth-of-type(1n),.small-up-8>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-8>.column:nth-of-type(8n+1),.small-up-8>.columns:nth-of-type(8n+1) {
    clear: both
}

.small-up-8>.column:last-child,.small-up-8>.columns:last-child {
    float: left
}

.small-collapse>.column,.small-collapse>.columns {
    padding-left: 0;
    padding-right: 0
}

.expanded.row .small-collapse.row,.small-collapse .row {
    margin-left: 0;
    margin-right: 0
}

.small-uncollapse>.column,.small-uncollapse>.columns {
    padding-left: .625rem;
    padding-right: .625rem
}

.small-centered {
    margin-left: auto;
    margin-right: auto
}

.small-centered,.small-centered:last-child:not(:first-child) {
    float: none;
    clear: both
}

.small-pull-0,.small-push-0,.small-uncentered {
    position: static;
    margin-left: 0;
    margin-right: 0;
    float: left
}

@media screen and (min-width: 40em) {
    .medium-1 {
        width:8.33333%
    }

    .medium-push-1 {
        position: relative;
        left: 8.33333%
    }

    .medium-pull-1 {
        position: relative;
        left: -8.33333%
    }

    .medium-offset-0 {
        margin-left: 0
    }

    .medium-2 {
        width: 16.66667%
    }

    .medium-push-2 {
        position: relative;
        left: 16.66667%
    }

    .medium-pull-2 {
        position: relative;
        left: -16.66667%
    }

    .medium-offset-1 {
        margin-left: 8.33333%
    }

    .medium-3 {
        width: 25%
    }

    .medium-push-3 {
        position: relative;
        left: 25%
    }

    .medium-pull-3 {
        position: relative;
        left: -25%
    }

    .medium-offset-2 {
        margin-left: 16.66667%
    }

    .medium-4 {
        width: 33.33333%
    }

    .medium-push-4 {
        position: relative;
        left: 33.33333%
    }

    .medium-pull-4 {
        position: relative;
        left: -33.33333%
    }

    .medium-offset-3 {
        margin-left: 25%
    }

    .medium-5 {
        width: 41.66667%
    }

    .medium-push-5 {
        position: relative;
        left: 41.66667%
    }

    .medium-pull-5 {
        position: relative;
        left: -41.66667%
    }

    .medium-offset-4 {
        margin-left: 33.33333%
    }

    .medium-6 {
        width: 50%
    }

    .medium-push-6 {
        position: relative;
        left: 50%
    }

    .medium-pull-6 {
        position: relative;
        left: -50%
    }

    .medium-offset-5 {
        margin-left: 41.66667%
    }

    .medium-7 {
        width: 58.33333%
    }

    .medium-push-7 {
        position: relative;
        left: 58.33333%
    }

    .medium-pull-7 {
        position: relative;
        left: -58.33333%
    }

    .medium-offset-6 {
        margin-left: 50%
    }

    .medium-8 {
        width: 66.66667%
    }

    .medium-push-8 {
        position: relative;
        left: 66.66667%
    }

    .medium-pull-8 {
        position: relative;
        left: -66.66667%
    }

    .medium-offset-7 {
        margin-left: 58.33333%
    }

    .medium-9 {
        width: 75%
    }

    .medium-push-9 {
        position: relative;
        left: 75%
    }

    .medium-pull-9 {
        position: relative;
        left: -75%
    }

    .medium-offset-8 {
        margin-left: 66.66667%
    }

    .medium-10 {
        width: 83.33333%
    }

    .medium-push-10 {
        position: relative;
        left: 83.33333%
    }

    .medium-pull-10 {
        position: relative;
        left: -83.33333%
    }

    .medium-offset-9 {
        margin-left: 75%
    }

    .medium-11 {
        width: 91.66667%
    }

    .medium-push-11 {
        position: relative;
        left: 91.66667%
    }

    .medium-pull-11 {
        position: relative;
        left: -91.66667%
    }

    .medium-offset-10 {
        margin-left: 83.33333%
    }

    .medium-12 {
        width: 100%
    }

    .medium-offset-11 {
        margin-left: 91.66667%
    }

    .medium-up-1>.column,.medium-up-1>.columns {
        width: 100%;
        float: left
    }

    .medium-up-1>.column:nth-of-type(1n),.medium-up-1>.columns:nth-of-type(1n) {
        clear: none
    }

    .medium-up-1>.column:nth-of-type(1n+1),.medium-up-1>.columns:nth-of-type(1n+1) {
        clear: both
    }

    .medium-up-1>.column:last-child,.medium-up-1>.columns:last-child {
        float: left
    }

    .medium-up-2>.column,.medium-up-2>.columns {
        width: 50%;
        float: left
    }

    .medium-up-2>.column:nth-of-type(1n),.medium-up-2>.columns:nth-of-type(1n) {
        clear: none
    }

    .medium-up-2>.column:nth-of-type(2n+1),.medium-up-2>.columns:nth-of-type(2n+1) {
        clear: both
    }

    .medium-up-2>.column:last-child,.medium-up-2>.columns:last-child {
        float: left
    }

    .medium-up-3>.column,.medium-up-3>.columns {
        width: 33.33333%;
        float: left
    }

    .medium-up-3>.column:nth-of-type(1n),.medium-up-3>.columns:nth-of-type(1n) {
        clear: none
    }

    .medium-up-3>.column:nth-of-type(3n+1),.medium-up-3>.columns:nth-of-type(3n+1) {
        clear: both
    }

    .medium-up-3>.column:last-child,.medium-up-3>.columns:last-child {
        float: left
    }

    .medium-up-4>.column,.medium-up-4>.columns {
        width: 25%;
        float: left
    }

    .medium-up-4>.column:nth-of-type(1n),.medium-up-4>.columns:nth-of-type(1n) {
        clear: none
    }

    .medium-up-4>.column:nth-of-type(4n+1),.medium-up-4>.columns:nth-of-type(4n+1) {
        clear: both
    }

    .medium-up-4>.column:last-child,.medium-up-4>.columns:last-child {
        float: left
    }

    .medium-up-5>.column,.medium-up-5>.columns {
        width: 20%;
        float: left
    }

    .medium-up-5>.column:nth-of-type(1n),.medium-up-5>.columns:nth-of-type(1n) {
        clear: none
    }

    .medium-up-5>.column:nth-of-type(5n+1),.medium-up-5>.columns:nth-of-type(5n+1) {
        clear: both
    }

    .medium-up-5>.column:last-child,.medium-up-5>.columns:last-child {
        float: left
    }

    .medium-up-6>.column,.medium-up-6>.columns {
        width: 16.66667%;
        float: left
    }

    .medium-up-6>.column:nth-of-type(1n),.medium-up-6>.columns:nth-of-type(1n) {
        clear: none
    }

    .medium-up-6>.column:nth-of-type(6n+1),.medium-up-6>.columns:nth-of-type(6n+1) {
        clear: both
    }

    .medium-up-6>.column:last-child,.medium-up-6>.columns:last-child {
        float: left
    }

    .medium-up-7>.column,.medium-up-7>.columns {
        width: 14.28571%;
        float: left
    }

    .medium-up-7>.column:nth-of-type(1n),.medium-up-7>.columns:nth-of-type(1n) {
        clear: none
    }

    .medium-up-7>.column:nth-of-type(7n+1),.medium-up-7>.columns:nth-of-type(7n+1) {
        clear: both
    }

    .medium-up-7>.column:last-child,.medium-up-7>.columns:last-child {
        float: left
    }

    .medium-up-8>.column,.medium-up-8>.columns {
        width: 12.5%;
        float: left
    }

    .medium-up-8>.column:nth-of-type(1n),.medium-up-8>.columns:nth-of-type(1n) {
        clear: none
    }

    .medium-up-8>.column:nth-of-type(8n+1),.medium-up-8>.columns:nth-of-type(8n+1) {
        clear: both
    }

    .medium-up-8>.column:last-child,.medium-up-8>.columns:last-child {
        float: left
    }

    .medium-collapse>.column,.medium-collapse>.columns {
        padding-left: 0;
        padding-right: 0
    }

    .expanded.row .medium-collapse.row,.medium-collapse .row {
        margin-left: 0;
        margin-right: 0
    }

    .medium-uncollapse>.column,.medium-uncollapse>.columns {
        padding-left: .9375rem;
        padding-right: .9375rem
    }

    .medium-centered {
        margin-left: auto;
        margin-right: auto
    }

    .medium-centered,.medium-centered:last-child:not(:first-child) {
        float: none;
        clear: both
    }

    .medium-pull-0,.medium-push-0,.medium-uncentered {
        position: static;
        margin-left: 0;
        margin-right: 0;
        float: left
    }
}

@media screen and (min-width: 64em) {
    .large-1 {
        width:8.33333%
    }

    .large-push-1 {
        position: relative;
        left: 8.33333%
    }

    .large-pull-1 {
        position: relative;
        left: -8.33333%
    }

    .large-offset-0 {
        margin-left: 0
    }

    .large-2 {
        width: 16.66667%
    }

    .large-push-2 {
        position: relative;
        left: 16.66667%
    }

    .large-pull-2 {
        position: relative;
        left: -16.66667%
    }

    .large-offset-1 {
        margin-left: 8.33333%
    }

    .large-3 {
        width: 25%
    }

    .large-push-3 {
        position: relative;
        left: 25%
    }

    .large-pull-3 {
        position: relative;
        left: -25%
    }

    .large-offset-2 {
        margin-left: 16.66667%
    }

    .large-4 {
        width: 33.33333%
    }

    .large-push-4 {
        position: relative;
        left: 33.33333%
    }

    .large-pull-4 {
        position: relative;
        left: -33.33333%
    }

    .large-offset-3 {
        margin-left: 25%
    }

    .large-5 {
        width: 41.66667%
    }

    .large-push-5 {
        position: relative;
        left: 41.66667%
    }

    .large-pull-5 {
        position: relative;
        left: -41.66667%
    }

    .large-offset-4 {
        margin-left: 33.33333%
    }

    .large-6 {
        width: 50%
    }

    .large-push-6 {
        position: relative;
        left: 50%
    }

    .large-pull-6 {
        position: relative;
        left: -50%
    }

    .large-offset-5 {
        margin-left: 41.66667%
    }

    .large-7 {
        width: 58.33333%
    }

    .large-push-7 {
        position: relative;
        left: 58.33333%
    }

    .large-pull-7 {
        position: relative;
        left: -58.33333%
    }

    .large-offset-6 {
        margin-left: 50%
    }

    .large-8 {
        width: 66.66667%
    }

    .large-push-8 {
        position: relative;
        left: 66.66667%
    }

    .large-pull-8 {
        position: relative;
        left: -66.66667%
    }

    .large-offset-7 {
        margin-left: 58.33333%
    }

    .large-9 {
        width: 75%
    }

    .large-push-9 {
        position: relative;
        left: 75%
    }

    .large-pull-9 {
        position: relative;
        left: -75%
    }

    .large-offset-8 {
        margin-left: 66.66667%
    }

    .large-10 {
        width: 83.33333%
    }

    .large-push-10 {
        position: relative;
        left: 83.33333%
    }

    .large-pull-10 {
        position: relative;
        left: -83.33333%
    }

    .large-offset-9 {
        margin-left: 75%
    }

    .large-11 {
        width: 91.66667%
    }

    .large-push-11 {
        position: relative;
        left: 91.66667%
    }

    .large-pull-11 {
        position: relative;
        left: -91.66667%
    }

    .large-offset-10 {
        margin-left: 83.33333%
    }

    .large-12 {
        width: 100%
    }

    .large-offset-11 {
        margin-left: 91.66667%
    }

    .large-up-1>.column,.large-up-1>.columns {
        width: 100%;
        float: left
    }

    .large-up-1>.column:nth-of-type(1n),.large-up-1>.columns:nth-of-type(1n) {
        clear: none
    }

    .large-up-1>.column:nth-of-type(1n+1),.large-up-1>.columns:nth-of-type(1n+1) {
        clear: both
    }

    .large-up-1>.column:last-child,.large-up-1>.columns:last-child {
        float: left
    }

    .large-up-2>.column,.large-up-2>.columns {
        width: 50%;
        float: left
    }

    .large-up-2>.column:nth-of-type(1n),.large-up-2>.columns:nth-of-type(1n) {
        clear: none
    }

    .large-up-2>.column:nth-of-type(2n+1),.large-up-2>.columns:nth-of-type(2n+1) {
        clear: both
    }

    .large-up-2>.column:last-child,.large-up-2>.columns:last-child {
        float: left
    }

    .large-up-3>.column,.large-up-3>.columns {
        width: 33.33333%;
        float: left
    }

    .large-up-3>.column:nth-of-type(1n),.large-up-3>.columns:nth-of-type(1n) {
        clear: none
    }

    .large-up-3>.column:nth-of-type(3n+1),.large-up-3>.columns:nth-of-type(3n+1) {
        clear: both
    }

    .large-up-3>.column:last-child,.large-up-3>.columns:last-child {
        float: left
    }

    .large-up-4>.column,.large-up-4>.columns {
        width: 25%;
        float: left
    }

    .large-up-4>.column:nth-of-type(1n),.large-up-4>.columns:nth-of-type(1n) {
        clear: none
    }

    .large-up-4>.column:nth-of-type(4n+1),.large-up-4>.columns:nth-of-type(4n+1) {
        clear: both
    }

    .large-up-4>.column:last-child,.large-up-4>.columns:last-child {
        float: left
    }

    .large-up-5>.column,.large-up-5>.columns {
        width: 20%;
        float: left
    }

    .large-up-5>.column:nth-of-type(1n),.large-up-5>.columns:nth-of-type(1n) {
        clear: none
    }

    .large-up-5>.column:nth-of-type(5n+1),.large-up-5>.columns:nth-of-type(5n+1) {
        clear: both
    }

    .large-up-5>.column:last-child,.large-up-5>.columns:last-child {
        float: left
    }

    .large-up-6>.column,.large-up-6>.columns {
        width: 16.66667%;
        float: left
    }

    .large-up-6>.column:nth-of-type(1n),.large-up-6>.columns:nth-of-type(1n) {
        clear: none
    }

    .large-up-6>.column:nth-of-type(6n+1),.large-up-6>.columns:nth-of-type(6n+1) {
        clear: both
    }

    .large-up-6>.column:last-child,.large-up-6>.columns:last-child {
        float: left
    }

    .large-up-7>.column,.large-up-7>.columns {
        width: 14.28571%;
        float: left
    }

    .large-up-7>.column:nth-of-type(1n),.large-up-7>.columns:nth-of-type(1n) {
        clear: none
    }

    .large-up-7>.column:nth-of-type(7n+1),.large-up-7>.columns:nth-of-type(7n+1) {
        clear: both
    }

    .large-up-7>.column:last-child,.large-up-7>.columns:last-child {
        float: left
    }

    .large-up-8>.column,.large-up-8>.columns {
        width: 12.5%;
        float: left
    }

    .large-up-8>.column:nth-of-type(1n),.large-up-8>.columns:nth-of-type(1n) {
        clear: none
    }

    .large-up-8>.column:nth-of-type(8n+1),.large-up-8>.columns:nth-of-type(8n+1) {
        clear: both
    }

    .large-up-8>.column:last-child,.large-up-8>.columns:last-child {
        float: left
    }

    .large-collapse>.column,.large-collapse>.columns {
        padding-left: 0;
        padding-right: 0
    }

    .expanded.row .large-collapse.row,.large-collapse .row {
        margin-left: 0;
        margin-right: 0
    }

    .large-uncollapse>.column,.large-uncollapse>.columns {
        padding-left: .9375rem;
        padding-right: .9375rem
    }

    .large-centered {
        margin-left: auto;
        margin-right: auto
    }

    .large-centered,.large-centered:last-child:not(:first-child) {
        float: none;
        clear: both
    }

    .large-pull-0,.large-push-0,.large-uncentered {
        position: static;
        margin-left: 0;
        margin-right: 0;
        float: left
    }
}


#allFooter {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    margin: 0;

    font-size: 14px;
    box-sizing: border-box;

article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary {
    display: block
}

audio,canvas,progress,video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden],template {
    display: none
}

a {
    background-color: transparent
}

a:active,a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,strong {
    font-weight: 700
}

dfn {
    font-style: italic
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 1em 40px
}

hr {
    box-sizing: content-box;
    height: 0
}

pre {
    overflow: auto
}

code,kbd,pre,samp {
    font-family: monospace;
    font-size: 1em
}

button,input,optgroup,select,textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button,select {
    text-transform: none
}

button,html input[type=button],input[type=reset],input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],html input[disabled] {
    cursor: not-allowed
}

button::-moz-focus-inner,input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input {
    line-height: normal
}

input[type=checkbox],input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
    height: auto
}

input[type=search] {
    -webkit-appearance: textfield;
    box-sizing: content-box
}

input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

legend {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto
}

optgroup {
    font-weight: 700
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td,th {
    padding: 0
}

.foundation-mq {
    font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"
}

*,:after,:before {
    box-sizing: inherit
}

/*
img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
    display: inline-block;
    vertical-align: middle
} */

textarea {
    height: auto;
    min-height: 50px;
    border-radius: 0
}

select {
    width: 100%;
    border-radius: 0
}

#map_canvas embed,#map_canvas img,#map_canvas object,.map_canvas embed,.map_canvas img,.map_canvas object,.mqa-display embed,.mqa-display img,.mqa-display object {
    max-width: none!important
}

button {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    padding: 0;
    border: 0;
    border-radius: 0;
    line-height: 1
}

[data-whatinput=mouse] button {
    outline: 0
}

.is-visible {
    display: block!important
}

.is-hidden {
    display: none!important
}

blockquote,dd,dl,dt,form,h1,h2,h3,h4,h5,h6,li,ol,p,pre,td,th,ul {
    margin: 0;
    padding: 0
}

p {
    font-size: inherit;
    line-height: 1.6;
    margin-bottom: 1rem;
    text-rendering: optimizeLegibility
}

em,i {
    font-style: italic
}

b,em,i,strong {
    line-height: inherit
}

b,strong {
    font-weight: 700
}

small {
    font-size: 80%;
    line-height: inherit
}

h1,h2,h3,h4,h5,h6 {
    font-family: Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
    font-weight: 400;
    font-style: normal;
    color: inherit;
    text-rendering: optimizeLegibility;
    margin-top: 0;
    margin-bottom: .5rem;
    line-height: 1.4
}

h1 small,h2 small,h3 small,h4 small,h5 small,h6 small {
    color: #cacaca;
    line-height: 0
}

h1 {
    font-size: 1.5rem
}

h2 {
    font-size: 1.25rem
}

h3 {
    font-size: 1.1875rem
}

h4 {
    font-size: 1.125rem
}

h5 {
    font-size: 1.0625rem
}

h6 {
    font-size: 1rem
}

@media screen and (min-width: 40em) {
    h1 {
        font-size:3rem
    }

    h2 {
        font-size: 2.5rem
    }

    h3 {
        font-size: 1.9375rem
    }

    h4 {
        font-size: 1.5625rem
    }

    h5 {
        font-size: 1.25rem
    }

    h6 {
        font-size: 1rem
    }
}

a {
    text-decoration: none;
}
/*
a {
    color: #2199e8;
    text-decoration: none;
    line-height: inherit;
    cursor: pointer
}

a:focus,a:hover {
    color: #1585cf
}
*/
a img {
    border: 0
}

hr {
    max-width: 75rem;
    height: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid #cacaca;
    border-left: 0;
    margin: 1.25rem auto;
    clear: both
}
/*
dl,ol,ul {
    line-height: 1.6;
    margin-bottom: 1rem;
    list-style-position: outside
}

li {
    font-size: inherit
}

ul {
    list-style-type: disc
}

ol,ul {
    margin-left: 1.25rem
}

ol ol,ol ul,ul ol,ul ul {
    margin-left: 1.25rem;
    margin-bottom: 0
}

dl {
    margin-bottom: 1rem
}

dl dt {
    margin-bottom: .3rem;
    font-weight: 700
}
*/
blockquote {
    margin: 0 0 1rem;
    padding: .5625rem 1.25rem 0 1.1875rem;
    border-left: 1px solid #cacaca
}

blockquote,blockquote p {
    line-height: 1.6;
    color: #8a8a8a
}

cite {
    display: block;
    font-size: .8125rem;
    color: #8a8a8a
}

cite:before {
    content: '—\20'
}

abbr {
    color: #0a0a0a;
    cursor: help;
    border-bottom: 1px dotted #0a0a0a
}

code {
    font-weight: 400;
    border: 1px solid #cacaca;
    padding: .125rem .3125rem .0625rem
}

code,kbd {
    font-family: Consolas,Liberation Mono,Courier,monospace;
    color: #0a0a0a;
    background-color: #e6e6e6
}

kbd {
    padding: .125rem .25rem 0;
    margin: 0
}

.subheader {
    margin-top: .2rem;
    margin-bottom: .5rem;
    font-weight: 400;
    line-height: 1.4;
    color: #8a8a8a
}

.lead {
    font-size: 125%;
    line-height: 1.6
}

.stat {
    font-size: 2.5rem;
    line-height: 1
}

p+.stat {
    margin-top: -1rem
}

.no-bullet {
    margin-left: 0;
    list-style: none
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.text-justify {
    text-align: justify
}

@media screen and (min-width: 40em) {
    .medium-text-left {
        text-align:left
    }

    .medium-text-right {
        text-align: right
    }

    .medium-text-center {
        text-align: center
    }

    .medium-text-justify {
        text-align: justify
    }
}

@media screen and (min-width: 64em) {
    .large-text-left {
        text-align:left
    }

    .large-text-right {
        text-align: right
    }

    .large-text-center {
        text-align: center
    }

    .large-text-justify {
        text-align: justify
    }
}

.show-for-print {
    display: none!important
}

@media print {
    * {
        background: transparent!important;
        color: #000!important;
        box-shadow: none!important;
        text-shadow: none!important
    }

    .show-for-print {
        display: block!important
    }

    .hide-for-print {
        display: none!important
    }

    table.show-for-print {
        display: table!important
    }

    thead.show-for-print {
        display: table-header-group!important
    }

    tbody.show-for-print {
        display: table-row-group!important
    }

    tr.show-for-print {
        display: table-row!important
    }

    td.show-for-print,th.show-for-print {
        display: table-cell!important
    }

    a,a:visited {
        text-decoration: underline
    }

    a[href]:after {
        content: " (" attr(href) ")"
    }

    .ir a:after,a[href^='#']:after,a[href^='javascript:']:after {
        content: ''
    }

    abbr[title]:after {
        content: " (" attr(title) ")"
    }

    blockquote,pre {
        border: 1px solid #8a8a8a;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    img,tr {
        page-break-inside: avoid
    }

    img {
        max-width: 100%!important
    }

    @page {
        margin: .5cm
    }

    h2,h3,p {
        orphans: 3;
        widows: 3
    }

    h2,h3 {
        page-break-after: avoid
    }
}

.button {
    display: inline-block;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: none;
    transition: background-color .25s ease-out,color .25s ease-out;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 0;
    padding: .85em 1em;
    margin: 0 0 1rem;
    font-size: .9rem;
    background-color: #2199e8;
    color: #fefefe
}

[data-whatinput=mouse] .button {
    outline: 0
}

.button:focus,.button:hover {
    background-color: #1583cc;
    color: #fefefe
}

.button.tiny {
    font-size: .6rem
}

.button.small {
    font-size: .75rem
}

.button.large {
    font-size: 1.25rem
}

.button.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0
}

.button.primary {
    background-color: #2199e8;
    color: #fefefe
}

.button.primary:focus,.button.primary:hover {
    background-color: #147cc0;
    color: #fefefe
}

.button.secondary {
    background-color: #777;
    color: #fefefe
}

.button.secondary:focus,.button.secondary:hover {
    background-color: #5f5f5f;
    color: #fefefe
}

.button.success {
    background-color: #3adb76;
    color: #fefefe
}

.button.success:focus,.button.success:hover {
    background-color: #22bb5b;
    color: #fefefe
}

.button.warning {
    background-color: #ffae00;
    color: #fefefe
}

.button.warning:focus,.button.warning:hover {
    background-color: #cc8b00;
    color: #fefefe
}

.button.alert {
    background-color: #ec5840;
    color: #fefefe
}

.button.alert:focus,.button.alert:hover {
    background-color: #da3116;
    color: #fefefe
}

.button.hollow {
    border: 1px solid #2199e8;
    color: #2199e8
}

.button.hollow,.button.hollow:focus,.button.hollow:hover {
    background-color: transparent
}

.button.hollow:focus,.button.hollow:hover {
    border-color: #0c4d78;
    color: #0c4d78
}

.button.hollow.primary {
    border: 1px solid #2199e8;
    color: #2199e8
}

.button.hollow.primary:focus,.button.hollow.primary:hover {
    border-color: #0c4d78;
    color: #0c4d78
}

.button.hollow.secondary {
    border: 1px solid #777;
    color: #777
}

.button.hollow.secondary:focus,.button.hollow.secondary:hover {
    border-color: #3c3c3c;
    color: #3c3c3c
}

.button.hollow.success {
    border: 1px solid #3adb76;
    color: #3adb76
}

.button.hollow.success:focus,.button.hollow.success:hover {
    border-color: #157539;
    color: #157539
}

.button.hollow.warning {
    border: 1px solid #ffae00;
    color: #ffae00
}

.button.hollow.warning:focus,.button.hollow.warning:hover {
    border-color: #805700;
    color: #805700
}

.button.hollow.alert {
    border: 1px solid #ec5840;
    color: #ec5840
}

.button.hollow.alert:focus,.button.hollow.alert:hover {
    border-color: #881f0e;
    color: #881f0e
}

.button.disabled,.button[disabled] {
    opacity: .25;
    cursor: not-allowed
}

.button.disabled:focus,.button.disabled:hover,.button[disabled]:focus,.button[disabled]:hover {
    background-color: #2199e8;
    color: #fefefe
}

.button.disabled.primary,.button[disabled].primary {
    opacity: .25;
    cursor: not-allowed
}

.button.disabled.primary:focus,.button.disabled.primary:hover,.button[disabled].primary:focus,.button[disabled].primary:hover {
    background-color: #2199e8;
    color: #fefefe
}

.button.disabled.secondary,.button[disabled].secondary {
    opacity: .25;
    cursor: not-allowed
}

.button.disabled.secondary:focus,.button.disabled.secondary:hover,.button[disabled].secondary:focus,.button[disabled].secondary:hover {
    background-color: #777;
    color: #fefefe
}

.button.disabled.success,.button[disabled].success {
    opacity: .25;
    cursor: not-allowed
}

.button.disabled.success:focus,.button.disabled.success:hover,.button[disabled].success:focus,.button[disabled].success:hover {
    background-color: #3adb76;
    color: #fefefe
}

.button.disabled.warning,.button[disabled].warning {
    opacity: .25;
    cursor: not-allowed
}

.button.disabled.warning:focus,.button.disabled.warning:hover,.button[disabled].warning:focus,.button[disabled].warning:hover {
    background-color: #ffae00;
    color: #fefefe
}

.button.disabled.alert,.button[disabled].alert {
    opacity: .25;
    cursor: not-allowed
}

.button.disabled.alert:focus,.button.disabled.alert:hover,.button[disabled].alert:focus,.button[disabled].alert:hover {
    background-color: #ec5840;
    color: #fefefe
}

.button.dropdown:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: .4em inset;
    border-color: #fefefe transparent transparent;
    border-top-style: solid;
    border-bottom-width: 0;
    position: relative;
    top: .4em;
    float: right;
    margin-left: 1em;
    display: inline-block
}

.button.arrow-only:after {
    margin-left: 0;
    float: none;
    top: -.1em
}

[type=color],[type=date],[type=datetime-local],[type=datetime],[type=email],[type=month],[type=number],[type=password],[type=search],[type=tel],:not([class^=biab])[type=text],[type=time],[type=url],[type=week],:not([class^=biab])[type=text],textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    padding: .5rem;
    border: 1px solid #cacaca;
    margin: 0 0 1rem;
    font-family: inherit;
    font-size: 1rem;
    color: #0a0a0a;
    background-color: #fefefe;
    box-shadow: inset 0 1px 2px hsla(0,0%,4%,.1);
    border-radius: 0;
    transition: box-shadow .5s,border-color .25s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none
}

[type=color]:focus,[type=date]:focus,[type=datetime-local]:focus,[type=datetime]:focus,[type=email]:focus,[type=month]:focus,[type=number]:focus,[type=password]:focus,[type=search]:focus,[type=tel]:focus,:not([class^=biab])[type=text]:focus,[type=time]:focus,[type=url]:focus,[type=week]:focus,textarea:focus {
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    outline: none;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow .5s,border-color .25s ease-in-out
}

textarea {
    max-width: 100%
}

textarea[rows] {
    height: auto
}

input::-webkit-input-placeholder,textarea::-webkit-input-placeholder {
    color: #cacaca
}

input::-moz-placeholder,textarea::-moz-placeholder {
    color: #cacaca
}

input:-ms-input-placeholder,textarea:-ms-input-placeholder {
    color: #cacaca
}

input::placeholder,textarea::placeholder {
    color: #cacaca
}

input:disabled,input[readonly],textarea:disabled,textarea[readonly] {
    background-color: #e6e6e6;
    cursor: not-allowed
}

[type=button],[type=submit] {
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none
}

input[type=search] {
    box-sizing: border-box
}

[type=checkbox],[type=file],[type=radio] {
    margin: 0 0 1rem
}

[type=checkbox]+label,[type=radio]+label {
    display: inline-block;
    margin-left: .5rem;
    margin-right: 1rem;
    margin-bottom: 0;
    vertical-align: baseline
}

[type=checkbox]+label[for],[type=radio]+label[for] {
    cursor: pointer
}

label>[type=checkbox],label>[type=radio] {
    margin-right: .5rem
}

[type=file] {
    width: 100%
}

label {
    display: block;
    margin: 0;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.8;
    color: #0a0a0a
}

label.middle {
    margin: 0 0 1rem;
    padding: .5625rem 0
}

.help-text {
    margin-top: -.5rem;
    font-size: .8125rem;
    font-style: italic;
    color: #0a0a0a
}

.input-group {
    display: table;
    width: 100%;
    margin-bottom: 1rem
}

.input-group>:first-child,.input-group>:last-child>* {
    border-radius: 0 0 0 0
}

.input-group-button,.input-group-field,.input-group-label {
    margin: 0;
    white-space: nowrap;
    display: table-cell;
    vertical-align: middle
}

.input-group-label {
    text-align: center;
    padding: 0 1rem;
    background: #e6e6e6;
    color: #0a0a0a;
    border: 1px solid #cacaca;
    white-space: nowrap;
    width: 1%;
    height: 100%
}

.input-group-label:first-child {
    border-right: 0
}

.input-group-label:last-child {
    border-left: 0
}

.input-group-field {
    border-radius: 0;
    height: 2.5rem
}

.input-group-button {
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    height: 100%;
    width: 1%
}

.input-group-button a,.input-group-button button,.input-group-button input {
    margin: 0
}

.input-group .input-group-button {
    display: table-cell
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0
}

legend {
    margin-bottom: .5rem;
    max-width: 100%
}

.fieldset {
    border: 1px solid #cacaca;
    padding: 1.25rem;
    margin: 1.125rem 0
}

.fieldset legend {
    background: #fefefe;
    padding: 0 .1875rem;
    margin: 0;
    margin-left: -.1875rem
}

select {
    height: 2.4375rem;
    padding: .5rem;
    border: 1px solid #cacaca;
    margin: 0 0 1rem;
    font-size: 1rem;
    font-family: inherit;
    line-height: normal;
    color: #0a0a0a;
    background-color: #fefefe;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
    background-size: 9px 6px;
    background-position: right -1rem center;
    background-origin: content-box;
    background-repeat: no-repeat;
    padding-right: 1.5rem
}

@media screen and (min-width: 0\0) {
    select {
        background-image:url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==")
    }
}

select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed
}

select::-ms-expand {
    display: none
}

select[multiple] {
    height: auto;
    background-image: none
}

.is-invalid-input:not(:focus) {
    background-color: rgba(236,88,64,.1);
    border-color: #ec5840
}

.form-error,.is-invalid-label {
    color: #ec5840
}

.form-error {
    display: none;
    margin-top: -.5rem;
    margin-bottom: 1rem;
    font-size: .75rem;
    font-weight: 700
}

.form-error.is-visible {
    display: block
}

.accordion {
    list-style-type: none;
    background: #fefefe;
    margin-left: 0
}

.accordion-item:first-child>:first-child,.accordion-item:last-child>:last-child {
    border-radius: 0 0 0 0
}

.accordion-title {
    display: block;
    padding: 1.25rem 1rem;
    line-height: 1;
    font-size: .75rem;
    color: #2199e8;
    position: relative;
    border: 1px solid #e6e6e6;
    border-bottom: 0
}

:last-child:not(.is-active)>.accordion-title {
    border-radius: 0 0 0 0;
    border-bottom: 1px solid #e6e6e6
}

.accordion-title:focus,.accordion-title:hover {
    background-color: #e6e6e6
}

.accordion-title:before {
    content: '+';
    position: absolute;
    right: 1rem;
    top: 50%;
    margin-top: -.5rem
}

.is-active>.accordion-title:before {
    content: '–'
}

.accordion-content {
    padding: 1rem;
    display: none;
    border: 1px solid #e6e6e6;
    border-bottom: 0;
    background-color: #fefefe;
    color: #0a0a0a
}

:last-child>.accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6
}

.is-accordion-submenu-parent>a {
    position: relative
}

.is-accordion-submenu-parent>a:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 6px inset;
    border-color: #2199e8 transparent transparent;
    border-top-style: solid;
    border-bottom-width: 0;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    right: 1rem
}

.is-accordion-submenu-parent[aria-expanded=true]>a:after {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1)
}

.badge {
    display: inline-block;
    padding: .3em;
    min-width: 2.1em;
    font-size: .6rem;
    text-align: center;
    border-radius: 50%;
    background: #2199e8;
    color: #fefefe
}

.badge.secondary {
    background: #777;
    color: #fefefe
}

.badge.success {
    background: #3adb76;
    color: #fefefe
}

.badge.warning {
    background: #ffae00;
    color: #fefefe
}

.badge.alert {
    background: #ec5840;
    color: #fefefe
}

.breadcrumbs {
    list-style: none;
    margin: 0 0 1rem
}

.breadcrumbs:after,.breadcrumbs:before {
    content: ' ';
    display: table
}

.breadcrumbs:after {
    clear: both
}

.breadcrumbs li {
    float: left;
    color: #0a0a0a;
    font-size: .6875rem;
    cursor: default;
    text-transform: uppercase
}

.breadcrumbs li:not(:last-child):after {
    color: #cacaca;
    content: "/";
    margin: 0 .75rem;
    position: relative;
    top: 1px;
    opacity: 1
}

.breadcrumbs a {
    color: #2199e8
}

.breadcrumbs a:hover {
    text-decoration: underline
}

.breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed
}

.button-group {
    margin-bottom: 1rem;
    font-size: 0
}

.button-group:after,.button-group:before {
    content: ' ';
    display: table
}

.button-group:after {
    clear: both
}

.button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: .9rem
}

.button-group .button:last-child {
    margin-right: 0
}

.button-group.tiny .button {
    font-size: .6rem
}

.button-group.small .button {
    font-size: .75rem
}

.button-group.large .button {
    font-size: 1.25rem
}

.button-group.expanded {
    margin-right: -1px
}

.button-group.expanded:after,.button-group.expanded:before {
    display: none
}

.button-group.expanded .button:first-child:nth-last-child(2),.button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2)~.button {
    display: inline-block;
    width: calc(50% - 1px);
    margin-right: 1px
}

.button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2)~.button:last-child,.button-group.expanded .button:first-child:nth-last-child(2):last-child {
    margin-right: -6px
}

.button-group.expanded .button:first-child:nth-last-child(3),.button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3)~.button {
    display: inline-block;
    width: calc(33.33333% - 1px);
    margin-right: 1px
}

.button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3)~.button:last-child,.button-group.expanded .button:first-child:nth-last-child(3):last-child {
    margin-right: -6px
}

.button-group.expanded .button:first-child:nth-last-child(4),.button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4)~.button {
    display: inline-block;
    width: calc(25% - 1px);
    margin-right: 1px
}

.button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4)~.button:last-child,.button-group.expanded .button:first-child:nth-last-child(4):last-child {
    margin-right: -6px
}

.button-group.expanded .button:first-child:nth-last-child(5),.button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5)~.button {
    display: inline-block;
    width: calc(20% - 1px);
    margin-right: 1px
}

.button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5)~.button:last-child,.button-group.expanded .button:first-child:nth-last-child(5):last-child {
    margin-right: -6px
}

.button-group.expanded .button:first-child:nth-last-child(6),.button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6)~.button {
    display: inline-block;
    width: calc(16.66667% - 1px);
    margin-right: 1px
}

.button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6)~.button:last-child,.button-group.expanded .button:first-child:nth-last-child(6):last-child {
    margin-right: -6px
}

.button-group.primary .button {
    background-color: #2199e8;
    color: #fefefe
}

.button-group.primary .button:focus,.button-group.primary .button:hover {
    background-color: #147cc0;
    color: #fefefe
}

.button-group.secondary .button {
    background-color: #777;
    color: #fefefe
}

.button-group.secondary .button:focus,.button-group.secondary .button:hover {
    background-color: #5f5f5f;
    color: #fefefe
}

.button-group.success .button {
    background-color: #3adb76;
    color: #fefefe
}

.button-group.success .button:focus,.button-group.success .button:hover {
    background-color: #22bb5b;
    color: #fefefe
}

.button-group.warning .button {
    background-color: #ffae00;
    color: #fefefe
}

.button-group.warning .button:focus,.button-group.warning .button:hover {
    background-color: #cc8b00;
    color: #fefefe
}

.button-group.alert .button {
    background-color: #ec5840;
    color: #fefefe
}

.button-group.alert .button:focus,.button-group.alert .button:hover {
    background-color: #da3116;
    color: #fefefe
}

.button-group.stacked-for-medium .button,.button-group.stacked-for-small .button,.button-group.stacked .button {
    width: 100%
}

.button-group.stacked-for-medium .button:last-child,.button-group.stacked-for-small .button:last-child,.button-group.stacked .button:last-child {
    margin-bottom: 0
}

@media screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
        width:auto;
        margin-bottom: 0
    }
}

@media screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
        width:auto;
        margin-bottom: 0
    }
}

@media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
        display:block
    }

    .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0
    }
}

.callout {
    margin: 0 0 1rem;
    padding: 1rem;
    border: 1px solid hsla(0,0%,4%,.25);
    border-radius: 0;
    position: relative;
    color: #0a0a0a;
    background-color: #fff
}

.callout>:first-child {
    margin-top: 0
}

.callout>:last-child {
    margin-bottom: 0
}

.callout.primary {
    background-color: #def0fc
}

.callout.secondary {
    background-color: #ebebeb
}

.callout.success {
    background-color: #e1faea
}

.callout.warning {
    background-color: #fff3d9
}

.callout.alert {
    background-color: #fce6e2
}

.callout.small {
    padding: .5rem
}

.callout.large {
    padding: 3rem
}

.close-button {
    position: absolute;
    color: #8a8a8a;
    right: 1rem;
    top: .5rem;
    font-size: 2em;
    line-height: 1;
    cursor: pointer
}

[data-whatinput=mouse] .close-button {
    outline: 0
}

.close-button:focus,.close-button:hover {
    color: #0a0a0a
}

.menu {
    margin: 0;
    list-style-type: none
}

.menu>li {
    display: table-cell;
    vertical-align: middle
}

[data-whatinput=mouse] .menu>li {
    outline: 0
}

.menu>li>a {
    display: block;
    padding: .7rem 1rem;
    line-height: 1
}

.menu a,.menu button,.menu input {
    margin-bottom: 0
}

.menu>li>a i,.menu>li>a i+span,.menu>li>a img,.menu>li>a img+span,.menu>li>a svg,.menu>li>a svg+span {
    vertical-align: middle
}

.menu>li>a i,.menu>li>a img,.menu>li>a svg {
    margin-right: .25rem;
    display: inline-block
}

.menu>li {
    display: table-cell
}

.menu.vertical>li {
    display: block
}

@media screen and (min-width: 40em) {
    .menu.medium-horizontal>li {
        display:table-cell
    }

    .menu.medium-vertical>li {
        display: block
    }
}

@media screen and (min-width: 64em) {
    .menu.large-horizontal>li {
        display:table-cell
    }

    .menu.large-vertical>li {
        display: block
    }
}

.menu.simple li {
    line-height: 1;
    display: inline-block;
    margin-right: 1rem
}

.menu.simple a {
    padding: 0
}

.menu.align-right:after,.menu.align-right:before {
    content: ' ';
    display: table
}

.menu.align-right:after {
    clear: both
}

.menu.align-right>li {
    float: right
}

.menu.expanded {
    width: 100%;
    display: table;
    table-layout: fixed
}

.menu.expanded>li:first-child:last-child {
    width: 100%
}

.menu.icon-top>li>a {
    text-align: center
}

.menu.icon-top>li>a i,.menu.icon-top>li>a img,.menu.icon-top>li>a svg {
    display: block;
    margin: 0 auto .25rem
}

.menu.nested {
    margin-left: 1rem
}

.menu .active>a {
    color: #fefefe;
    background: #2199e8
}

.menu-text {
    font-weight: 700;
    color: inherit;
    line-height: 1;
    padding-top: 0;
    padding-bottom: 0;
    padding: .7rem 1rem
}

.menu-centered {
    text-align: center
}

.menu-centered>.menu {
    display: inline-block
}

.no-js [data-responsive-menu] ul {
    display: none
}

.menu-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    width: 20px;
    height: 16px
}

.menu-icon:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    top: 0;
    left: 0;
    box-shadow: 0 7px 0 #fefefe,0 14px 0 #fefefe
}

.menu-icon:hover:after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca,0 14px 0 #cacaca
}

.menu-icon.dark {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    width: 20px;
    height: 16px
}

.menu-icon.dark:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    top: 0;
    left: 0;
    box-shadow: 0 7px 0 #0a0a0a,0 14px 0 #0a0a0a
}

.menu-icon.dark:hover:after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a,0 14px 0 #8a8a8a
}

.is-drilldown {
    position: relative;
    overflow: hidden
}

.is-drilldown li {
    display: block!important
}

.is-drilldown-submenu {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: -1;
    width: 100%;
    background: #fefefe;
    transition: -webkit-transform .15s linear;
    transition: transform .15s linear
}

.is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.is-drilldown-submenu.is-closing {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.is-drilldown-submenu-parent>a {
    position: relative
}

.is-drilldown-submenu-parent>a:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 6px inset;
    border-color: transparent transparent transparent #2199e8;
    border-left-style: solid;
    border-right-width: 0;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem
}

.js-drilldown-back>a:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 6px inset;
    border-color: transparent #2199e8 transparent transparent;
    border-right-style: solid;
    border-left-width: 0;
    display: inline-block;
    vertical-align: middle;
    margin-right: .75rem
}

.dropdown-pane {
    background-color: #fefefe;
    border: 1px solid #cacaca;
    border-radius: 0;
    display: block;
    font-size: 1rem;
    padding: 1rem;
    position: absolute;
    visibility: hidden;
    width: 300px;
    z-index: 3
}

.dropdown-pane.is-open {
    visibility: visible
}

.dropdown-pane.tiny {
    width: 100px
}

.dropdown-pane.small {
    width: 200px
}

.dropdown-pane.large {
    width: 400px
}

.dropdown.menu>li.opens-left>.is-dropdown-submenu {
    left: auto;
    right: 0;
    top: 100%
}

.dropdown.menu>li.opens-right>.is-dropdown-submenu {
    right: auto;
    left: 0;
    top: 100%
}

.dropdown.menu>li.is-dropdown-submenu-parent>a {
    padding-right: 1.5rem;
    position: relative
}

.dropdown.menu>li.is-dropdown-submenu-parent>a:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 5px inset;
    border-color: #2199e8 transparent transparent;
    border-top-style: solid;
    border-bottom-width: 0;
    right: 5px;
    margin-top: -2px
}

[data-whatinput=mouse] .dropdown.menu a {
    outline: 0
}

.no-js .dropdown.menu ul {
    display: none
}

.dropdown.menu.vertical>li .is-dropdown-submenu {
    top: 0
}

.dropdown.menu.vertical>li.opens-left>.is-dropdown-submenu {
    left: auto;
    right: 100%
}

.dropdown.menu.vertical>li.opens-right>.is-dropdown-submenu {
    right: auto;
    left: 100%
}

.dropdown.menu.vertical>li>a:after {
    right: 14px;
    margin-top: -3px
}

.dropdown.menu.vertical>li.opens-left>a:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 5px inset;
    border-color: transparent #2199e8 transparent transparent;
    border-right-style: solid;
    border-left-width: 0
}

.dropdown.menu.vertical>li.opens-right>a:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 5px inset;
    border-color: transparent transparent transparent #2199e8;
    border-left-style: solid;
    border-right-width: 0
}

@media screen and (min-width: 40em) {
    .dropdown.menu.medium-horizontal>li.opens-left>.is-dropdown-submenu {
        left:auto;
        right: 0;
        top: 100%
    }

    .dropdown.menu.medium-horizontal>li.opens-right>.is-dropdown-submenu {
        right: auto;
        left: 0;
        top: 100%
    }

    .dropdown.menu.medium-horizontal>li.is-dropdown-submenu-parent>a {
        padding-right: 1.5rem;
        position: relative
    }

    .dropdown.menu.medium-horizontal>li.is-dropdown-submenu-parent>a:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: 5px inset;
        border-color: #2199e8 transparent transparent;
        border-top-style: solid;
        border-bottom-width: 0;
        right: 5px;
        margin-top: -2px
    }

    .dropdown.menu.medium-vertical>li .is-dropdown-submenu {
        top: 0
    }

    .dropdown.menu.medium-vertical>li.opens-left>.is-dropdown-submenu {
        left: auto;
        right: 100%
    }

    .dropdown.menu.medium-vertical>li.opens-right>.is-dropdown-submenu {
        right: auto;
        left: 100%
    }

    .dropdown.menu.medium-vertical>li>a:after {
        right: 14px;
        margin-top: -3px
    }

    .dropdown.menu.medium-vertical>li.opens-left>a:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: 5px inset;
        border-color: transparent #2199e8 transparent transparent;
        border-right-style: solid;
        border-left-width: 0
    }

    .dropdown.menu.medium-vertical>li.opens-right>a:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: 5px inset;
        border-color: transparent transparent transparent #2199e8;
        border-left-style: solid;
        border-right-width: 0
    }
}

@media screen and (min-width: 64em) {
    .dropdown.menu.large-horizontal>li.opens-left>.is-dropdown-submenu {
        left:auto;
        right: 0;
        top: 100%
    }

    .dropdown.menu.large-horizontal>li.opens-right>.is-dropdown-submenu {
        right: auto;
        left: 0;
        top: 100%
    }

    .dropdown.menu.large-horizontal>li.is-dropdown-submenu-parent>a {
        padding-right: 1.5rem;
        position: relative
    }

    .dropdown.menu.large-horizontal>li.is-dropdown-submenu-parent>a:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: 5px inset;
        border-color: #2199e8 transparent transparent;
        border-top-style: solid;
        border-bottom-width: 0;
        right: 5px;
        margin-top: -2px
    }

    .dropdown.menu.large-vertical>li .is-dropdown-submenu {
        top: 0
    }

    .dropdown.menu.large-vertical>li.opens-left>.is-dropdown-submenu {
        left: auto;
        right: 100%
    }

    .dropdown.menu.large-vertical>li.opens-right>.is-dropdown-submenu {
        right: auto;
        left: 100%
    }

    .dropdown.menu.large-vertical>li>a:after {
        right: 14px;
        margin-top: -3px
    }

    .dropdown.menu.large-vertical>li.opens-left>a:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: 5px inset;
        border-color: transparent #2199e8 transparent transparent;
        border-right-style: solid;
        border-left-width: 0
    }

    .dropdown.menu.large-vertical>li.opens-right>a:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: 5px inset;
        border-color: transparent transparent transparent #2199e8;
        border-left-style: solid;
        border-right-width: 0
    }
}

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
    top: 100%;
    left: auto;
    right: 0
}

.is-dropdown-menu.vertical {
    width: 100px
}

.is-dropdown-menu.vertical.align-right {
    float: right
}

.is-dropdown-submenu-parent {
    position: relative
}

.is-dropdown-submenu-parent a:after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -2px
}

.is-dropdown-submenu-parent.opens-inner>.is-dropdown-submenu {
    top: 100%;
    left: auto
}

.is-dropdown-submenu-parent.opens-left>.is-dropdown-submenu {
    left: auto;
    right: 100%
}

.is-dropdown-submenu-parent.opens-right>.is-dropdown-submenu {
    right: auto;
    left: 100%
}

.is-dropdown-submenu {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 200px;
    z-index: 1;
    background: #fefefe;
    border: 1px solid #cacaca
}

.is-dropdown-submenu .is-dropdown-submenu-parent>a:after {
    right: 14px;
    margin-top: -3px
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left>a:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 5px inset;
    border-color: transparent #2199e8 transparent transparent;
    border-right-style: solid;
    border-left-width: 0
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right>a:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 5px inset;
    border-color: transparent transparent transparent #2199e8;
    border-left-style: solid;
    border-right-width: 0
}

.is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px
}

.is-dropdown-submenu>li {
    width: 100%
}

.is-dropdown-submenu.js-dropdown-active {
    display: block
}

.flex-video {
    position: relative;
    height: 0;
    padding-bottom: 75%;
    margin-bottom: 1rem;
    overflow: hidden
}

.flex-video embed,.flex-video iframe,.flex-video object,.flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.flex-video.widescreen {
    padding-bottom: 56.25%
}

.flex-video.vimeo {
    padding-top: 0
}

.label {
    display: inline-block;
    padding: .33333rem .5rem;
    font-size: .8rem;
    line-height: 1;
    white-space: nowrap;
    cursor: default;
    border-radius: 0;
    background: #2199e8;
    color: #fefefe
}

.label.secondary {
    background: #777;
    color: #fefefe
}

.label.success {
    background: #3adb76;
    color: #fefefe
}

.label.warning {
    background: #ffae00;
    color: #fefefe
}

.label.alert {
    background: #ec5840;
    color: #fefefe
}

.media-object {
    margin-bottom: 1rem;
    display: block
}

.media-object img {
    max-width: none
}

@media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
        padding:0;
        padding-bottom: 1rem;
        display: block
    }

    .media-object.stack-for-small .media-object-section img {
        width: 100%
    }
}

.media-object-section {
    display: table-cell;
    vertical-align: top
}

.media-object-section:first-child {
    padding-right: 1rem
}

.media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem
}

.media-object-section>:last-child {
    margin-bottom: 0
}

.media-object-section.middle {
    vertical-align: middle
}

.media-object-section.bottom {
    vertical-align: bottom
}

body,html {
    height: 100%
}

.off-canvas-wrapper {
    width: 100%;
    overflow-x: hidden;
    position: relative;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: auto
}

.off-canvas-wrapper-inner {
    position: relative;
    width: 100%;
    min-height: 100%;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease
}

.off-canvas-wrapper-inner:after,.off-canvas-wrapper-inner:before {
    content: ' ';
    display: table
}

.off-canvas-wrapper-inner:after {
    clear: both
}

.off-canvas-content {
    min-height: 100%;
    background: #fefefe;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
    padding-bottom: .1px;
    box-shadow: 0 0 10px hsla(0,0%,4%,.5)
}

.js-off-canvas-exit {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0,0%,100%,.25);
    cursor: pointer;
    transition: background .5s ease
}

.off-canvas {
    position: absolute;
    background: #e6e6e6;
    z-index: -1;
    max-height: 100%;
    overflow-y: auto;
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

[data-whatinput=mouse] .off-canvas {
    outline: 0
}

.off-canvas.position-left {
    left: -250px;
    top: 0;
    width: 250px
}

.is-open-left {
    -webkit-transform: translateX(250px);
    transform: translateX(250px)
}

.off-canvas.position-right {
    right: -250px;
    top: 0;
    width: 250px
}

.is-open-right {
    -webkit-transform: translateX(-250px);
    transform: translateX(-250px)
}

@media screen and (min-width: 40em) {
    .position-left.reveal-for-medium {
        left:0;
        z-index: auto;
        position: fixed
    }

    .position-left.reveal-for-medium~.off-canvas-content {
        margin-left: 250px
    }

    .position-right.reveal-for-medium {
        right: 0;
        z-index: auto;
        position: fixed
    }

    .position-right.reveal-for-medium~.off-canvas-content {
        margin-right: 250px
    }
}

@media screen and (min-width: 64em) {
    .position-left.reveal-for-large {
        left:0;
        z-index: auto;
        position: fixed
    }

    .position-left.reveal-for-large~.off-canvas-content {
        margin-left: 250px
    }

    .position-right.reveal-for-large {
        right: 0;
        z-index: auto;
        position: fixed
    }

    .position-right.reveal-for-large~.off-canvas-content {
        margin-right: 250px
    }
}

.orbit,.orbit-container {
    position: relative
}

.orbit-container {
    margin: 0;
    overflow: hidden;
    list-style: none
}

.orbit-slide {
    width: 100%;
    max-height: 100%
}

.orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0
}

.orbit-figure {
    margin: 0
}

.orbit-image {
    margin: 0;
    width: 100%;
    max-width: 100%
}

.orbit-caption {
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
    background-color: hsla(0,0%,4%,.5)
}

.orbit-caption,.orbit-next,.orbit-previous {
    position: absolute;
    padding: 1rem;
    color: #fefefe
}

.orbit-next,.orbit-previous {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 3
}

[data-whatinput=mouse] .orbit-next,[data-whatinput=mouse] .orbit-previous {
    outline: 0
}

.orbit-next:active,.orbit-next:focus,.orbit-next:hover,.orbit-previous:active,.orbit-previous:focus,.orbit-previous:hover {
    background-color: hsla(0,0%,4%,.5)
}

.orbit-previous {
    left: 0
}

.orbit-next {
    left: auto;
    right: 0
}

.orbit-bullets {
    position: relative;
    margin-top: .8rem;
    margin-bottom: .8rem;
    text-align: center
}

[data-whatinput=mouse] .orbit-bullets {
    outline: 0
}

.orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: .1rem;
    background-color: #cacaca;
    border-radius: 50%
}

.orbit-bullets button.is-active,.orbit-bullets button:hover {
    background-color: #8a8a8a
}

.pagination {
    margin-left: 0;
    margin-bottom: 1rem
}

.pagination:after,.pagination:before {
    content: ' ';
    display: table
}

.pagination:after {
    clear: both
}

.pagination li {
    font-size: .875rem;
    margin-right: .0625rem;
    border-radius: 0;
    display: none
}

.pagination li:first-child,.pagination li:last-child {
    display: inline-block
}

@media screen and (min-width: 40em) {
    .pagination li {
        display:inline-block
    }
}

.pagination a,.pagination button {
    color: #0a0a0a;
    display: block;
    padding: .1875rem .625rem;
    border-radius: 0
}

.pagination a:hover,.pagination button:hover {
    background: #e6e6e6
}

.pagination .current {
    padding: .1875rem .625rem;
    background: #2199e8;
    color: #fefefe;
    cursor: default
}

.pagination .disabled {
    padding: .1875rem .625rem;
    color: #cacaca;
    cursor: not-allowed
}

.pagination .disabled:hover {
    background: transparent
}

.pagination .ellipsis:after {
    content: '…';
    padding: .1875rem .625rem;
    color: #0a0a0a
}

.pagination-previous.disabled:before,.pagination-previous a:before {
    content: '«';
    display: inline-block;
    margin-right: .5rem
}

.pagination-next.disabled:after,.pagination-next a:after {
    content: '»';
    display: inline-block;
    margin-left: .5rem
}

.progress {
    background-color: #cacaca;
    height: 1rem;
    margin-bottom: 1rem;
    border-radius: 0
}

.progress.primary .progress-meter {
    background-color: #2199e8
}

.progress.secondary .progress-meter {
    background-color: #777
}

.progress.success .progress-meter {
    background-color: #3adb76
}

.progress.warning .progress-meter {
    background-color: #ffae00
}

.progress.alert .progress-meter {
    background-color: #ec5840
}

.progress-meter {
    position: relative;
    display: block;
    width: 0;
    height: 100%;
    background-color: #2199e8
}

.progress-meter-text {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    position: absolute;
    margin: 0;
    font-size: .75rem;
    font-weight: 700;
    color: #fefefe;
    white-space: nowrap
}

body.is-reveal-open {
    overflow: hidden
}

html.is-reveal-open,html.is-reveal-open body {
    min-height: 100%;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.slider {
    position: relative;
    height: .5rem;
    margin-top: 1.25rem;
    margin-bottom: 2.25rem;
    background-color: #e6e6e6;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: none;
    touch-action: none
}

.slider-fill {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    max-width: 100%;
    height: .5rem;
    background-color: #cacaca;
    transition: all .2s ease-in-out
}

.slider-fill.is-dragging {
    transition: all 0s linear
}

.slider-handle {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    z-index: 1;
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    background-color: #2199e8;
    transition: all .2s ease-in-out;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    border-radius: 0
}

[data-whatinput=mouse] .slider-handle {
    outline: 0
}

.slider-handle:hover {
    background-color: #1583cc
}

.slider-handle.is-dragging {
    transition: all 0s linear
}

.slider.disabled,.slider[disabled] {
    opacity: .25;
    cursor: not-allowed
}

.slider.vertical {
    display: inline-block;
    width: .5rem;
    height: 12.5rem;
    margin: 0 1.25rem;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1)
}

.slider.vertical .slider-fill {
    top: 0;
    width: .5rem;
    max-height: 100%
}

.slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.sticky-container {
    position: relative
}

.sticky {
    position: absolute;
    z-index: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.sticky.is-stuck {
    position: fixed;
    z-index: 2
}

.sticky.is-stuck.is-at-top {
    top: 0
}

.sticky.is-stuck.is-at-bottom {
    bottom: 0
}

.sticky.is-anchored {
    position: absolute;
    left: auto;
    right: auto
}

.sticky.is-anchored.is-at-bottom {
    bottom: 0
}

.switch {
    height: 2rem;
    margin-bottom: 1rem;
    outline: 0;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #fefefe;
    font-weight: 700;
    font-size: .875rem
}

.switch-input {
    opacity: 0;
    position: absolute;
    margin-bottom: 0
}

.switch-paddle {
    background: #cacaca;
    cursor: pointer;
    display: block;
    position: relative;
    width: 4rem;
    height: 2rem;
    transition: all .25s ease-out;
    border-radius: 0;
    color: inherit;
    font-weight: inherit
}

input+.switch-paddle {
    margin: 0
}

.switch-paddle:after {
    background: #fefefe;
    content: '';
    display: block;
    position: absolute;
    height: 1.5rem;
    left: .25rem;
    top: .25rem;
    width: 1.5rem;
    transition: all .25s ease-out;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 0
}

input:checked~.switch-paddle {
    background: #2199e8
}

input:checked~.switch-paddle:after {
    left: 2.25rem
}

[data-whatinput=mouse] input:focus~.switch-paddle {
    outline: 0
}

.switch-active,.switch-inactive {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.switch-active {
    left: 8%;
    display: none
}

input:checked+label>.switch-active {
    display: block
}

.switch-inactive {
    right: 15%
}

input:checked+label>.switch-inactive {
    display: none
}

.switch.tiny {
    height: 1.5rem
}

.switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: .625rem
}

.switch.tiny .switch-paddle:after {
    width: 1rem;
    height: 1rem
}

.switch.tiny input:checked~.switch-paddle:after {
    left: 1.75rem
}

.switch.small {
    height: 1.75rem
}

.switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: .75rem
}

.switch.small .switch-paddle:after {
    width: 1.25rem;
    height: 1.25rem
}

.switch.small input:checked~.switch-paddle:after {
    left: 2rem
}

.switch.large {
    height: 2.5rem
}

.switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem
}

.switch.large .switch-paddle:after {
    width: 2rem;
    height: 2rem
}

.switch.large input:checked~.switch-paddle:after {
    left: 2.75rem
}

table {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0
}

table tbody,table tfoot,table thead {
    border: 1px solid #f1f1f1;
    background-color: #fefefe
}

table caption {
    font-weight: 700;
    padding: .5rem .625rem .625rem
}

table thead {
    background: #f8f8f8;
    color: #0a0a0a
}

table tfoot {
    background: #f1f1f1;
    color: #0a0a0a
}

table tfoot tr,table thead tr {
    background: transparent
}

table tfoot td,table tfoot th,table thead td,table thead th {
    padding: .5rem .625rem .625rem;
    font-weight: 700;
    text-align: left
}

table tbody td,table tbody th {
    padding: .5rem .625rem .625rem
}

@media screen and (max-width: 63.9375em) {
    table.stack tfoot,table.stack thead {
        display:none
    }

    table.stack td,table.stack th,table.stack tr {
        display: block
    }

    table.stack td {
        border-top: 0
    }
}

table.scroll {
    display: block;
    width: 100%;
    overflow-x: auto
}

table.hover thead tr:hover {
    background-color: #f3f3f3
}

table.hover tfoot tr:hover {
    background-color: #ececec
}

table.hover tbody tr:hover {
    background-color: #f9f9f9
}

table.hover tbody tr:nth-of-type(even):hover {
    background-color: #ececec
}

.table-scroll {
    overflow-x: auto
}

.table-scroll table {
    width: auto
}

.tabs {
    margin: 0;
    list-style-type: none;
    background: #fefefe;
    border: 1px solid #e6e6e6
}

.tabs:after,.tabs:before {
    content: ' ';
    display: table
}

.tabs:after {
    clear: both
}

.tabs.vertical>li {
    width: auto;
    float: none;
    display: block
}

.tabs.simple>li>a {
    padding: 0
}

.tabs.simple>li>a:hover {
    background: transparent
}

.tabs.primary {
    background: #2199e8
}

.tabs.primary>li>a {
    color: #fefefe
}

.tabs.primary>li>a:focus,.tabs.primary>li>a:hover {
    background: #1893e4
}

.tabs-title {
    float: left
}

.tabs-title>a {
    display: block;
    padding: 1.25rem 1.5rem;
    line-height: 1;
    font-size: .75rem
}

.tabs-title>a:hover {
    background: #fefefe
}

.tabs-title>a:focus,.tabs-title>a[aria-selected=true] {
    background: #e6e6e6
}

.tabs-content {
    background: #fefefe;
    transition: all .5s ease;
    border: 1px solid #e6e6e6;
    border-top: 0
}

.tabs-content.vertical {
    border: 1px solid #e6e6e6;
    border-left: 0
}

.tabs-panel {
    display: none;
    padding: 1rem
}

.tabs-panel.is-active {
    display: block
}

.thumbnail {
    border: 4px solid #fefefe;
    box-shadow: 0 0 0 1px hsla(0,0%,4%,.2);
    display: inline-block;
    line-height: 0;
    max-width: 100%;
    transition: box-shadow .2s ease-out;
    border-radius: 0;
    margin-bottom: 1rem
}

.thumbnail:focus,.thumbnail:hover {
    box-shadow: 0 0 6px 1px rgba(33,153,232,.5)
}

.title-bar {
    background: #0a0a0a;
    color: #fefefe;
    padding: .5rem
}

.title-bar:after,.title-bar:before {
    content: ' ';
    display: table
}

.title-bar:after {
    clear: both
}

.title-bar .menu-icon {
    margin-left: .25rem;
    margin-right: .25rem
}

.title-bar-left {
    float: left
}

.title-bar-right {
    float: right;
    text-align: right
}

.title-bar-title {
    vertical-align: middle
}

.has-tip,.title-bar-title {
    font-weight: 700;
    display: inline-block
}

.has-tip {
    border-bottom: 1px dotted #8a8a8a;
    position: relative;
    cursor: help
}

.tooltip {
    background-color: #0a0a0a;
    color: #fefefe;
    font-size: 80%;
    padding: .75rem;
    position: absolute;
    z-index: 3;
    top: calc(100% + .6495rem);
    max-width: 10rem;
    border-radius: 0
}

.tooltip:before {
    border-color: transparent transparent #0a0a0a;
    border-bottom-style: solid;
    border-top-width: 0;
    bottom: 100%;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.tooltip.top:before,.tooltip:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: .75rem inset
}

.tooltip.top:before {
    border-color: #0a0a0a transparent transparent;
    border-top-style: solid;
    border-bottom-width: 0;
    top: 100%;
    bottom: auto
}

.tooltip.left:before {
    border-color: transparent transparent transparent #0a0a0a;
    border-left-style: solid;
    border-right-width: 0;
    left: 100%
}

.tooltip.left:before,.tooltip.right:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: .75rem inset;
    bottom: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.tooltip.right:before {
    border-color: transparent #0a0a0a transparent transparent;
    border-right-style: solid;
    border-left-width: 0;
    left: auto;
    right: 100%
}

.top-bar {
    padding: .5rem
}

.top-bar:after,.top-bar:before {
    content: ' ';
    display: table
}

.top-bar:after {
    clear: both
}

.top-bar,.top-bar ul {
    background-color: #e6e6e6
}

.top-bar input {
    max-width: 200px;
    margin-right: 1rem
}

.top-bar .input-group-field {
    width: 100%;
    margin-right: 0
}

.top-bar input.button {
    width: auto
}

.top-bar .top-bar-left,.top-bar .top-bar-right {
    width: 100%
}

@media screen and (min-width: 40em) {
    .top-bar .top-bar-left,.top-bar .top-bar-right {
        width:auto
    }
}

@media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium .top-bar-left,.top-bar.stacked-for-medium .top-bar-right {
        width:100%
    }
}

@media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large .top-bar-left,.top-bar.stacked-for-large .top-bar-right {
        width:100%
    }
}

.top-bar-title {
    float: left;
    margin-right: 1rem
}

.top-bar-left {
    float: left
}

.top-bar-right {
    float: right
}

.hide {
    display: none!important
}

.invisible {
    visibility: hidden
}

@media screen and (max-width: 39.9375em) {
    .hide-for-small-only {
        display:none!important
    }
}

@media screen and (max-width: 0),screen and (min-width:40em) {
    .show-for-small-only {
        display:none!important
    }
}

@media screen and (min-width: 40em) {
    .hide-for-medium {
        display:none!important
    }
}

@media screen and (max-width: 39.9375em) {
    .show-for-medium {
        display:none!important
    }
}

@media screen and (min-width: 40em) and (max-width:63.9375em) {
    .hide-for-medium-only {
        display:none!important
    }
}

@media screen and (max-width: 39.9375em),screen and (min-width:64em) {
    .show-for-medium-only {
        display:none!important
    }
}

@media screen and (min-width: 64em) {
    .hide-for-large {
        display:none!important
    }
}

@media screen and (max-width: 63.9375em) {
    .show-for-large {
        display:none!important
    }
}

@media screen and (min-width: 64em) and (max-width:74.9375em) {
    .hide-for-large-only {
        display:none!important
    }
}

@media screen and (max-width: 63.9375em),screen and (min-width:75em) {
    .show-for-large-only {
        display:none!important
    }
}

.ui-pnotify {
    top: 36px;
    right: 36px;
    position: absolute;
    height: auto;
    z-index: 2
}

body>.ui-pnotify {
    position: fixed;
    z-index: 100040
}

.ui-pnotify-modal-overlay {
    background-color: rgba(0,0,0,.4);
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1
}

body>.ui-pnotify-modal-overlay {
    position: fixed;
    z-index: 100039
}

.ui-pnotify.ui-pnotify-in {
    display: block!important
}

.ui-pnotify.ui-pnotify-move {
    transition: left .5s ease,top .5s ease,right .5s ease,bottom .5s ease
}

.ui-pnotify.ui-pnotify-fade-slow {
    transition: opacity .6s linear;
    opacity: 0
}

.ui-pnotify.ui-pnotify-fade-slow.ui-pnotify.ui-pnotify-move {
    transition: opacity .6s linear,left .5s ease,top .5s ease,right .5s ease,bottom .5s ease
}

.ui-pnotify.ui-pnotify-fade-normal {
    transition: opacity .4s linear;
    opacity: 0
}

.ui-pnotify.ui-pnotify-fade-normal.ui-pnotify.ui-pnotify-move {
    transition: opacity .4s linear,left .5s ease,top .5s ease,right .5s ease,bottom .5s ease
}

.ui-pnotify.ui-pnotify-fade-fast {
    transition: opacity .2s linear;
    opacity: 0
}

.ui-pnotify.ui-pnotify-fade-fast.ui-pnotify.ui-pnotify-move {
    transition: opacity .2s linear,left .5s ease,top .5s ease,right .5s ease,bottom .5s ease
}

.ui-pnotify.ui-pnotify-fade-in {
    opacity: 1
}

.ui-pnotify .ui-pnotify-shadow {
    -webkit-box-shadow: 0 6px 28px 0 rgba(0,0,0,.1);
    -moz-box-shadow: 0 6px 28px 0 rgba(0,0,0,.1);
    box-shadow: 0 6px 28px 0 rgba(0,0,0,.1)
}

.ui-pnotify-container {
    background-position: 0 0;
    padding: .8em;
    height: 100%;
    margin: 0
}

.ui-pnotify-container:after {
    content: " ";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both
}

.ui-pnotify-container.ui-pnotify-sharp {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.ui-pnotify-title {
    display: block;
    margin-bottom: .4em;
    margin-top: 0
}

.ui-pnotify-text {
    display: block
}

.ui-pnotify-icon,.ui-pnotify-icon span {
    display: block;
    float: left;
    margin-right: .2em
}

.ui-pnotify.stack-bottomleft,.ui-pnotify.stack-topleft {
    left: 25px;
    right: auto
}

.ui-pnotify.stack-bottomleft,.ui-pnotify.stack-bottomright {
    bottom: 25px;
    top: auto
}

.ui-pnotify.stack-modal {
    left: 50%;
    right: auto;
    margin-left: -150px
}

.brighttheme {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.brighttheme.ui-pnotify-container {
    padding: 18px
}

.brighttheme .ui-pnotify-title {
    margin-bottom: 7px
}

.brighttheme-notice {
    background-color: #f3f3f3;
    border: 0 solid #ff0;
    color: #000;
    font-size: 12px
}

.brighttheme-info {
    background-color: #8fcedd;
    border: 0 solid #0286a5;
    color: #012831
}

.brighttheme-success {
    background-color: #aff29a;
    border: 0 solid #35db00;
    color: #104300
}

.brighttheme-error {
    background-color: #ffaba2;
    background-image: repeating-linear-gradient(135deg,transparent,transparent 35px,rgba(255,255,255,.3) 35px,rgba(255,255,255,.3) 70px);
    border: 0 solid #ff1800;
    color: #4f0800
}

.brighttheme-icon-closer,.brighttheme-icon-info,.brighttheme-icon-notice,.brighttheme-icon-sticker,.brighttheme-icon-success {
    position: relative;
    width: 16px;
    height: 16px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    font-family: "Courier New",Courier,monospace;
    border-radius: 50%
}

.brighttheme-icon-closer:after,.brighttheme-icon-info:after,.brighttheme-icon-notice:after,.brighttheme-icon-sticker:after,.brighttheme-icon-success:after {
    position: absolute;
    top: 0;
    left: 4px
}

.brighttheme-icon-notice {
    background-color: #2e2e00;
    color: #ffffa2;
    margin-top: 2px
}

.brighttheme-icon-notice:after {
    content: "!"
}

.brighttheme-icon-info {
    background-color: #012831;
    color: #8fcedd;
    margin-top: 2px
}

.brighttheme-icon-info:after {
    content: "i"
}

.brighttheme-icon-success {
    background-color: #104300;
    color: #aff29a;
    margin-top: 2px
}

.brighttheme-icon-success:after {
    content: "✓"
}

.brighttheme-icon-error {
    position: relative;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 16px solid #2e0400;
    font-size: 0;
    line-height: 0;
    color: #ffaba2;
    margin-top: 1px
}

.brighttheme-icon-error:after {
    position: absolute;
    top: 1px;
    left: -4px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    font-family: "Courier New",Courier,monospace;
    content: "!"
}

.brighttheme-icon-closer,.brighttheme-icon-sticker {
    display: inline-block
}

.brighttheme-icon-closer:after {
    top: -4px;
    content: "✕"
}

.brighttheme-icon-sticker:after {
    top: -5px;
    content: "𝆺𝅥";
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.brighttheme-icon-sticker.brighttheme-icon-stuck:after {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.brighttheme .ui-pnotify-action-bar {
    padding-top: 12px
}

.brighttheme .ui-pnotify-action-bar input,.brighttheme .ui-pnotify-action-bar textarea {
    display: block;
    width: 100%;
    margin-bottom: 12px!important
}

.brighttheme .ui-pnotify-action-button {
    text-transform: uppercase;
    font-weight: 700;
    padding: 4px 8px;
    border: none;
    background: 0 0
}

.brighttheme .ui-pnotify-action-button.btn-primary {
    border: none;
    border-radius: 0
}

.brighttheme-notice .ui-pnotify-action-button.btn-primary {
    background-color: #ff0;
    color: #4f4f00
}

.brighttheme-info .ui-pnotify-action-button.btn-primary {
    background-color: #0286a5;
    color: #012831
}

.brighttheme-success .ui-pnotify-action-button.btn-primary {
    background-color: #35db00;
    color: #104300
}

.brighttheme-error .ui-pnotify-action-button.btn-primary {
    background-color: #ff1800;
    color: #4f0800
}

.ui-pnotify-closer,.ui-pnotify-sticker {
    float: right;
    margin-left: .2em
}

.ui-pnotify-history-container {
    position: absolute;
    top: 0;
    right: 18px;
    width: 70px;
    border-top: none;
    padding: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
    -moz-border-top-right-radius: 0;
    border-top-right-radius: 0;
    z-index: 10000
}

.ui-pnotify-history-container.ui-pnotify-history-fixed {
    position: fixed
}

.ui-pnotify-history-container .ui-pnotify-history-header {
    padding: 2px;
    text-align: center
}

.ui-pnotify-history-container button {
    cursor: pointer;
    display: block;
    width: 100%
}

.ui-pnotify-history-container .ui-pnotify-history-pulldown {
    display: block;
    margin: 0 auto
}

.ui-pnotify-container {
    position: relative;
    left: 0
}

@media(max-width: 480px) {
    .ui-pnotify-mobile-able.ui-pnotify {
        position:fixed;
        top: 0;
        right: 0;
        left: 0;
        width: auto!important;
        font-size: 1.2em;
        -webkit-font-smoothing: antialiased;
        -moz-font-smoothing: antialiased;
        -ms-font-smoothing: antialiased;
        font-smoothing: antialiased
    }

    .ui-pnotify-mobile-able.ui-pnotify .ui-pnotify-shadow {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-bottom-width: 5px
    }

    .ui-pnotify-mobile-able .ui-pnotify-container {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0
    }

    .ui-pnotify-mobile-able.ui-pnotify.stack-bottomleft,.ui-pnotify-mobile-able.ui-pnotify.stack-topleft {
        left: 0;
        right: 0
    }

    .ui-pnotify-mobile-able.ui-pnotify.stack-bottomleft,.ui-pnotify-mobile-able.ui-pnotify.stack-bottomright {
        left: 0;
        right: 0;
        bottom: 0;
        top: auto
    }

    .ui-pnotify-mobile-able.ui-pnotify.stack-bottomleft .ui-pnotify-shadow,.ui-pnotify-mobile-able.ui-pnotify.stack-bottomright .ui-pnotify-shadow {
        border-top-width: 5px;
        border-bottom-width: 1px
    }
}

.datepicker {
    display: none;
    position: absolute;
    padding: 4px;
    margin-top: 1px;
    direction: ltr
}

.datepicker.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    list-style: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.2);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    color: #333;
    font-size: 13px;
    line-height: 18px
}

.datepicker.dropdown-menu td,.datepicker.dropdown-menu th {
    padding: 4px 5px
}

.datepicker table {
    border: 0;
    margin: 0;
    width: auto
}

.datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer
}

.datepicker td,.datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    border: 0;
    font-size: 12px;
    padding: 4px 8px;
    cursor: pointer
}

.datepicker td {
    background: #fff
}

.datepicker td span.active,.datepicker td.active.day,.datepicker td.active.year {
    background: #2ba6cb
}

.datepicker td.new,.datepicker td.old {
    color: #999
}

.datepicker td span.month.disabled,.datepicker td span.year.disabled,.datepicker td.day.disabled {
    color: #eee
}

.datepicker th {
    background: #fff
}

.datepicker th span.active,.datepicker th.active.day,.datepicker th.active.year {
    background: #2ba6cb
}

.datepicker th.date-switch {
    width: 145px
}

.datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle
}

.datepicker.days div.datepicker-days,.datepicker.months div.datepicker-months,.datepicker.years div.datepicker-years {
    display: block
}

.datepicker-dropdown:after,.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    position: absolute
}

.datepicker thead tr:first-child th {
    cursor: pointer
}

.datepicker thead tr:first-child th.cw {
    cursor: default;
    background-color: transparent
}

.datepicker tfoot tr:first-child th {
    cursor: pointer
}

.datepicker-inline {
    width: 220px
}

.datepicker-rtl {
    direction: rtl
}

.datepicker-rtl table tr td span {
    float: right
}

.datepicker-dropdown {
    top: 0;
    left: 0
}

.datepicker-dropdown:before {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: 1px solid rgba(0,0,0,.2);
    top: -7px;
    left: 6px
}

.datepicker-dropdown:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    top: -6px;
    left: 7px
}

.datepicker-dropdown::after,.datepicker-dropdown::before,.datepicker>div {
    display: none
}

.datepicker-close {
    position: absolute;
    top: -30px;
    right: 0;
    width: 15px;
    height: 30px;
    padding: 0;
    display: none
}

.table-striped .datepicker table tr td,.table-striped .datepicker table tr th {
    background-color: transparent
}

.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0,0,0)
}

.owl-carousel .owl-wrapper,.owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0)
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%
}

.owl-carousel .owl-nav.disabled,.owl-carousel .owl-dots.disabled {
    display: none
}

.owl-carousel .owl-nav .owl-prev,.owl-carousel .owl-nav .owl-next,.owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel .owl-nav button.owl-prev,.owl-carousel .owl-nav button.owl-next,.owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0!important;
    font: inherit
}

.owl-carousel.owl-loaded {
    display: block
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block
}

.owl-carousel.owl-hidden {
    opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
    visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab
}

.owl-carousel.owl-rtl {
    direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
    float: right
}

.no-js .owl-carousel {
    display: block
}

.owl-carousel .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
    z-index: 0
}

.owl-carousel .owl-animated-out {
    z-index: 1
}

.owl-carousel .fadeOut {
    animation-name: fadeOut
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.owl-height {
    transition: height 500ms ease-in-out
}

.owl-carousel .owl-item {
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease
}

.owl-carousel .owl-item .owl-lazy[src^=""],.owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0
}

.owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    // background: url("owl.video.play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 100ms ease
}

.owl-carousel .owl-video-play-icon:hover {
    -ms-transform: scale(1.3,1.3);
    transform: scale(1.3,1.3)
}

.owl-carousel .owl-video-playing .owl-video-tn,.owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%
}

} // END of #allFooter
